import {
  LoadingScreen,
  useQuery,
  Divider,
  Button,
  useMutation,
  Avatar,
} from '@entropyparadox/reusable-react';
import React, { FC } from 'react';
import {
  resumeMeByUserId,
  ResumeMeByUserIdData,
  updateApply,
  updateApplyCancel,
} from '../api';
import { ReactComponent as ContentFormImg } from '../assets/svg/contentForm.svg';
import { ReactComponent as PhoneImg } from '../assets/svg/phone.svg';
import { Apply, StatusApply } from '../types/applies';
import { Gender } from '../types/user';
import AlreadyReviewButton from './AlreadyReviewButton';
import EvaluateList from './EvaluateList';

interface UserResumeDetailProps {
  applyUser: Apply;
  refetch: () => void;
}

const UserResumeDetail: FC<UserResumeDetailProps> = ({
  applyUser,
  refetch,
}) => {
  const { loading, data } = useQuery<ResumeMeByUserIdData>(resumeMeByUserId, {
    variables: {
      userId: applyUser.user.id,
    },
  });

  const handleConfirm = () => {
    // eslint-disable-next-line no-restricted-globals
    const con_test = confirm(
      `정말 채용하시겠습니까?
채용하기를 누르시면 지원자에게
채용완료 알람이 가고 채용이 완료됩니다.`,
    );
    if (con_test) {
      UpdateApply();
    } else {
    }
  };

  const handleCancel = () => {
    // eslint-disable-next-line no-restricted-globals
    const con_test = confirm(`해당 지원자를 불합격 처리하시겠습니까?`);
    if (con_test) {
      UpdateApplyCancel();
    } else {
    }
  };

  const [UpdateApply] = useMutation(updateApply, {
    variables: {
      applyId: applyUser.id,
    },
    onCompleted: async () => {
      await refetch();
    },
  });

  const [UpdateApplyCancel] = useMutation(updateApplyCancel, {
    variables: {
      applyId: applyUser.id,
    },
    onCompleted: async () => {
      await refetch();
    },
  });

  const applied = applyUser?.status === StatusApply.SUCCESS;

  if (loading) return <LoadingScreen />;

  return (
    <>
      <Divider />
      <div className="px-5">
        <li className="py-4 flex items-center justify-between">
          <div className="flex items-center space-x-3">
            <Avatar src={data?.resumeMeByUserId?.avatar} size={14} />
            <div>
              <div className="flex space-x-3 items-center">
                <p className="text-sm font-bold text-gray-900">
                  {applyUser.user.name}
                </p>
                <div className="rounded-3xl px-3 py-1 text-xs font-bold text-white bg-brand-1 opacity-50">
                  {applyUser.user.gender === Gender.MAN
                    ? '남자'
                    : applyUser.user.gender === Gender.WOMAN
                    ? '여자'
                    : '-'}
                </div>
              </div>
              <p className="text-sm text-gray-500 mt-1">
                {applied
                  ? applyUser.user.phone.replace(
                      /(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/,
                      '$1-$2-$3',
                    )
                  : '채용 확정 시 공개'}
              </p>
            </div>
          </div>
        </li>
        <div className="space-y-4">
          <div>
            <h3 className="text-sm text-gray-500">생년월일</h3>
            <div>
              {applyUser.user.birthday.replace(
                /(\d{4})(\d{2})(\d{2})/,
                '$1-$2-$3',
              )}
            </div>
          </div>
          <div>
            <h3 className="text-sm text-gray-500">상세주소</h3>

            {applied ? (
              <div>
                {applyUser.user.address} {applyUser.user.detailAddress}{' '}
              </div>
            ) : (
              <div className="text-gray-600">채용 확정 시 공개</div>
            )}
          </div>
          {data?.resumeMeByUserId && (
            <>
              <div>
                <h3 className="text-sm text-gray-500">계좌번호</h3>
                {applied ? (
                  <>
                    <div>{data?.resumeMeByUserId?.bank}</div>
                    <div>{data?.resumeMeByUserId?.bankAccount}</div>
                  </>
                ) : (
                  <div className="text-gray-600">채용 확정 시 공개</div>
                )}
              </div>
              <div>
                <h3 className="text-sm text-gray-500">이력정보</h3>
                <div>{data?.resumeMeByUserId?.career}</div>
              </div>
              <div>
                <h3 className="text-sm text-gray-500">전하고싶은 말</h3>
                <div>{data?.resumeMeByUserId?.introduction}</div>
              </div>
              {data?.resumeMeByUserId?.healthCertificate && (
                <div>
                  <h3 className="text-sm text-gray-500">보건증</h3>
                  <div className="border border-gray-200 rounded-md px-4 mt-2 h-12 flex justify-between items-center relative">
                    <div className="flex items-center">
                      <ContentFormImg className="mr-2" />
                    </div>
                    <div className="w-4/5 truncate">
                      {data?.resumeMeByUserId?.healthCertificate
                        ?.split('?')[0]
                        .split('/')
                        .pop()}
                    </div>
                    <a
                      href={data?.resumeMeByUserId?.healthCertificate}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <div className=" text-brand-1">Download</div>
                    </a>
                  </div>
                </div>
              )}

              {data?.resumeMeByUserId?.consentForm && (
                <div>
                  <h3 className="text-sm text-gray-500">부모님 동의서</h3>
                  <div className=" border border-gray-200 rounded-md px-4 mt-2 h-12 flex justify-between items-center">
                    <div className="flex items-center">
                      <ContentFormImg className="mr-2" />
                    </div>
                    <div className="w-4/5 truncate">
                      {data?.resumeMeByUserId?.consentForm
                        ?.split('?')[0]
                        .split('/')
                        .pop()}
                    </div>
                    <a
                      href={data?.resumeMeByUserId?.consentForm}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <div className=" text-brand-1">Download</div>
                    </a>
                  </div>
                </div>
              )}
            </>
          )}

          <Divider height="h-3" />
          <h3 className="text-lg font-medium pt-4">업장의 후기</h3>
          <EvaluateList employeeId={applyUser.user.id} />
        </div>

        <div className="h-12"></div>
      </div>
      <div className="w-full md:w-112 z-100 fixed bottom-0 bg-white">
        <div className="w-full p-4 flex items-center space-x-4 min-w-max">
          <div className="w-13 min-w-13 h-13 flex items-center justify-center bg-brand-1 bg-opacity-50 rounded-lg">
            <a href={`tel:${data?.resumeMeByUserId?.user.phone}`}>
              <PhoneImg stroke="#FFFFFF" />
            </a>
          </div>
          {applyUser?.status === StatusApply.SUCCESS && (
            <AlreadyReviewButton applyUserId={applyUser.user.id} />
          )}
          {applyUser?.status === StatusApply.PENDING && (
            <>
              <Button style={{ width: '50%' }} onClick={() => handleCancel()}>
                불합격처리
              </Button>
              <Button style={{ width: '50%' }} onClick={() => handleConfirm()}>
                채용하기
              </Button>
            </>
          )}
          {applyUser?.status === StatusApply.CANCEL && (
            <Button style={{ width: '100%' }} onClick={() => handleConfirm()}>
              채용하기
            </Button>
          )}
        </div>
      </div>
    </>
  );
};

export default UserResumeDetail;
