import React, { InputHTMLAttributes, forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';
import { Label } from './Label';

interface TextFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  required?: boolean;
  label?: string;
  helper?: string;
  [key: string]: any;
}

export const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  ({ className, required, label, helper, ...props }, ref) => {
    return (
      <div>
        {label && <Label required={required}>{label}</Label>}
        <input
          ref={ref}
          className={twMerge(
            'block px-4 w-full h-12 border border-gray-200 rounded-md sm:text-sm focus:ring-0 focus:border-brand-1 placeholder-gray-400 disabled:bg-gray-100 disabled:text-gray-400',
            className,
          )}
          {...props}
        />
        {helper && <div className="mt-1 text-red-500">{helper}</div>}
      </div>
    );
  },
);
