import { Recommend } from './recommend';
import { Resume } from './resumes';

export interface User {
  id: number;
  name: string;
  email: string;
  phone: string;
  gender: Gender;
  agree: boolean;
  generalNotification: boolean;
  generalPushNotification: boolean;
  urgentlyPushNotification: boolean;
  recommendPushNotification: boolean;
  role: Role;
  birthday: string;
  isActive: boolean;
  isDeleted: boolean;
  createdAt: string;
  updatedAt: string;
  resume?: Resume;
  address: string;
  ticketNumber?: number;
  detailAddress: string;
  entranceUrl: string;
  recommends: Recommend[];
}

export enum Role {
  ADMIN = 'ADMIN',
  BUSINESS = 'BUSINESS',
  USER = 'USER',
}

export enum Gender {
  MAN = 'MAN',
  WOMAN = 'WOMAN',
  NONE = 'NONE',
}
