import React from 'react';
import {
  Tabs,
  Tab,
  LoadingScreen,
  useRecoilValue,
} from '@entropyparadox/reusable-react';
import NewMainTopNavbar from '../../components/NewMainTopNavbar';
import { Route, Switch, useHistory, useLocation } from 'react-router';
import CompanyNotice from './CompanyNotice';
import CompanyProfile from './CompanyProfile';
import CompanyPayment from './CompanyPayment';
import CompanyStore from './CompanyStore';
// import CompanyCard from './CompanyCard';
import { meState } from '../../store';
import CompanyTicket from './CompanyTicket';
import MorePage from '../UserMypage/MorePage';

export const CompanyPage: React.FC = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const me = useRecoilValue(meState);

  if (!me) return <LoadingScreen />;

  return (
    <>
      <NewMainTopNavbar maintitle="마이페이지" />
      <div className="hidden md:block mt-8">
        <div className=" text-2xl text-center">마이페이지</div>
      </div>

      <div className="w-full max-w-md mx-auto md:mt-8">
        <Tabs padding="px-3">
          <Tab
            text="공고관리"
            active={pathname === '/company'}
            onClick={() => history.replace('/company')}
          />
          {/* <Tab
            text="결제내역"
            active={pathname === '/company/payment'}
            onClick={() => history.replace('/company/payment')}
          />
          <Tab
            text="카드관리"
            active={pathname === '/company/card'}
            onClick={() => history.replace('/company/card')}
          /> */}
          <Tab
            text="업장관리"
            active={pathname === '/company/store'}
            onClick={() => history.replace('/company/store')}
          />
          <Tab
            text="프로필"
            active={pathname === '/company/profile'}
            onClick={() => history.replace('/company/profile')}
          />
          <Tab
            text="이용권"
            active={pathname === '/company/ticket'}
            onClick={() => history.replace('/company/ticket')}
          />
          <Tab
            text="더보기"
            active={pathname === '/company/more'}
            onClick={() => history.replace('/company/more')}
          />
        </Tabs>
        <Switch>
          {/* <Route path="/company/card" component={CompanyCard} /> */}
          <Route path="/company/store" component={CompanyStore} />
          <Route path="/company/payment" component={CompanyPayment} />
          <Route path="/company/ticket" component={CompanyTicket} />
          <Route
            path="/company/profile"
            render={() => <CompanyProfile me={me} />}
          />
          <Route path="/company/more" component={MorePage} />
          <Route path="/company" component={CompanyNotice} />
        </Switch>
      </div>
    </>
  );
};
