import React from 'react';
import { ReactComponent as Star } from '../assets/svg/star_icon.svg';
import { ReactComponent as StarFill } from '../assets/svg/star_icon_fill.svg';

interface StarIconProps {
  fill: boolean;
  width?: string;
}

const StarIcon: React.FC<StarIconProps> = ({ fill, width }) => {
  return (
    <div>
      {fill ? (
        <StarFill className={`${width}`} />
      ) : (
        <Star className={`${width}`} />
      )}
    </div>
  );
};

export default StarIcon;
