import {
  Button,
  Checkbox,
  Label,
  PhoneNumberField,
  Section,
  Select,
  TextField,
  ToggleButton,
  useLogout,
  useMutation,
} from '@entropyparadox/reusable-react';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { CreatePhone, UpDatePhoneVerify, updateUser } from '../../api';
import { Gender, Role, User } from '../../types/user';
import { DaumPost } from '../../components/DaumPost';

interface ProfilePageProps {
  me: User;
}

const ProfilePage: React.FC<ProfilePageProps> = ({ me }) => {
  const thisMonth =
    me.birthday.substr(4, 1) === '0'
      ? me.birthday.substr(4, 2).split('0')[1]
      : me.birthday.substr(4, 2);

  const thisDay =
    me.birthday.substr(6, 1) === '0'
      ? me.birthday.substr(6, 2).split('0')[1]
      : me.birthday.substr(6, 2);
  const history = useHistory();
  const [name, setName] = useState(me?.name);
  const [birthday] = useState(me?.birthday);
  const [phone, setPhone] = useState(me?.phone);
  const [code, setCode] = useState('');
  const [authenticationNumber, setAuthenticationNumber] = useState(false);
  const [authenticationComplet, setAuthenticationComplet] = useState(false);
  const [address, setAddress] = useState(me?.address);
  const [detailAddress, setDetailAddress] = useState(me?.detailAddress);
  const [year, setYear] = useState(birthday.substr(0, 4));
  const [month, setMonth] = useState(thisMonth);
  const [day, setDay] = useState(thisDay);
  const [isOpen, setIsOpen] = useState(false);
  const [gender, setGender] = useState(me?.gender);
  const [agree, setAgree] = useState(me?.agree);
  const logout = useLogout();

  const [createPhone] = useMutation(CreatePhone, {
    variables: { phone: Number(phone) },
    onCompleted: ({ createPhone }) => {
      window.localStorage.setItem('phoneId', createPhone.id);
      setAuthenticationNumber(true);
      alert('인증번호가 발송되었습니다.');
    },
    onError: (err) => {
      console.log(err);
    },
  });

  const phnId = window.localStorage.getItem('phoneId');
  const m = month?.length === 1 ? `0${month}` : month;
  const d = day?.length === 1 ? `0${day}` : day;

  const [upDatePhoneVerify] = useMutation(UpDatePhoneVerify, {
    variables: { id: Number(phnId), code: code },
    onCompleted: () => {
      setAuthenticationComplet(true);
      window.localStorage.removeItem('phoneId');
    },
    onError: (error) => {
      alert(error);
    },
  });

  const [UPDATEUSER] = useMutation(updateUser, {
    variables: {
      phone: phone,
      name: name,
      address: address,
      detailAddress: detailAddress,
      birthday: year + m + d,
      gender: gender,
      agree: agree,
    },
    onCompleted: () => {
      window.location.reload();
    },
  });

  const handleSubmit = () => {
    UPDATEUSER();
    alert('수정이 완료 되었습니다.');
  };

  const handleLogOut = () => {
    logout();
    history.push('/main');
  };

  const maxOffset = 70;
  const thisYear = new Date().getFullYear();
  const allYears = [];
  for (let x = 0; x <= maxOffset; x++) {
    allYears.push(thisYear - x);
  }

  const yearList = allYears.map((y) => {
    return (
      <>
        <option key={y} value={y}>
          {y}년
        </option>
      </>
    );
  });

  const monthList = Array.from({ length: 12 }, (_, i) => i + 1).map((m) => {
    return (
      <>
        <option key={m} value={m}>
          {m}월
        </option>
      </>
    );
  });

  const days = new Date(Number(year), Number(month), 0).getDate();
  const dayList = Array.from({ length: days }, (_, i) => i + 1).map((d) => {
    return (
      <>
        <option key={d} value={d}>
          {d}일
        </option>
      </>
    );
  });

  return (
    <>
      <div className="w-full p-4 bg-white">
        <div className="text-xl truncate text-gray-800 font-bold my-1">
          내 프로필
        </div>
        <div className="text-base text-gray-500">
          지원 결과 혹은 추천 알바 정보를 받아볼 이메일, 연락처 정보를
          입력해주세요.
        </div>
      </div>
      <Section>
        <TextField
          label="*이름"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />

        <div>
          <Label text="*생년월일" />
          <div className="flex space-x-3.5">
            <div className="w-full">
              <Select
                placeholder="년"
                value={year}
                onChange={(e) => setYear(e.target.value)}
              >
                <option value="" selected disabled hidden>
                  년
                </option>
                {yearList}
              </Select>
            </div>
            <div className="w-full">
              <Select
                placeholder="월"
                value={month}
                onChange={(e) => setMonth(e.target.value)}
              >
                <option value="" selected disabled hidden>
                  월
                </option>
                {monthList}
              </Select>
            </div>
            <div className="w-full">
              <Select
                placeholder="일"
                value={day}
                onChange={(e) => setDay(e.target.value)}
              >
                <option value="" selected disabled hidden>
                  일
                </option>
                {dayList}
              </Select>
            </div>
          </div>
        </div>

        <div>
          <div className="mb-1 text-sm block text-gray-800">*성별</div>
          <div className="flex space-x-3">
            <ToggleButton
              text="남자"
              active={gender === Gender.MAN}
              onClick={() => setGender(Gender.MAN)}
            />
            <ToggleButton
              text="여자"
              active={gender === Gender.WOMAN}
              onClick={() => setGender(Gender.WOMAN)}
            />
            <ToggleButton
              text="선택 안함"
              active={gender === Gender.NONE}
              onClick={() => setGender(Gender.NONE)}
            />
          </div>
        </div>

        <PhoneNumberField
          label="*핸드폰 번호"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          disabled={authenticationComplet}
        />
        {authenticationNumber ? (
          <>
            {!authenticationComplet ? (
              <>
                <Button
                  variant="outlined"
                  onClick={() =>
                    phone ? createPhone() : alert('핸드폰 번호를 입력해주세요.')
                  }
                >
                  인증번호 재전송
                </Button>
                <div className="mt-4">
                  <div className="grid grid-cols-4 gap-4 mt-1">
                    <input
                      type="text"
                      name=""
                      className="col-span-3 block w-full h-12 border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:to-brand-1 sm:text-sm"
                      placeholder="인증번호를 입력해주세요."
                      value={code}
                      onChange={(e) => setCode(e.target.value)}
                    />
                    <button
                      className="col-span-1 bg-brand-1 rounded-md text-white"
                      onClick={() =>
                        code
                          ? upDatePhoneVerify()
                          : alert('인증번호를 입력해주세요.')
                      }
                    >
                      확인
                    </button>
                  </div>
                </div>
              </>
            ) : (
              <>
                {authenticationComplet && <div>* 인증이 완료되었습니다.</div>}
              </>
            )}
          </>
        ) : (
          <Button
            variant="outlined"
            onClick={() =>
              phone ? createPhone() : alert('핸드폰 번호를 입력해주세요.')
            }
          >
            인증번호 보내기
          </Button>
        )}
        {me.role === Role.USER && (
          <>
            <TextField label="*이메일" value={me.email} disabled />
            <div className="mt-4">
              <label
                htmlFor="expiration_date"
                className="block text-sm font-medium text-gray-700"
              >
                *주소
              </label>
              <div className="grid grid-cols-4 gap-4 mt-1">
                <div className="col-span-3">
                  <TextField
                    type="text"
                    name="address"
                    id="phone_number_f"
                    placeholder="주소를 입력해주세요."
                    value={address}
                    disabled
                  />
                </div>
                <button
                  className="col-span-1 bg-brand-1 rounded-md text-white"
                  onClick={() => setIsOpen(true)}
                >
                  주소 검색
                </button>
              </div>
              {isOpen && (
                <DaumPost
                  setAddress={(fullAddress: any) => setAddress(fullAddress)}
                  setIsOpen={() => setIsOpen(false)}
                />
              )}
            </div>
            <TextField
              label="*상세 주소"
              placeholder="상세 주소를 입력해주세요"
              value={detailAddress}
              onChange={(e) => setDetailAddress(e.target.value)}
            />
          </>
        )}

        <div className="flex items-center space-x-2">
          <Checkbox
            id="agree"
            onChange={() => setAgree(!agree)}
            checked={agree}
          />
          <Label htmlFor="agree" margin="m-0">
            <span className="text-sm ">마케팅 수신동의 (선택)</span>
          </Label>
        </div>

        <Button
          disabled={
            (phone !== me.phone && !authenticationComplet) ||
            !phone ||
            !name ||
            !year ||
            !month ||
            !day ||
            !address ||
            !detailAddress
          }
          onClick={() => handleSubmit()}
        >
          수정 완료
        </Button>
      </Section>
      <div className="h-4 bg-gray-100 my-3 mx-5" />
      <div className="mx-5">
        <div
          className="w-full py-5 border-b cursor-pointer"
          onClick={() => history.push('/change-password')}
        >
          비밀번호 변경
        </div>
        <div
          className="border-b w-full py-5 cursor-pointer"
          onClick={() => handleLogOut()}
        >
          로그아웃
        </div>
        <div
          className="border-b w-full py-5 cursor-pointer"
          onClick={() => history.push('/delete-account')}
        >
          회원 탈퇴
        </div>
      </div>
    </>
  );
};

export default ProfilePage;
