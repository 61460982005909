import { Button, Section } from '@entropyparadox/reusable-react';
import NewMainTopNavbar from '../components/NewMainTopNavbar';
import ChannelService from '../ChannelService';

const DeleteAccount = () => {
  return (
    <>
      <NewMainTopNavbar maintitle="회원탈퇴" />

      <div className="max-w-md mx-auto mt-4 md:mt-8">
        <Section>
          <div className="text-2xl">회원탈퇴</div>
          <div className="pb-10">
            <p>회원탈퇴는 고객센터를 통해 진행하실 수 있습니다.</p>
            <p>회원탈퇴를 원하시면 하단 버튼을 클릭하여 문의주세요.</p>
          </div>
          <div className="w-full">
            <Button
              text="회원탈퇴"
              style={{ width: '100%' }}
              onClick={() => ChannelService.showMessenger()}
            />
          </div>
        </Section>
      </div>
    </>
  );
};

export default DeleteAccount;
