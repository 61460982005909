import React from 'react';
import ReqPrefix from './ReqPrefix';
import { twMerge } from 'tailwind-merge';

interface LabelProps extends React.HTMLAttributes<HTMLLabelElement> {
  className?: string;
  text?: string;
  required?: boolean;
}

export const Label: React.FC<LabelProps> = ({
  className,
  required,
  text,
  children,
  ...props
}) => {
  return (
    <label
      className={twMerge('block mb-2 text-sm text-gray-800', className)}
      {...props}
    >
      {required && <ReqPrefix />}
      {text}
      {children}
    </label>
  );
};
