import {
  LoadingScreen,
  useQuery,
  utcToLocalFormat,
} from '@entropyparadox/reusable-react';
import React from 'react';
import { FC } from 'react';
import { TAGS, TagsData } from '../api';
import { Tag } from '../types/tag';

interface JobTagIdFieldProps {
  label?: string;
  deadLine?: string;
  detailWorkTime?: string;
  workPeriod?: number;
  wage?: number;
  workDay?: number[];
  workTime?: number[];
  payType?: number;
  industry?: number[];
}

const JobTagIdField: FC<JobTagIdFieldProps> = ({
  label,
  deadLine,
  workPeriod,
  workDay,
  workTime,
  detailWorkTime,
  wage,
  payType,
  industry,
}) => {
  const { loading, error, data } = useQuery<TagsData>(TAGS);

  if (loading) return <LoadingScreen />;
  if (error || !data) return <></>;

  const tagWorkPeriod = data.tags.filter((tag) => tag.id === workPeriod);
  const tagPayType = data.tags.filter((tag) => tag.id === payType);

  if (wage && tagPayType) {
    return (
      <div className="flex justify-between items-center">
        <span className="text-gray-500">급여</span>
        <div className="flex items-center space-x-2">
          {tagPayType.map((tagJob) => (
            <div
              key={tagJob.id}
              className={`${
                tagJob.title === '시급'
                  ? 'border-brand-7 text-brand-7'
                  : tagJob.title === '월급'
                  ? 'border-green-500 text-green-500'
                  : ''
              } px-2 py-1 border-2 font-bold text-xs`}
            >
              <>{tagJob.title}</>
            </div>
          ))}
          <span className="font-bold">
            {String(wage).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원
          </span>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="flex justify-between">
        <div className="text-gray-500 flex-shrink-0">{label}</div>
        <div className="text-gray-800 font-bold text-right">
          {deadLine && (
            <div>
              {utcToLocalFormat(deadLine || '', 'YYYY년 MM월 DD일')}까지
            </div>
          )}
          {workPeriod && (
            <div>
              {tagWorkPeriod.map((tagJob) => (
                <span key={tagJob.id}>{tagJob.title}</span>
              ))}
            </div>
          )}
          {workDay && (
            <>
              {data.tags
                .map((tag: Tag) => workDay?.includes(tag.id) && tag.title)
                .filter((el) => !!el)
                .join(',')}
            </>
          )}
          {workTime && (
            <>
              {data.tags.map((tag: Tag, index) => {
                if (workTime?.includes(tag.id)) {
                  return <span key={'worktime' + tag.id}>{tag.title}</span>;
                }
                return <div key={'emptyworktime' + index}></div>;
              })}
            </>
          )}
          {detailWorkTime && <div>{detailWorkTime}</div>}
          {industry && (
            <>
              {data.tags
                .map((tag: Tag) => industry?.includes(tag.id) && tag.title)
                .filter((el) => !!el)
                .join(',')}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default JobTagIdField;
