import React from 'react';
import {
  Tabs,
  Tab,
  useRecoilValue,
  LoadingScreen,
} from '@entropyparadox/reusable-react';
import NewMainTopNavbar from '../../components/NewMainTopNavbar';
import { Route, Switch, useHistory, useLocation } from 'react-router';
import ProfilePage from './ProfilePage';
import ResumePage from './ResumePage';
import ApplyList from './ApplyList';
import { meState } from '../../store';
import { NotificationSetting } from './NotificationSetting';
import MorePage from './MorePage';

export const MainMypage: React.FC = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const me = useRecoilValue(meState);

  if (!me) return <LoadingScreen />;

  return (
    <>
      <NewMainTopNavbar maintitle="마이페이지" />
      <div className="hidden md:block">
        <div className="text-2xl mt-8 text-center">마이페이지</div>
      </div>
      <div className="md:max-w-md md:mx-auto md:mt-8 w-full">
        <div className="flex scroll-box px-3 w-full overflow-x-scroll border-b border-gray-200">
          <Tab
            text="지원리스트"
            active={pathname === '/mypage'}
            onClick={() => history.replace('/mypage')}
          />
          <Tab
            text="이력서"
            active={pathname === '/mypage/resume'}
            onClick={() => history.replace('/mypage/resume')}
          />
          <Tab
            text="프로필"
            active={pathname === '/mypage/profile'}
            onClick={() => history.replace('/mypage/profile')}
          />
          <Tab
            text="알림설정"
            active={pathname === '/mypage/notifications'}
            onClick={() => history.replace('/mypage/notifications')}
          />
          <Tab
            text="더보기"
            active={pathname === '/mypage/more'}
            onClick={() => history.replace('/mypage/more')}
          />
        </div>
        <Switch>
          <Route
            path="/mypage/notifications"
            render={() => <NotificationSetting me={me} />}
          />
          <Route path="/mypage/resume" component={ResumePage} />
          <Route
            path="/mypage/profile"
            render={() => <ProfilePage me={me} />}
          />
          <Route path="/mypage/more" component={MorePage} />
          <Route path="/mypage/" component={ApplyList} />
        </Switch>
      </div>
    </>
  );
};
