import React from 'react';
import NewMainTopNavbar from '../../components/NewMainTopNavbar';

export const MarketingAgreePage = () => {
  return (
    <>
      <NewMainTopNavbar />

      <div className="w-full h-full flex justify-center">
        <div className="max-w-7xl w-full mt-4 md:mt-8 whitespace-pre-line px-4">
          <h1 className="text-2xl md:text-3xl font-bold">
            마케팅 수신 동의 약관
          </h1>
          {`
1. 정보 수집 이용 목적
수집 및 동의한 개인정보를 활용하여 이용자 맞춤형 서비스 및 상품 추천, 강종 이벤트 등의 광고 정보를 이메일, 전화, 문자 등을 통해 이용자에게 제공합니다.

2. 개인정보 보유 및 이용기간
회원탈퇴를 요청하거나 본 동의를 철회하는 경우, 소정 및 이용목적이 달성되거나 이용기간이 종료한 경우에는 개인정보를 파기합니다. 단 , 상법 등 관계범령의 규정에 의하여 보전할 필요가 있는 경우 범령에서 규정한 보존기간 동안 거래내역과 최소한의 기본정보를 보유합니다.

3. 마케팅 수신 동의 철회
본 동의는 거부할 수 있으며 동의는 고객의 의사에 따라 철회하실 수 있습니다. 철회 방법은 마이페이지 > 프로필에서 철회 가능합니다.`}
        </div>
      </div>
    </>
  );
};
