import {
  LoadingScreen,
  Select,
  useMutation,
  useQuery,
  useRecoilValue,
} from '@entropyparadox/reusable-react';
import React, { useEffect, useState } from 'react';
import { meState } from '../store';
import NewMainTopNavbar from '../components/NewMainTopNavbar';
import {
  CreateRecommend,
  MyRecommend,
  MyRecommendData,
  UpdateRecommend,
} from '../api';
import { SIGUNGUS } from '../types/sigungus';
import { RecommendItem } from '../components/RecommendItem';

const RecommendPage = () => {
  const { loading, error, data } = useQuery<MyRecommendData>(MyRecommend);
  const [sido, setSido] = useState(Object.keys(SIGUNGUS)[0]);
  const [sigungu, setSigungu] = useState('');
  const [industry, setIndustry] = useState<number[]>([]);
  const [jobType, setJobType] = useState<number[]>([]);
  const [detailWorkTime, setDetailWorkTime] = useState('');
  const [workDay, setWorkDay] = useState<number[]>([]);

  useEffect(() => {
    if (data?.me?.recommends?.length) {
      const recommend = data?.me?.recommends[data.me.recommends.length - 1];
      setSido(recommend.sido);
      setSigungu(recommend.sigungu);
      setIndustry(recommend.industry);
      setJobType(recommend.jobType);
      setDetailWorkTime(recommend.detailWorkTime);
      setWorkDay(recommend.workDay);
    }
  }, [data]);

  const [createRecommend] = useMutation(CreateRecommend, {
    variables: {
      sido,
      sigungu,
      industry,
      jobType,
      detailWorkTime,
      workDay,
    },
    onCompleted: () => {
      alert('추천 알바 정보를 저장했습니다.');
    },
    onError: (error) => {
      alert(error);
    },
  });

  const [updateRecommend] = useMutation(UpdateRecommend, {
    variables: {
      sido,
      sigungu,
      industry,
      jobType,
      detailWorkTime,
      workDay,
    },
    onCompleted: () => {
      alert('추천 알바 정보를 저장했습니다.');
    },
    onError: (error) => {
      alert(error);
    },
  });

  const me = useRecoilValue(meState);

  const disabled = !(
    sido &&
    sigungu &&
    industry.length &&
    jobType.length &&
    detailWorkTime &&
    workDay.length
  );

  if (!me) {
    return <></>;
  }

  if (loading) return <LoadingScreen />;
  if (error || !data) return <></>;

  return (
    <>
      <NewMainTopNavbar />
      <div className="w-full max-w-md mx-auto md:mt-8 bg-white flex justify-center items-center h-full">
        <div className="w-full px-4 flex flex-col justify-center space-y-6 items-center">
          <div className="text-xl md:text-2xl truncate text-gray-800 font-bold my-1">
            맞춤 알바 설정
          </div>
          <div className="text-left w-full">
            <div className="text-lg md:text-xl">지역</div>
            <div className="flex items-center space-x-2 w-full mt-2">
              <Select
                style={{
                  padding: '1rem 2rem',
                  paddingRight: '3rem',
                  height: 'auto',
                }}
                value={sido}
                onChange={(e) => setSido(e.target.value)}
              >
                <option disabled hidden value="">
                  시도
                </option>
                {Object.keys(SIGUNGUS).map((sido) => (
                  <option>{sido}</option>
                ))}
              </Select>
              <Select
                style={{
                  padding: '1rem 2rem',
                  paddingRight: '3rem',
                  height: 'auto',
                }}
                value={sigungu}
                onChange={(e) => setSigungu(e.target.value)}
              >
                <option disabled hidden value="">
                  시군구
                </option>
                {SIGUNGUS[sido]?.map((sigungu) => (
                  <option>{sigungu}</option>
                ))}
              </Select>
            </div>
          </div>
          <div className="text-left w-full">
            <div className="text-lg md:text-xl">업종</div>
            <div>
              {data.tags
                ?.filter((el) => el.status === 'INDUSTRY')
                .map((tag) => (
                  <RecommendItem
                    tag={tag}
                    arrayState={industry}
                    setArrayState={setIndustry}
                  />
                ))}
            </div>
          </div>
          <div className="text-left w-full">
            <div className="text-lg md:text-xl">근무형태</div>
            <div>
              {data.tags
                ?.filter((el) => el.status === 'JOBTYPE')
                .map((tag) => (
                  <RecommendItem
                    tag={tag}
                    arrayState={jobType}
                    setArrayState={setJobType}
                  />
                ))}
            </div>
          </div>
          <div className="text-left w-full">
            <div className="text-lg md:text-xl">근무시간</div>
            <div>
              {['오전', '오후', '새벽', '풀타임'].map((tag) => (
                <div
                  className={`cursor-pointer text-center py-2 px-4 inline-block mr-2 my-2 rounded-full whitespace-pre ${
                    detailWorkTime === tag
                      ? 'bg-brand-1 text-white'
                      : 'bg-gray-100'
                  }`}
                  onClick={() => setDetailWorkTime(tag)}
                >
                  {tag}
                </div>
              ))}
            </div>
          </div>
          <div className="text-left w-full">
            <div className="text-lg md:text-xl">근무요일</div>
            <div>
              {data.tags
                ?.filter((el) => el.status === 'WORKDAY')
                .map((tag) => (
                  <RecommendItem
                    tag={tag}
                    arrayState={workDay}
                    setArrayState={setWorkDay}
                  />
                ))}
            </div>
          </div>
          <div className=" w-full flex space-x-2 pt-8">
            <button
              className={`text-center w-full cursor-pointer py-4 px-6 rounded-xl ${
                disabled ? 'bg-gray-300' : 'bg-brand-1 text-white'
              }`}
              onClick={() =>
                !disabled && data?.me?.recommends?.length
                  ? updateRecommend()
                  : createRecommend()
              }
            >
              저장하기
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default RecommendPage;
