import React from 'react';
import { Button, ListItem } from '@entropyparadox/reusable-react';
import { ReactComponent as ChevronRight } from '../assets/svg/chevron-right.svg';
import { Company } from '../types/company';
import { useHistory } from 'react-router';

interface WorkShopItemProps {
  companies: Company[];
}

export const WorkShopItem: React.FC<WorkShopItemProps> = ({ companies }) => {
  const history = useHistory();
  return (
    <>
      <div className="flex justify-between items-center py-2 px-4 bg-gray-50">
        <p className="">총 {companies?.length}개</p>
        <button
          className="bg-brand-1 text-white rounded-full w-fit px-5 py-2"
          onClick={() => alert('업장 추가 등록은 고객센터에 문의해주세요.')}
        >
          + 새 업장 등록
        </button>
      </div>
      {companies.map((company) => {
        const i = company.jobs.filter((q) => q.companyId === company.id);

        let lo = [];
        lo.push(i);

        return (
          <ListItem onClick={() => history.push(`/notice/${company.id}`)}>
            <div className="flex items-center space-x-2">
              <div className="w-full text-left">
                <div className="font-bold text-gray-900 text-lg">
                  {company.name}
                </div>
                <div className="text-gray-600 mt-3">
                  등록된 공고 : {lo[0].length}건
                </div>
                <div className="text-gray-600 mt-2">
                  {company.address} {company.detailAddress}
                </div>
              </div>
              <ChevronRight stroke="#B0B8C1" />
            </div>
          </ListItem>
        );
      })}
    </>
  );
};
