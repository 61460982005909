import { Link } from 'react-router-dom';
import { ReactComponent as ChevronRightIcon } from '../../assets/svg/chevron-right.svg';

const QnaSection = () => {
  return (
    <div className="max-w-screen-lg mx-auto w-full px-5 lg:space-y-12 space-y-5">
      <div className="flex items-center justify-between">
        <p className="lg:text-4xl font-bold text-2xl">무엇을 도와드릴까요?</p>
      </div>

      <div className="grid lg:grid-cols-2 grid-cols-1 gap-5">
        <QnaSectionCard to="/more?tab=QNA" title="자주하는 질문" />
        <QnaSectionCard to="/more?tab=USAGE" title="서비스 이용 가이드" />
      </div>
    </div>
  );
};

export default QnaSection;

const QnaSectionCard = (props: { to: string; title: string }) => {
  const { to, title } = props;
  return (
    <Link to={to}>
      <div className="rounded-xl bg-white shadow-sm p-5 flex justify-between items-center">
        <div>
          <p className="md:text-xl text-lg">{title}</p>
        </div>
        <ChevronRightIcon />
      </div>
    </Link>
  );
};
