import {
  LoadingScreen,
  useMutation,
  useQuery,
} from '@entropyparadox/reusable-react';
import React, { FC } from 'react';
import { ReactComponent as HeartIcon } from '../assets/svg/heart-icon.svg';
import { ReactComponent as HeartColorIcon } from '../assets/svg/heart-icon-color.svg';
import { Job } from '../types/job';
import { createLike, deleteLike, likeByAdIdUserId, LikeData } from '../api';

interface JobLikeIconProps {
  job: Job;
}

const JobLikeIcon: FC<JobLikeIconProps> = ({ job }) => {
  const { loading, data } = useQuery<LikeData>(likeByAdIdUserId, {
    variables: { jobId: job.id },
  });

  const [DeleteLike] = useMutation(deleteLike, {
    variables: { jobId: job.id },
    refetchQueries: [{ query: likeByAdIdUserId, variables: { jobId: job.id } }],
  });

  const [CreateLike] = useMutation(createLike, {
    variables: { jobId: job.id },
    refetchQueries: [{ query: likeByAdIdUserId, variables: { jobId: job.id } }],
  });

  if (loading) return <LoadingScreen />;

  return (
    <>
      {data?.likeByAdIdUserId?.jobId === job.id ? (
        <HeartColorIcon onClick={() => DeleteLike()} />
      ) : (
        <HeartIcon onClick={() => CreateLike()} />
      )}
    </>
  );
};

export default JobLikeIcon;
