import {
  LoadingScreen,
  Section,
  useIdParam,
  useQuery,
} from '@entropyparadox/reusable-react';
import React from 'react';
import { applyUser, ApplyUserData } from '../api';
import NewMainTopNavbar from '../components/NewMainTopNavbar';
import UserResumeDetail from '../components/UserResumeDetail';

const ApplicantDetail = () => {
  const id = useIdParam();

  const { loading, error, data, refetch } = useQuery<ApplyUserData>(applyUser, {
    variables: { applyId: id },
  });

  if (loading) return <LoadingScreen />;
  if (error || !data) return <></>;

  return (
    <>
      <NewMainTopNavbar maintitle="상세보기" />
      <div className="max-w-md mx-auto">
        <div className="hidden md:block text-2xl text-center md:mt-8 mb-6 font-semibold">
          상세 보기
        </div>
        <Section>
          <div className="text-xl font-bold text-gray-800">
            {data.applyUser.job.title}
          </div>
          <div className="text-gray-500">{data.applyUser.job.summery}</div>
        </Section>
        <UserResumeDetail
          applyUser={data.applyUser}
          refetch={() => refetch()}
        />
      </div>
    </>
  );
};

export default ApplicantDetail;
