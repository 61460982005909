import React, { FC, ReactElement } from 'react';
import { Link } from 'react-router-dom';

interface NavItemProps {
  className: string;
  icon: ReactElement;
  menu: string;
  path: string;
}

export const NavItem: FC<NavItemProps> = ({ className, icon, menu, path }) => {
  return (
    <Link
      to={path}
      className={`w-15 flex justify-center items-center flex-col space-y-1 ${className}`}
    >
      <div className="fill-current">{icon}</div>
      <p className="text-xxs font-medium">{menu}</p>
    </Link>
  );
};
