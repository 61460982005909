import { useQuery, useRecoilState } from '@entropyparadox/reusable-react';
import React, { useEffect, useState } from 'react';
import { Route, Switch, useLocation, withRouter } from 'react-router-dom';
import { JobsPage } from './JobsPage';
import './index.css';
import { JobPage } from './JobPage';
import { CompanyDetailPage } from './CompanyDetailPage';
import { FilteringPage } from './FilteringPage';
import { HomeData, homeQuery } from '../api';
import ModalPopUp from '../components/ModalPopUp';
import { meState } from '../store';
import { NaverMap } from '../components/NaverMap';
import { Job } from '../types/job';
import { MapBounds } from '../types/Mapdata';
import { ReactComponent as ListIcon } from '../assets/svg/list.svg';
import NewMainTopNavbar from '../components/NewMainTopNavbar';
import { TagFilterType } from '../types/tag';
import { ReactComponent as UrgentJobLogo } from '../assets/svg/urgent_job_logo.svg';

const MapPage = () => {
  const { pathname } = useLocation();
  const [map, setMap] = useState<any>();
  const [isMapView, setMapView] = useState(true);
  const [bounds, setBounds] = useState<MapBounds>();
  const [close, setClose] = useState(true);
  const [me] = useRecoilState(meState);
  const [urgentJobs, setUrgentJobs] = useState<Job[]>([]);
  const [selectedTags, setSelectedTags] = useState<TagFilterType>({});
  const [searchTitle, setSearchTitle] = useState<string>('');

  let tagIds = localStorage.getItem('selectedTags');
  let albaTypes = localStorage.getItem('selectedAlbaType');
  if (!tagIds) tagIds = '';
  if (!albaTypes) albaTypes = '';

  useEffect(() => {
    if (!tagIds) return;
    const tags = JSON.parse(tagIds) as TagFilterType;
    setSelectedTags(tags);
  }, [tagIds]);

  const { data, loading } = useQuery<HomeData>(homeQuery, {
    variables: {
      tagIds: JSON.stringify(selectedTags),
      albaTypes: albaTypes,
      bounds: bounds ? JSON.stringify(bounds) : '',
      filter: JSON.stringify({ title: searchTitle }),
    },
  });

  useEffect(() => {
    document.documentElement.style.setProperty('overflow', 'hidden');
    return () => document.documentElement.style.setProperty('overflow', 'auto');
  }, []);

  useEffect(() => {
    if (!urgentJobs.length) {
      setUrgentJobs(data?.urgentJobs ?? []);
    }
  }, [data]);

  const clientWidth = () => {
    return (
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth
    );
  };

  const isDesktop = () => {
    return clientWidth() >= 768;
  };

  // 데스크탑 뷰
  function renderDesktop() {
    return (
      <>
        {me?.role === 'USER' && me.resume === null && close === true && (
          <ModalPopUp
            title="알바 지원을 위해"
            title2="이력서를 등록해주세요 :)"
            text1="이력서 등록하기"
            link1="/mypage/resume"
            inset="inset-x-0"
            createApply={() => ''}
            setClose={() => {
              setClose(false);
            }}
          />
        )}
        <NewMainTopNavbar />
        <div className="absolute inset-0 top-24 flex flex-col overflow-hidden">
          {urgentJobs?.length !== 0 && (
            <div className="bg-gray-50 py-4 px-4 w-full space-y-2">
              <div className="">번쩍 알바</div>
              <div className="w-full space-x-4 flex items-center overflow-x-auto">
                {urgentJobs?.map((job) => (
                  <a
                    href={`/map/jobs/${job.id}`}
                    className="relative flex items-center justify-center border border-gray-100 rounded-lg  bg-white"
                    key={job.id}
                  >
                    <div className="flex space-x-4  py-3 px-4 min-w-52 rounded-lg shadow-md">
                      <div className="place-content-center">
                        <UrgentJobLogo className="w-8 h-8" />
                      </div>
                      <div className="z-10 text-black text-start">
                        <div className="text-gray-500 text-xs">
                          {job?.company?.name || '급구 알바'}
                        </div>
                        <div className="text-sm">
                          <span>
                            {data?.tags.filter(
                              (tag) => tag.id === job?.payType,
                            )[0]?.title || '시급'}{' '}
                          </span>
                          <span className="font-bold text-lg text-black">
                            {job?.wage?.toLocaleString()}원
                          </span>
                        </div>
                      </div>
                    </div>
                  </a>
                ))}
              </div>
            </div>
          )}
          <div className="flex items-center justify-center w-full h-full">
            <div
              id="mapViewContainer"
              className="w-full overflow-hidden h-full"
            >
              <NaverMap
                searchTitle={searchTitle}
                setSearchTitle={setSearchTitle}
                selectedTags={selectedTags}
                setSelectedTags={setSelectedTags}
                tags={data?.tags ?? []}
                places={data?.jobsByTagAndBounds ?? []}
                bounds={bounds}
                setBounds={setBounds}
                map={map}
                setMap={setMap}
                isPlaceLoading={loading}
              />
            </div>

            <div className="h-full w-112">
              <div
                className={`h-full flex flex-col shadow-xl overflow-y-scroll scroll-box`}
              >
                <div className={`bg-white z-10 h-full scroll-box relative`}>
                  <Switch>
                    <Route
                      path="/map/companies/:id"
                      component={CompanyDetailPage}
                    />
                    <Route
                      path="/map/jobs/:id"
                      component={() => <JobPage map={map} />}
                    />
                    <Route path="/map/filter" component={FilteringPage} />
                    <Route
                      path="/map"
                      component={() => (
                        <JobsPage
                          isMapView={isMapView}
                          setMapView={(b: boolean) => setMapView(b)}
                          jobs={data?.jobsByTagAndBounds}
                        />
                      )}
                    />
                  </Switch>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  // 모바일 뷰
  function renderMobile() {
    return (
      <>
        {me?.role === 'USER' && me?.resume === null && close === true && (
          <ModalPopUp
            title="알바 지원을 위해"
            title2="이력서를 등록해주세요 :)"
            text1="이력서 등록하기"
            link1="/mypage/resume"
            inset="inset-x-0"
            createApply={() => ''}
            setClose={() => {
              setClose(false);
            }}
          />
        )}
        <div className={``}>
          <div
            id="mapViewContainer"
            className={`absolute inset-y-0 left-0 w-full overflow-hidden ${
              isMapView === true && pathname === '/map'
                ? 'block z-10'
                : 'hidden'
            }`}
          >
            <NaverMap
              searchTitle={searchTitle}
              setSearchTitle={setSearchTitle}
              selectedTags={selectedTags}
              setSelectedTags={setSelectedTags}
              tags={data?.tags ?? []}
              places={data?.jobsByTagAndBounds ?? []}
              bounds={bounds}
              setBounds={setBounds}
              map={map}
              setMap={setMap}
              isPlaceLoading={loading}
            />
            <div className="fixed bottom-24 z-10 w-full flex justify-center">
              <button
                className="bg-brand-1 rounded-full py-2 px-4 text-white flex space-x-2"
                onClick={() => setMapView(false)}
              >
                <ListIcon />
                <p>리스트로 보기</p>
              </button>
            </div>
          </div>
          <div
            className={`${
              isMapView === true && pathname === '/map'
                ? 'hidden'
                : 'block z-10'
            }`}
          >
            <div className={`h-full flex flex-col shadow-xl`}>
              <div
                className={`bg-white scroll-box`}
                style={{
                  height: '100dvh',
                  overflow: 'hidden scroll',
                }}
              >
                <Switch>
                  <Route
                    path="/map/companies/:id"
                    component={CompanyDetailPage}
                  />
                  <Route path="/map/filter" component={FilteringPage} />
                  <Route
                    path="/map/jobs/:id"
                    component={() => <JobPage map={map} />}
                  />
                  <Route
                    path="/map"
                    component={() => (
                      <JobsPage
                        isMapView={isMapView}
                        setMapView={(b: boolean) => setMapView(b)}
                        jobs={data?.jobsByTagAndBounds}
                      />
                    )}
                  />
                </Switch>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  return <>{isDesktop() ? <>{renderDesktop()}</> : <>{renderMobile()}</>}</>;
};

export default withRouter(MapPage);
