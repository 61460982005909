import {
  AspectRatioImage,
  IconButton,
  LoadingScreen,
  Section,
  useQuery,
} from '@entropyparadox/reusable-react';
import React, { FC, useState } from 'react';
import { useHistory } from 'react-router';
import { CompanyData, companyOne } from '../api';
import { ReactComponent as VerticalMenu } from '../assets/svg/menu-vertical.svg';

interface CompanyContentFieldProps {
  companyId?: number;
}

const CompanyContentField: FC<CompanyContentFieldProps> = ({ companyId }) => {
  const { loading, error, data } = useQuery<CompanyData>(companyOne, {
    variables: {
      companyId,
    },
  });

  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();

  if (loading) return <LoadingScreen />;
  if (error || !data) return <></>;

  return (
    <Section>
      <div className="flex items-center justify-between relative">
        <div className="text-xl font-bold text-gray-800">
          {data.companyOne.name}
        </div>
        <IconButton
          icon={<VerticalMenu />}
          onClick={() => setIsOpen(!isOpen)}
        />
        {isOpen === true && (
          <div className="py-1 border rounded-md text-sm text-gray-700  cursor-pointer absolute right-2 top-10 bg-white">
            <p
              className="hover:bg-gray-100 hover:text-gray-90 py-2 px-4"
              onClick={() => history.push(`/notice/${data.companyOne.id}/edit`)}
            >
              수정하기
            </p>
            <p
              className="hover:bg-gray-100 hover:text-gray-90 py-2 px-4"
              onClick={() => setIsOpen(false)}
            >
              취소
            </p>
          </div>
        )}
      </div>
      <div>
        <div className="text-gray-500">업장 주소</div>
        <div className="text-gray-800">
          {data.companyOne.address} {data.companyOne.detailAddress}
        </div>
      </div>
      {data.companyOne.number !== null && (
        <div>
          <div className="text-gray-500">업장 전화번호</div>
          <div className="text-gray-800">{data.companyOne.number}</div>
        </div>
      )}
      <div>
        <div className="text-gray-500">홈페이지</div>
        <div className="text-gray-800">{data.companyOne.url}</div>
      </div>
      <div>
        <div className="text-gray-500">사업내용</div>
        <div className="text-gray-800">{data.companyOne.introduction}</div>
      </div>
      <div>
        <div className="text-gray-500">사업자 등록증</div>
        <AspectRatioImage
          src={data.companyOne.license || ''}
          alt=""
          pb="pb-2/3"
        />
      </div>
    </Section>
  );
};

export default CompanyContentField;
