import { List, LoadingScreen, useQuery } from '@entropyparadox/reusable-react';
import React from 'react';
import { CompaniesData, MyCompanies } from '../../api';
import { WorkShopItem } from '../../components';

const CompanyStore = () => {
  const { loading, error, data } = useQuery<CompaniesData>(MyCompanies);

  if (loading) return <LoadingScreen />;
  if (error || !data) return <></>;

  return (
    <List>
      <WorkShopItem companies={data.businessUserGetMyCompany} />
    </List>
  );
};

export default CompanyStore;
