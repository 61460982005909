import { Button } from '@entropyparadox/reusable-react';
import { FC } from 'react';

interface UnathorizedRouteModalProps {
  isCompany: boolean;
  onClose: () => void;
}

const UnathorizedRouteModal: FC<UnathorizedRouteModalProps> = ({
  isCompany = false,
  onClose,
}) => {
  return (
    <div
      className={`w-80 m-auto z-100 opacity-100 scale-100 duration-100 ease-in fixed top-1/4 inset-x-0 p-2 transition transform origin-top-right`}
    >
      <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
        <div className="pt-5 pb-6 px-5">
          <div className="flex items-center justify-between mb-4">
            <div className="font-bold text-base">안내</div>

            <button
              type="button"
              className="bg-white rounded-md inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
              onClick={() => onClose()}
            >
              <span className="sr-only">Close menu</span>
              <svg
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinejoin="round"
                  strokeLinecap="round"
                  stroke-width="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <div className="w-full">
            <div className="text-sm text-left">
              <p>
                {isCompany
                  ? '알바 유저만 이용 가능합니다.'
                  : '업장용 유저만 이용 가능합니다.'}
              </p>
            </div>
            <div className="w-full mt-8">
              <Button
                text="확인"
                style={{ width: '100%' }}
                onClick={() => onClose()}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnathorizedRouteModal;
