import {
  LoadingScreen,
  useIdParam,
  useQuery,
} from '@entropyparadox/reusable-react';
import React from 'react';
import { CompanyData, companyOne } from '../api';
import CompanyEditContent from '../components/CompanyEditContent';

const NoticeEdit = () => {
  const id = useIdParam();

  const { loading, error, data, refetch } = useQuery<CompanyData>(companyOne, {
    variables: {
      companyId: id,
    },
  });

  if (loading) return <LoadingScreen />;
  if (error || !data) return <></>;

  return (
    <CompanyEditContent company={data.companyOne} refetch={() => refetch()} />
  );
};

export default NoticeEdit;
