import { BrowserRouter, Route } from 'react-router-dom';
import MainRouter from './pages/MainRouter';
import ScrollToTop from './util/scroll';

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Route path="/" component={MainRouter} />
    </BrowserRouter>
  );
}

export default App;
