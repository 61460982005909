import React from 'react';
import { Tag } from '../types/tag';
import { Chip } from './Chip';
import { Label } from './Label';

interface SeletedTagFieldProps {
  required?: boolean;
  tags: Tag[];
  type: string;
  label?: string;
  state: number | number[];
  setState: (value: any) => void;
  multiple?: boolean;
  helper?: string;
}

export const SeletedTagField: React.FC<SeletedTagFieldProps> = ({
  required,
  tags,
  type,
  label,
  state,
  setState,
  multiple,
  helper,
}) => {
  const arrayState: number[] =
    typeof state === 'number' ? [] : (state as number[]) || [];
  return (
    <div>
      {label && <Label required={required}>{label}</Label>}
      <div className="flex flex-wrap">
        {tags
          .filter((tag) => tag.status === type)
          .map((tag) =>
            multiple ? (
              <Chip
                key={tag.id}
                text={tag.title}
                style={{ margin: '0.25rem' }}
                onClick={() =>
                  arrayState.includes(tag.id)
                    ? setState(arrayState.filter((id: number) => id !== tag.id))
                    : setState(arrayState.concat(tag.id))
                }
                selected={arrayState.includes(tag.id)}
              />
            ) : (
              <Chip
                key={tag.id}
                text={tag.title}
                style={{ margin: '0.25rem' }}
                onClick={() =>
                  state === tag.id ? setState(undefined) : setState(tag.id)
                }
                selected={state === tag.id}
              />
            ),
          )}
      </div>
      {helper && <div className="mt-1 text-red-500">{helper}</div>}
    </div>
  );
};
