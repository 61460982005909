import { useMutation } from '@entropyparadox/reusable-react';
import React, { FC, useState } from 'react';
import { ME, updateUserNotification } from '../../api';
import { SwitchButton } from '../../components/SwitchButton';
import { User } from '../../types/user';

interface NotificationSettingProps {
  me: User;
}

export const NotificationSetting: FC<NotificationSettingProps> = ({ me }) => {
  const [generalNotification, setGeneralNotification] = useState(
    me.generalNotification,
  );
  const [generalPushNotification, setGeneralPushNotification] = useState(
    me.generalPushNotification,
  );
  const [urgentlyPushNotification, setUrgentlyPushNotification] = useState(
    me.urgentlyPushNotification,
  );
  const [recommendPushNotification, setRecommendPushNotification] = useState(
    me.recommendPushNotification,
  );

  const [UpdateUserNotification] = useMutation(updateUserNotification, {
    variables: {
      generalNotification,
      generalPushNotification,
      urgentlyPushNotification,
      recommendPushNotification,
    },
    refetchQueries: [{ query: ME }],
  });

  const handleSubmit = () => {
    UpdateUserNotification();
  };

  return (
    <div className="mt-2">
      <div className="px-7 py-6 border-b border-brand-8">
        <div className="flex items-center justify-between">
          <div className="text-gray-900 space-y-2">
            <p className="text-2lg font-bold">일반 알림</p>
            <p className="text-2xs">
              알림 합격 알림 등 일반적인 앱 내 알림을 <br />
              푸쉬 알림을 안내해드립니다.
            </p>
          </div>
          <div>
            <SwitchButton
              id={generalNotification}
              value={generalNotification}
              onChange={async (e) => {
                await setGeneralNotification(e);
                handleSubmit();
              }}
            />
          </div>
        </div>
      </div>
      <div className="px-7 py-6 border-b border-brand-8">
        <div className="flex items-center justify-between">
          <div className="text-gray-900 space-y-2">
            <p className="text-2lg font-bold">일반 공고 푸쉬 알림</p>
            <p className="text-2xs">
              일반 공고 푸쉬 알림을 설정하면 일반공고가 올라갔을 경우
              <br />
              푸쉬알림을 통해 안내해드립니다.
            </p>
          </div>
          <div>
            <SwitchButton
              id={generalPushNotification}
              value={generalPushNotification}
              onChange={async (e) => {
                await setGeneralPushNotification(e);
                handleSubmit();
              }}
            />
          </div>
        </div>
      </div>
      <div className="px-7 py-6 border-b border-brand-8">
        <div className="flex items-center justify-between">
          <div className="text-gray-900 space-y-2">
            <p className="text-2lg font-bold">번쩍 알바 푸쉬 알림</p>
            <p className="text-2xs">
              번쩍 공고 푸쉬 알림을 설정하면 공고가 올라갔을 경우 <br />
              푸쉬알림을 통해 안내해드립니다.
            </p>
          </div>
          <div>
            <SwitchButton
              id={urgentlyPushNotification}
              value={urgentlyPushNotification}
              onChange={async (e) => {
                await setUrgentlyPushNotification(e);
                handleSubmit();
              }}
            />
          </div>
        </div>
      </div>

      <div className="px-7 py-6 border-b border-brand-8">
        <div className="flex items-center justify-between">
          <div className="text-gray-900 space-y-2">
            <p className="text-2lg font-bold">맞춤 알바 푸쉬 알림</p>
            <p className="text-2xs">
              맞춤 공고 푸쉬 알림을 설정하면 공고가 올라갔을 경우 <br />
              푸쉬알림을 통해 안내해드립니다.
            </p>
          </div>
          <div>
            <SwitchButton
              id={recommendPushNotification}
              value={recommendPushNotification}
              onChange={async (e) => {
                await setRecommendPushNotification(e);
                handleSubmit();
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
