import React, { useState } from 'react';
import NewMainTopNavbar from '../../components/NewMainTopNavbar';

export const PersonalInfoPage = () => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <NewMainTopNavbar />

      <div className="w-full h-full flex justify-center">
        <div className="max-w-7xl w-full mt-4 md:mt-8 whitespace-pre-line px-4">
          <h1 className="text-2xl md:text-3xl font-bold">개인정보 처리방침</h1>
          {`
# 개인정보 처리방침
시행일자 : 2023년 11월 17일

우구구(이하 "회사")는 정보통신망 이용촉진 및 정보보호 등에 관한 법률, 개인정보 보호법, 통신비밀보호법, 전기통신사업법 등 정보통신서비스제공자가 준수하여야 할 관련 법규상의 개인정보보호 규정을 준수하며, 관련 법령에 의거한 개인정보처리방침을 정하여 이용자의 권익 보호에 최선을 다하고 있습니다.

개인정보처리방침은 개인정보와 관련한 법령 또는 지침의 변경이 있는 경우 갱신되고, 회사 정책 변화에 따라 달라질 수 있으니 이용자께서는 사이트를 방문 시 수시로 확인하여 주시기 바랍니다. 개인정보처리방침은 다음과 같은 내용을 담고 있습니다.
    `}
          <table>
            <tr>
              <td>1. 개인정보의 수집 및 이용</td>
              <td>9. 개인정보의 안전성 확보조치 기준</td>
            </tr>
            <tr>
              <td>2. 개인정보 처리위탁</td>
              <td>10. 링크</td>
            </tr>
            <tr>
              <td>3. 개인정보 제3자 제공</td>
              <td>11. 아동의 개인정보 보호</td>
            </tr>
            <tr>
              <td>4. 개인정보의 보유 및 이용기간</td>
              <td>12. 비회원의 개인정보 보호</td>
            </tr>
            <tr>
              <td>5. 개인정보 파기절차 및 파기방법</td>
              <td>13. 개인정보 보호책임자 및 민원서비스</td>
            </tr>
            <tr>
              <td>6. 스마트폰 앱 관리</td>
              <td>14. 개인위치정보의 처리</td>
            </tr>
            <tr>
              <td>
                7. 개인정보 자동 수집 장치의 설치, 운영 및 거부에 관한 사항
              </td>
              <td>15. 부칙</td>
            </tr>
            <tr>
              <td>8. 행태정보의 수집·이용 및 거부 등에 관한 사항</td>
              <td></td>
            </tr>
          </table>
          {`
 1. 개인정보의 수집 및 이용
 1) 구인구직 활동과 관련하여 적합한 서비스를 제공하기 위하여 개인정보를 수집하고 있으며, 수집된 정보를 아래와 같이 이용하고 있습니다. 이용자가 제공한 모든 정보는 하기 목적에 필요한 용도 이외로는 사용되지 않으며, 이용 목적이 변경될 시에는 사전동의를 구할 예정입니다. 또한, 회원이 회사가 요구하지 않은 개인정보를 사이트에 업로드한 경우, 회사는 해당 정보를 이용하지 않으며, 이에 대한 관리책임 또한 없습니다. 
`}
          <table>
            <tr>
              <td>구분</td>
              <td>목적</td>
              <td>항목</td>
              <td>보유기간</td>
            </tr>
            <tr>
              <td>개인회원 가입</td>
              <td rowSpan={2}>
                본인여부 확인, 서비스 개선 및 신규 서비스 개발을 위한 통계 활용,
                계약이행 및 약관변경 등의 고지를 위한 연락, 본인의사확인 및 민원
                등의 고객불만처리
              </td>
              <td>
                [필수] 이름, 주민번호, 휴대폰 번호, 이메일 주소 비밀번호 및
                확인, 주소 및 상세 주소
              </td>
              <td>회원 탈퇴 시 즉시 파기</td>
            </tr>
            <tr>
              <td>기업회원 가입</td>
              <td>
                [필수] 이름, 주민번호, 휴대폰 번호, 이메일 주소 비밀번호 및
                확인, 주소 및 상세 주소
              </td>
              <td></td>
            </tr>
            <tr>
              <td>광고성 정보 수신</td>
              <td>상품·서비스 영업, 홍보, 마케팅, 쿠폰 발송</td>
              <td>[선택] 전화번호, 이메일, 서비스 이용기록</td>
              <td></td>
            </tr>
            <tr>
              <td>
                프로필 수정 <br />
                (개인회원)
              </td>
              <td>구직자 정보 수정</td>
              <td>
                [필수] 이름, 생년월일, 성별, 핸드폰 번호, 이메일, 주소, 상세주소
                비밀번호 변경시 [필수] 기존 비밀번호, 새로운 비밀번호
              </td>
              <td></td>
            </tr>

            <tr>
              <td>이력서 등록</td>
              <td>구직자의 정보 확인 및 통계 확인</td>
              <td>
                [필수] 이름, 생년월일, 성별, 핸드폰 번호, 자기소개 이력 정보,
                내사진, 주소 및 상세주소, 계좌번호 [선택] 보건증 서류, 부모님
                동의서
              </td>
              <td></td>
            </tr>

            <tr>
              <td>채용정보 등록</td>
              <td>
                채용정보 등록자 확인 및 관리. 구직자 지원 및 문의. 등록 결과
                안내 및 고객불만처리. 각종 맞춤형 서비스 제공
              </td>
              <td>[필수] 담당자명, 이메일 주소, 핸드폰 번호</td>
              <td></td>
            </tr>

            <tr>
              <td>면접제의</td>
              <td>지원자에게 면접제의 내용 발송하기 위함</td>
              <td>[필수] 이메일, 핸드폰 번호</td>
              <td>발송일로부터 1년간 보관 후 파기</td>
            </tr>

            <tr>
              <td>결과통보</td>
              <td>지원자에게 결과 안내 발송하기 위함</td>
              <td>[필수] 이메일, 핸드폰 번호</td>
              <td>발송일로부터 1년간 보관 후 파기</td>
            </tr>

            <tr>
              <td>채용상품 신청 및 문의</td>
              <td>서비스 신청에 따른 문의사항 안내를 위함</td>
              <td>[필수] 이름, 핸드폰 번호, 이메일</td>
              <td>서비스 신청 후 1년까지 보관 후 파기</td>
            </tr>

            <tr>
              <td>기업인증</td>
              <td>관리자 심사시 의사 소통을 위한 정보</td>
              <td>[필수] 이름, 휴대폰 번호, 이메일</td>
              <td>서비스 신청 후 2년까지 보관 후 파기</td>
            </tr>

            <tr>
              <td>유료서비스 이용</td>
              <td>
                유료서비스 구매 및 이용 시 요금 정산 ※ 결제정보는 결제
                대행업체에서만 저장하며, 당사는 저장 및 관리하고 있지 않습니다.
              </td>
              <td>
                카드 결제 [필수] 카드사명, 카드번호 [선택] 이메일 주소 토스 페이
                [필수] 핸드폰번호, 생년월일 [선택] 이메일 주소
              </td>
              <td>
                전자상거래 등에서의 소비자보호 에 관한 법 률에 의거 하여 5년간
                보관 후 파기 (결제대행 업체에서 보관)
              </td>
            </tr>

            <tr>
              <td>이벤트</td>
              <td>이벤트 당첨 시 경품</td>
              <td>
                모바일상품: 이름, 휴대폰 번호 현물상품 : 이름, 주소, 핸드폰번호
              </td>
              <td>이벤트 종료 후 3개월 보관후 파기</td>
            </tr>

            <tr>
              <td rowSpan={4}>고객 문의 및 상담</td>
              <td>서비스 제안, 문의, 신고에 대한 답변</td>
              <td>[필수] 이름, 이메일 주소 [선택] 전화번호</td>
              <td>문의일로 부터 3년 보관 후 파기</td>
            </tr>
            <tr>
              <td>고객 접촉 내역 확인(전화통화)</td>
              <td>[필수] 이름, 전화번호</td>
              <td>
                회원 탈퇴 후 관련 분쟁대응을 위해 채권보관기간인 5년 보관 후
                파기
              </td>
            </tr>
            <tr>
              <td>상담내용의 기록 및 분쟁 해결</td>
              <td>[필수] 상담내용 녹취파일</td>
              <td>
                상담 종료일로부터 1년 후 백업 DB로 이관하여 5년 보관 후 파기
              </td>
            </tr>
            <tr>
              <td>유료서비스 환불</td>
              <td>[필수] 이름, 계좌번호, 핸드폰 번호, 이메일 주소</td>
              <td>환불 처리 완료일로부터 5년 보관 후 파기</td>
            </tr>
            <tr>
              <td>비밀번호 분실신고</td>
              <td>비밀번호 찾기 본인 확인</td>
              <td>[필수] 이메일 주소</td>
              <td>3년간 보관 후 파기</td>
            </tr>
            <tr>
              <td>회사명/사업자등록번호 변경 신청</td>
              <td>기업회원 ID 소유 확인 및 결과 통보</td>
              <td>[필수] 이름, 핸드폰 번호, 이메일 주소, 사업자 등록증</td>
              <td>신청 후 2년 경과 시 파기</td>
            </tr>
          </table>
          {`
2) 서비스 이용과정이나 사업처리 과정에서 아래와 같은 정보들이 자동으로 생성되어 수집될 수 있습니다.
IP Address, 쿠키, 방문 일시, 서비스 이용 기록, 불량 이용 기록, 광고식별자(ADID, IDFA, IDFV), 기기정보(OS버전, 모델명, UUID)
 
3) 회사는 다음과 같은 방법으로 개인정보를 수집할 수 있습니다.
홈페이지, 서비스 이용, 이벤트 응모, 팩스, 우편, 전화, 고객센터 문의
 
4) 회사는 개인정보를 수집함에 있어, 서비스 제공에 필요한 최소한의 개인정보를 ‘필수 동의 항목’으로, 그 외 개인정보는 ‘선택 동의 항목’으로 구분하여 이에 대해 개별적으로 동의할 수 있는 절차를 마련합니다. 회사는 이용자가 필요한 최소한의 개인정보 이외의 개인정보를 제공하지 아니한다는 이유로 그 서비스의 제공을 거부하지 않습니다.
 
 2. 개인정보 처리위탁
 
회사는 개인정보를 "1. 개인정보의 수집 및 이용"에서 고지한 범위 내에서 사용하며, 이용자의 사전 동의 없이 동 범위를 초과하여 이용하지 않습니다.
 
1) 회사는 서비스 향상을 위해서 아래와 같이 개인정보를 위탁하고 있으며, 관계 법령에 따라 위탁계약 시 개인정보가 안전하게 관리될 수 있도록 필요한 사항을 규정하고 있습니다. 변동사항 발생 시 공지사항 또는 개인정보 처리방침을 통해 고지하도록 하겠습니다.
 
국내 위탁 현황
`}
          <table>
            <tr>
              <td>수탁업체</td>
              <td>위탁 업무 내용</td>
              <td>개인정보 이용 기간</td>
            </tr>
            <tr>
              <td>비즈엠</td>
              <td>상품 구매에 필요한 신용카드, 계좌이체, 휴대폰 등의 결제</td>
              <td>
                전자상거래등에서의 소비자보호에 관한 법률(대금결제 및 재화 등의
                공급에 관한 기록 - 5년)
              </td>
            </tr>
            <tr>
              <td>토스페이먼츠</td>
              <td>상품 구매에 필요한 신용카드, 계좌이체, 휴대폰 등의 결제</td>
              <td>
                전자상거래등에서의 소비자보호에 관한 법률(대금결제 및 재화 등의
                공급에 관한 기록 - 5년)
              </td>
            </tr>
            <tr>
              <td>Amazon Web Service</td>
              <td>클라우드 IT인프라 관리</td>
              <td>위탁계약 종료 시 까지</td>
            </tr>
          </table>
          {`

2) 다음의 경우에는 합당한 절차를 통한 이용자의 동의를 얻어 개인 정보를 제공 또는 이용할 수 있습니다.
① 제휴관계 : 본 개인정보 처리방침 시행일 현재에는 개인회원의 개인정보를 제공하는 제휴관계가 없으며, 제휴를 통한 변동사항 발생 시 사전공지 합니다. 이 경우 개별적인 동의를 구하는 절차를 거치며, 동의가 없는 경우에는 제공하지 않습니다. 개인정보를 제공하지 않는 제휴관계는 각 회원별 약관을 따릅니다.
② 매각, 인수합병: 서비스 제공자의 권리, 의무가 승계 또는 이전되는 경우 이를 반드시 사전에 고지하며 이용자의 개인정보에 대한 동의 철회의 선택권을 부여합니다.
③ 다만, 아래의 경우에는 예외로 합니다.
관계법령에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우
 
3. 개인정보 제3자 제공
1) 회사는 원칙적으로 이용자의 사전 동의를 받아 이용자들의 개인정보를 외부에 제공합니다. 다만, 아래의 경우에는 예외로 합니다.
① 법령의 규정에 의거하거나, 수사, 조사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관 및 감독당국의 요구가 있는 경우
② 요금 정산을 위하여 필요한 경우
③ 이용자 또는 제3자의 급박한 생명, 신체, 재산의 이익을 위하여 필요함에도 불구하고 동의를 받을 수 없는 경우
④ 개인정보보호법 제17조 제4항, 동법 시행령 제14조의2 제1항에 따라, 회원이 동의한 개인정보의 수집 및 이용목적의 범위와 관련이 있으며, 회원이 제3자의 제공을 충분히 예측할 수 있고, 제3자 제공이 회원의 이익을 부당하지 침해하지 않으며, 제3자 제공시 기술적인 안전성을 충분히 확보한 경우(이력서열람서비스)
 
2) 회사는 원칙적으로 "1. 개인정보의 수집 및 이용"에서 고지한 범위 내에서 개인정보를 이용하거나 제3자에게 제공하며, 동 범위를 초과하여 이용하거나 제3자에게 제공하지 않습니다. 다만, 아래의 경우에는 예외로 합니다.
① 이용자들이 사전에 공개 또는 제3자 제공에 동의한 경우
② 법령의 규정에 의거하거나, 수사, 조사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관 및 감독당국의 요구가 있는 경우
 
3) 그 밖에 개인정보 제3자 제공이 필요한 경우에는 이용자의 동의를 얻는 등 적법한 절차를 통하여 제3자에게 개인정보를 제공할 수 있습니다. 회사는 이용자들의 거래 이행을 위하여 필요한 경우 이용자의 동의를 얻는 등 적법한 절차를 통하여 아래와 같이 개인정보를 제공할 수 있습니다.
          `}
          <table>
            <tr>
              <td>제공받는 자</td>
              <td>개인정보 이용목적</td>
              <td>제공하는 개인정보 항목</td>
              <td>보유 및 이용기간</td>
            </tr>
            <tr>
              <td>기업회원</td>
              <td>입사지원 및 채용 절차 진행, 채용 목적에 따른 인재 검색</td>
              <td>
                이름, 휴대폰 번호, 생년월일, 성별, 사진, 주소, 계좌번호,
                이력정보, 전하고 싶은 말 업장 후기
              </td>
              <td>입사지원 한 채용 공고 마감 후 90일</td>
            </tr>
          </table>
          {`

4) 이용자는 개인정보의 제3자 제공에 대하여 동의를 하지 않을 수 있고, 언제든지 제3자 제공 동의를 철회할 수 있습니다. 동의를 거부하시는 경우에도 회원가입 서비스는 이용하실 수 있으나, 제3자 제공에 기반한 관련 서비스의 이용/제공이 제한될 수 있습니다. 기타 개인정보 제3자 제공에 대한 변동사항은 공지 및 별도 통지를 할 예정입니다.
 
4. 개인정보의 보유 및 이용기간
사이트는 회원가입일로부터 서비스를 제공하는 기간 동안에 한하여 이용자의 개인정보를 보유 및 이용하게 됩니다. 회원탈퇴를 요청하거나 개인정보의 수집 및 이용에 대한 동의를 철회하는 경우, 수집 및 이용목적이 달성되거나 이용기간이 종료한 경우 개인정보를 지체 없이 파기합니다. 단, 다음의 경우에 대해서는 각각 명시한 이유와 기간 동안 보존합니다.
1) 상법 등 관계법령의 규정에 의하여 보존할 필요가 있는 경우 법령에서 규정한 보존기간 동안 거래내역과 최소한의 기본정보를 보유합니다. 이 경우 회사는 보관하는 정보를 그 보관의 목적으로만 이용합니다.
          `}
          <table>
            <tr>
              <td>관계법령</td>
              <td>목적</td>
              <td>수집항목</td>
              <td>보유기간</td>
            </tr>
            <tr>
              <td>통신비밀보호법</td>
              <td>법원의 영장을 받아 수사기관이 요청시 제공</td>
              <td>로그기록, IP 등</td>
              <td>3개월</td>
            </tr>
            <tr>
              <td rowSpan={3}>전자상거래등에서의 소비자보호에 관한 법률</td>
              <td>소비자의 불만 또는 분쟁처리에 관한 기록</td>
              <td>소비자 식별정보, 분쟁처리 기록 등</td>
              <td>3년</td>
            </tr>
            <tr>
              <td>대금결제 및 재화 등의 공급에 관한 기록</td>
              <td rowSpan={2}>소비자 식별정보, 계약/청약철회 기록 등</td>
              <td>5년</td>
            </tr>
            <tr>
              <td>계약 또는 청약철회 등에 관한 기록</td>
              <td></td>
            </tr>
            <tr>
              <td>부가가치세법</td>
              <td>장부, 세금계산서, 수입세금계산서, 영수증 등</td>
              <td>부가가치세의 과세 표준과 세액의 신고 자료 등</td>
              <td>5년</td>
            </tr>
            <tr>
              <td>전자금융거래법</td>
              <td>전자금융거래 기록 확인</td>
              <td>전자금융거래에 관한 기록, 상대방에 관한 정보 등</td>
              <td>5년</td>
            </tr>
            <tr>
              <td>특정 금융거래 정보의 보고 및 이용 등에 관한 법률</td>
              <td>고객확인 의무</td>
              <td>고객확인 정보 등</td>
              <td>5년</td>
            </tr>
          </table>
          {`

2) 회사 내부 방침에 의해 부정이용 등에 관한 기록은 5년간 보관합니다.
 
3) 문자(SMS/MMS/알림톡 등)에 대한 발송 기록은 통신사실 확인자료 활용 목적으로 발송 후 1년간 보관합니다.
 
4) 보유기간을 미리 공지하고 그 보유기간이 경과하지 아니한 경우와 개별적으로 동의를 받은 경우에는 약정한 기간 동안 보유합니다.
 
5) 아이디의 경우 중복가입확인 등의 목적으로 영구 보유합니다.
 
5. 개인정보 파기절차 및 파기방법
이용자의 개인정보는 원칙적으로 개인정보의 수집 및 이용목적이 달성되면 지체 없이 파기합니다. 회사의 개인정보 파기절차 및 방법은 다음과 같습니다.
 
1) 파기절차
① 이용자가 회원가입 등을 위해 입력한 정보는 목적이 달성된 후 별도의 DB로 옮겨져(종이의 경우 별도의 서류함) 내부 방침 및 기타 관련 법령에 의해 정보보호 사유에 따라 일정 기간 저장된 후 파기됩니다.
② 별도 DB로 옮겨진 개인정보는 법률에 의한 경우가 아니고서는 다른 목적으로 이용되지 않습니다.
③ 시스템의 원활한 유지를 위해 백업되는 데이터는 백업 후 15일마다 일괄 삭제됩니다.
 
2) 파기방법
① 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통해 파기합니다.
② 전자적 파일형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.
 
6. 스마트폰 앱 관리
스마트폰 앱을 통해 서비스 이용 시, 이용자에게 개인정보 수집·이용 동의를 받은 범위 내에서 단말기 정보 등에 접근한다는 것을 고지하고 승인을 획득한 후 정보가 수집되거나 전송됩니다. 앱 상에서 이용자가 단말기 접근 권한을 허용하였다고 하여 허용권한과 관련된 모든 정보가 즉시 수집되거나 전송되는 것은 아닙니다. 서비스 제공을 위하여 스마트폰 앱의 접근 권한을 필수 또는 선택적으로 이용자에게 요청하고 있으며, 단말기 내 "설정" 메뉴를 통하여 이용자가 직접 권한을 변경할 수 있습니다. 앱 권한에 대한 자세한 사항은 애플리케이션 스토어를 통해 확인하실 수 있습니다. 스마트폰 상에서 앱을 삭제하더라도 이용자의 회원계정은 유지되므로, 회원탈퇴를 원하실 경우 고객센터로 연락하여 주시기 바랍니다.
 
7. 개인정보 자동 수집 장치의 설치, 운영 및 거부에 관한 사항
 
1) 쿠키란
웹사이트를 운영하는데 이용되는 서버가 이용자의 브라우저에 보내는 작은 텍스트 파일로 이용자의 하드디스크에 저장됩니다.
 
2) 쿠키의 사용 목적
① 회사는 쿠키의 사용을 통해서만 가능한 특정된 맞춤형 서비스를 제공할 수 있습니다
② 회사는 회원을 식별하고 회원의 로그인 상태를 유지하기 위해 쿠키를 사용할 수 있습니다.
 
3) 쿠키 설치, 운영 및 거부
① 이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 웹브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다.
② 쿠키 설치 허용 여부를 지정하는 방법은 다음과 같습니다.
- Microsoft Edge : 설정 -> 쿠키및 사이트 권한 -> 쿠키 설정
- Safari : 환경설정 -> '크로스 사이트 추적 방지' 및 '모든 쿠키 차단'
- Chrome : 우측 상단 메뉴(점3개) -> 설정 -> 개인 정보 보호 및 보안 -> 쿠키 및 기타 사이트 데이터 -> 설정
③ 쿠키의 저장을 거부할 경우에는 로그인이 필요한 일부 서비스 이용에 어려움이 있을 수 있습니다.
 
8. 행태정보의 수집·이용 및 거부 등에 관한 사항
① 회사는 서비스 이용과정에서 정보주체에게 최적화된 맞춤형 서비스 및 혜택, 온라인 맞춤형 광고 등을 제공하기 위하여 행터정보를 수집·이용하고 있습니다.
② 회사는 다음과 같이 온라인 맞춤형 광고 사업자가 행태정보를 수집·처리하도록 허용하고 있습니다.
- 행태정보를 수집 및 처리하려는 광고 사업자 : Meta Platforms, Inc, 카카오, 크리테오, AppsFlyer, Google Analytics, 데이터스토리, 구글 태그매니저, 파이어베이스, 브레이즈
- 행태정보 수집 방법 : 픽셀(Meta Platforms, Inc,), SDK(Meta Platforms, Inc, 카카오, 파이어베이스, 브레이즈), 써드파티 앱트래킹툴 SDK(AppsFlyer), 웹스크립트(Google Analytics, 데이터스토리, 구글 태그매니저), 앱스크립트(데이터스토리) 를 이용하여 자동 수집 및 전송
- 수집·처리되는 행태정보 항목 : 이용자의 웹사이트/앱서비스 방문 이력, 검색 이력, 구매 이력, 기타 온라인상 활동 정보, 광고식별자
- 보유·이용기간 : 수집일로부터 최대 36개월간 보유 · 이용
③ 이용자는 당사의 행태정보 수집 · 이용을 차단할 수 있는 선택권을 다음과 같은 방법으로 행사할 수 있습니다.
(웹브라우저)
- Microsoft Edge : 설정 → 개인정보, 검색 및 서비스 → 추적방지 → InPrivate를 검색할 때 항상 "엄격" 추적 방지 사용 → 개인정보 → 추적 안함 요청 보내기
- Chrome : 설정 → 고급 → 개인정보 및 보안 → 콘텐츠 설정 → 쿠키 → 타사 쿠키 차단
 
(스마트폰)
- Android : 구글 설정 → 계정 → 구글 계정 선택 → 광고 선택 → 광고 맞춤설정 선택 해제(OS 버전 별 다소 상이할 수 있음)
- iOS : 아이폰 설정 → 개인정보보호 선택 → 광고선택 → 광고추적 제한 해제(OS 버전 별 다소 상이할 수 있음)
Google 애널리틱스 차단 브라우저 부가기능의 설치(https://tools.google.com/dlpage/gaoptout)를 통해
Google 애널리틱스 이용을 거부할 수 있습니다.
④ 행태정보 관련 문의 및 피해 구제 방법
행태정보와 관련된 문의 및 피해 구제 방법은 제14조에서 정한 바에 따릅니다.
 
9. 개인정보의 안전성 확보조치 기준
1) 개인정보 암호화
이용자의 개인정보는 비밀번호에 의해 보호되며, 파일 및 각종 데이터는 암호화하거나 파일 잠금 기능을 통해 별도의 보안기능을 통해 보호하고 있습니다.
 
2) 해킹 등에 대비한 대책
① 해킹이나 컴퓨터 바이러스 등에 의한 피해를 방지하기 위하여 백신 프로그램을 주기적으로 업데이트하며 새로운 바이러스가 출현할 경우 백신이 나오는 즉시 적용하여 개인정보가 훼손되지 않도록 방지하고 있습니다.
② 외부 침입에 대비하여 접근이 통제된 구역에 시스템을 설치하고, 침입 탐지 시스템 및 취약점 분석 시스템을 설치하여 24시간 감시하고 있습니다.
 
3) 개인정보 처리 직원의 교육
개인정보관련 처리 직원은 최소한의 인원으로 구성되며, 새로운 보안 기술 습득 및 개인정보보호 의무에 관해 정기적인 교육을 실시하며 내부 감사 절차를 통해 보안이 유지되도록 시행하고 있습니다.
 
4) 개인 아이디와 비밀번호 관리
회사는 이용자의 개인정보를 보호하기 위하여 최선의 노력을 다하고 있습니다. 단, 이용자의 개인적인 부주의로 ID, 비밀번호 등 개인정보가 유출되어 발생한 문제와 기본적인 인터넷의 위험성 때문에 일어나는 일들에 대해 책임을 지지 않습니다.
 
10. 링크
사이트는 다양한 배너와 링크를 포함하고 있습니다. 많은 경우 타 사이트의 페이지와 연결되어 있으며 이는 광고주와의 계약관계에 의하거나 제공받은 컨텐츠의 출처를 밝히기 위한 조치입니다. 사이트가 포함하고 있는 링크를 클릭하여 타 사이트의 페이지로 옮겨갈 경우 해당 사이트의 개인정보처리방침은 과 회사와 무관하므로 새로 방문한 사이트의 정책을 검토해 보시기 바랍니다.
 
11. 아동의 개인정보 보호
 
회사는 인터넷 리크루팅의 특성으로 볼 때 이력서를 등록하여 구직활동을 할 수 없다고 판단하여 만15세 미만 아동의 회원가입을 허용하지 않습니다.

`}
          <span onClick={() => setOpen(!open)}>청소년 보호정책</span>
          {open && (
            <>
              {`

우구구 (이하 "회사")는 유해정보로부터 청소년을 보호하고 안전한 인터넷 사용을 돕기 위해 아래와 같이 정보통신망 이용 촉진 및 정보보호 등에 관한 법률 및 청소년보호법에 근거하여 청소년 보호정책을 수립, 시행하고 있습니다. "회사"는 방송통신심의위원회의 정보통신에 관한 심의규정 및 청소년보호법상의 청소년유해매체물 심의기준, 청소년 고용금지 직종 기준 등에 따라 만 19세 미만의 청소년들이 유해정보에 접근할 수 없도록 방지하고 있으며 청소년 보호정책을 통하여 알바몬이 청소년 보호를 위해 어떠한 조치를 취하고 있는지 알려드립니다.
 
1. 청소년유해정보에 대한 청소년 접근제한 및 관리조치
 
"회사"는 청소년이 아무런 제한장치 없이 청소년 유해정보에 노출되지 않도록 청소년유해매체물에 대해서는 별도의 성인 인증장치를 마련, 적용하며 청소년 유해정보가 노출되지 않기 위한 예방차원의 조치를 강구합니다.
 
2. 청소년유해정보로부터의 청소년보호를 위한 업무 담당자 교육
 
"회사"는 청소년보호담당자 및 각 서비스 담당자들을 대상으로 청소년보호 관련 법령 및 제재기준, 유해정보 발견시 대처방법, 위반사항 처리에 대한 보고절차 등을 교육하고 있습니다.
 
3. 청소년유해정보로 인한 피해상담 및 고충처리
 
"회사"는 청소년 유해정보로 인한 피해상담 및 고충처리를 위한 전문인력을 배치하여 그 피해가 확산되지 않도록 하고 있습니다. 하단에 명시한 4. 청소년보호 관리책임자 및 담당자 항을 참고하여 전화나, e-메일 등을 통해 청소년유해정보로 인한 피해상담 및 고충처리를 요청할 수 있습니다.
 
4. 청소년보호 관리책임자 및 담당자
 
"회사"는 청소년유해정보의 차단 및 관리, 청소년유해정보로부터의 청소년보호정책을 수립하는 등 청소년보호업무를 수행하기 위하여 아래와 같이 청소년보호 관리책임자 및 청소년보호 관리담당자를 지정하고 있습니다.
 
`}
              <table>
                <tr>
                  <td>청소년보호 관리책임자</td>
                  <td>청소년보호 관리담당자</td>
                </tr>
                <tr>
                  <td>이 름 · 소속 · 직위</td>
                  <td>이 름 · 소속 · 직위</td>
                </tr>
                <tr>
                  <td>이지훈 기획팀 대리</td>
                  <td>추한성 영업팀 대리</td>
                </tr>
                <tr>
                  <td>e-메일 : thescv_agency@naver.com</td>
                  <td>e-메일 : thescv_agency@naver.com</td>
                </tr>
                <tr>
                  <td>전화 : 02-558-1195</td>
                  <td>전화 : 02-558-1195</td>
                </tr>
              </table>
            </>
          )}
          {`

12. 비회원의 개인정보 보호
사이트는 회원 가입을 하지 않아도 공개를 희망하지 않는 개인정보와 이력서 주요 정보를 제외한 대부분의 컨텐츠를 열람할 수 있습니다.
 
13. 개인정보 보호책임자 및 민원서비스
회사는 이용자의 개인정보를 보호하고 개인정보와 관련한 고충처리를 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.
  
`}
          <table>
            <tr>
              <td>개인정보 보호책임자</td>
              <td>개인정보 민원처리 담당부서</td>
            </tr>
            <tr>
              <td>이름 : 이지훈</td>
              <td>우구구 고객센터</td>
            </tr>
            <tr>
              <td>연락처 : 02-558-1195</td>
              <td>연락처 : 02-558-1195</td>
            </tr>
            <tr>
              <td>e-메일 : thescv_agency@naver.com</td>
              <td>e-메일 : thescv_agency@naver.com</td>
            </tr>
          </table>
          {`
기타 개인정보에 관한 상담이 필요한 경우에는 아래 기관에 문의하실 수 있습니다.
 
[개인정보침해신고센터] 국번없이 118 / http://privacy.kisa.or.kr
[대검찰청 사이버수사과] 국번없이 1301 / http://www.spo.go.kr
[경찰청 사이버수사국] 국번없이 182 / http://www.police.go.kr
 
14. 개인위치정보의 처리
개인위치정보 보유기간
· 회사는 위치기반서비스에서 개인위치정보를 일회성 또는 임시로 이용 후 지체없이 파기합니다.
 
개인위치정보 처리 목적
· 위치기반서비스를 제공하기 위해 개인위치정보를 처리하는 목적은 아래와 같습니다.
`}
          <table>
            <tr>
              <td>서비스명</td>
              <td>서비스 내용</td>
            </tr>
            <tr>
              <td>위치기반 서비스</td>
              <td>{`- 현위치를 이용한 국내지역 채용정보 검색
- 현위치를 활용하여 채용정보 검색결과 제시
- 현위치 내지 특정 위치를 지도 내에 표기하여 채용정보 제공
- 현위치 내지 특정 위치를 저장
- 저장한 위치를 기반으로 채용정보 검색결과 제시
- 현 위치를 이용한 게시물 검색결과 제시`}</td>
            </tr>
            <tr>
              <td>연락처 : 02-558-1195</td>
              <td>연락처 : 02-558-1195</td>
            </tr>
            <tr>
              <td>e-메일 : thescv_agency@naver.com</td>
              <td>e-메일 : thescv_agency@naver.com</td>
            </tr>
          </table>
          {`

개인위치정보 수집ㆍ이용ㆍ제공사실 확인자료의 보유근거 및 보유기간
· 회사는 타사업자 또는 이용 고객과의 요금정산 및 민원처리를 위해 위치정보 수집·이용·제공사실 확인자료를 자동 기록·보존하며, 해당 자료는 1년간 보관합니다.
 
개인위치정보의 파기 절차 및 방법
· 회사는 개인위치정보의 처리목적이 달성된 경우, 개인위치정보를 재생이 불가능한 방법으로 안전하게 파기하고 있습니다. 전자적 파일 형태는 복구 및 재생이 불가능한 기술적인 방법으로 파기하며, 출력물 등은 분쇄하거나 소각하는 방식으로 파기합니다.
 
개인위치정보의 제3자 제공 및 통보에 관한 사항
· 회사는 현재 개인위치정보를 제3자에게 제공하지 않고 있습니다.
· 회사는 개인위치정보를 회원이 지정하는 제3자에게 제공하는 경우에는 개인위치정보를 수집한 당해 통신 단말장치로 매회 회원에게 제공받는 자, 제공일시 및 제공목적을 즉시 통보합니다. 단, 아래 각 호의 1에 해당하는 경우에는 회원이 미리 특정하여 지정한 통신 단말장치 또는 전자우편주소로 통보합니다.
1. 개인위치정보를 수집한 당해 통신단말장치가 문자, 음성 또는 영상의 수신기능을 갖추지 아니한 경우
2. 회원이 온라인 게시 등의 방법으로 통보할 것을 미리 요청한 경우
 
8세 이하의 등의 보호의무자 권리·의무 및 그 행사방법
"회사"는 아래의 경우에 해당하는 자(이하 "8세 이하의 아동"등이라 한다)의 보호의무자가 8세 이하의 아동 등의 생명 또는 신체보호를 위하여 개인위치정보의 이용 또는 제공에 동의하는 경우에는 본인의 동의가 있는 것으로 본다.
 
1. 8세 이하의 아동
2. 금치산자
3. 장애인복지법제2조제2항제2호의 규정에 의한 정신적 장애를 가진 자로서 장애인고용촉진 및 직업재활법 제2조제2호의 규정에 의한 중증장애인에 해당하는 자(장애인복지법 제29조의 규정에 의하여 장애인등록을 한 자에 한한다)
8세 이하의 아동 등의 생명 또는 신체의 보호를 위하여 개인위치정보의 이용 또는 제공에 동의를 하고자 하는 보호의무자는 서면동의서에 보호의무자임을 증명하는 서면을 첨부하여 "회사"에 제출하여야 한다.
 
위치정보 관리책임자의 정보
          `}
          <table>
            <tr>
              <td>위치정보 관리책임자</td>
              <td>{`이름 : 이지훈
소속/직위 : 기획팀 대리
e-메일 : thescv_agency@naver.com
전화 : 02-558-1195
`}</td>
            </tr>
          </table>
          {`
15. 부칙
본 개인정보처리방침은 관계법령 및 지침의 변경 또는 회사의 필요 등에 의하여 내용의 추가, 삭제 및 수정이 생길 수 있습니다. 이 경우 최소 7일 전에 홈페이지 또는 이메일을 통해 사전 공지하고 사전 공지가 곤란한 경우 지체 없이 공지하며, 별도 공지가 없는 한 7일이 경과된 후에 시행됩니다. 다만, 회원의 권리/의무에 중대한 영향을 주는 변경의 경우에는 최소 30일 전에 공지하고, 별도 공지가 없는 한 30일이 경과된 후에 시행됩니다. 또한 당사는 관계법령에 따라 필요한 경우 고객의 별도 동의를 받을 수 있습니다.
`}
        </div>
      </div>
    </>
  );
};
