import React from 'react';
import NewMainTopNavbar from '../components/NewMainTopNavbar';
import Section1 from '../components/MainPage/Section1';
import Section2 from '../components/MainPage/Section2';
import Section3 from '../components/MainPage/Section3';
import Section4 from '../components/MainPage/Section4';
import Section5 from '../components/MainPage/Section5';
import Section6 from '../components/MainPage/Section6';
import Footer from '../components/MainPage/Footer';
import QnaSection from '../components/MainPage/QnaSection';

const MainPage = () => {
  return (
    <div className="bg-[#F4F5F8]">
      <NewMainTopNavbar />

      <div className="bg-gray-50 lg:space-y-24 space-y-10 pb-32 lg:pb-0">
        <Section1 />

        <Section2 />

        <Section3 />

        <Section4 />

        <Section5 />

        <Section6 />

        <QnaSection />

        <Footer />
      </div>
    </div>
  );
};

export default MainPage;
