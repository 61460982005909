import React from 'react';
import { Section2Item } from './Section2Item';
import { ReactComponent as Icon1SVG } from '../../assets/svg/section-2-icon1.svg';
import { ReactComponent as Icon2SVG } from '../../assets/svg/section-2-icon2.svg';
import { ReactComponent as Icon3SVG } from '../../assets/svg/section-2-icon3.svg';
import { ReactComponent as IconClick } from '../../assets/svg/icon-click.svg';
import SerchField from './SerchField';

const DATA = [
  {
    id: 1,
    icon: <Icon1SVG />,
    title: '매칭 서비스',
    content: `알바님들은 맞춤 알바 설정으로 원하는 알바만 쏙쏙 골라볼 수 있어요.`,
  },
  {
    id: 2,
    icon: <Icon2SVG />,
    title: '공고 알림 서비스',
    content: `사장님들은 공고 등록 내용으로 알바님들에게 알림을 보낼 수 있어요.`,
  },
  {
    id: 3,
    icon: <Icon3SVG />,
    title: '번쩍 공고 등록',
    content: `갑자기 알바님이 필요한 경우에는 번쩍 공고 등록도 가능해요.`,
  },
];

const Section2 = () => {
  return (
    <div className="max-w-screen-lg mx-auto w-full px-5 space-y-24 hidden lg:block">
      <div className="space-y-16">
        <p className="text-4xl font-bold text-center">
          사장님, 알바님 모두 <span className="text-brand-1">우구구</span>와
          함께!
        </p>

        <div className="grid grid-cols-3 gap-10">
          {DATA.map((se) => (
            <Section2Item
              key={se.id}
              icon={se.icon}
              title={se.title}
              content={se.content}
            />
          ))}
        </div>
      </div>

      <div className="bg-gray-100 rounded-xl p-5 flex items-center justify-between">
        <div className="flex items-center space-x-4">
          <IconClick />
          <p className="text-lg">일하고 싶은 곳을 검색해보세요.</p>
        </div>

        <div className="w-96">
          <SerchField />
        </div>
      </div>
    </div>
  );
};

export default Section2;
