import React from 'react';
import NewMainTopNavbar from '../../components/NewMainTopNavbar';

export const LocationServiceAgreePage = () => {
  return (
    <>
      <NewMainTopNavbar />

      <div className="w-full h-full flex justify-center">
        <div className="max-w-7xl w-full mt-4 md:mt-8 whitespace-pre-line px-4">
          <h1 className="text-2xl md:text-3xl font-bold">
            위치기반 서비스 이용약관
          </h1>
          {`
제 1 조 (목적)
이 약관은 더에스씨브이 (이하 “회사”)가 제공하는 위치기반서비스와 관련하여 회사와 개인위치정보주체와의 권리, 의무 및 책임사항, 기타 필요한 사항을 규정함을 목적으로 한다.

제 2 조 (약관 외 준칙)
이 약관에 명시되지 않은 사항은 위치정보의 보호 및 이용 등에 관한 법률, 개인정보보호법, 정보통신망 이용촉진 및 정보보호 등에 관한 법률, 전기통신기본법, 전기통신사업법 등 관계법령과 회사의 이용약관 및 개인정보처리방침, 회사가 별도로 정한 지침 등에 의한다.

제 3 조 (서비스 내용 및 요금)
위치 정보를 활용한 채용 정보 검색, 채용 정보 검색결과 제시, 위치를 지도 내에 표기하여 채용 정보 제공과 같은 서비스를 제공한다.
제공하는 서비스는 기본적으로 무료이며, 별도의 유로 서비스의 경우 해당 서비스에 명시된 요금을 지불하여야 사용이 가능하다.
유료서비스 이용을 통하여 결제된 대금에 대한 취소 및 환불은 결제 이용약관 등 관계법에 따른다.
회원의 개인정보도용 및 결제사기로 인한 환불요청 또는 결제자의 개인정보 요구는 법률이 정한 경우 외에는 거절될 수 있다.
무선 서비스 이용 시 발생하는 데이터 통신료는 별도이며 가입한 각 이동통신사의 정책에 따른다.
MMS 등으로 게시물을 등록할 경우 발생하는 요금은 이동통신사의 정책에 따른다.

제 4 조 (서비스 내용 변경 통지)
서비스 내용을 변경하거나 종료하는 경우 회원의 등록된 전자우편 주소로 이메일을 통하여 서비스 내용의 변경 사항 또는 종료를 통지하여야한다. 항의 경우 불특정 다수인을 대상으로 통지를 함에 있어서는 웹사이트 등 기타 "회사"의 공지사항을 통하여 회원들에게 통지할 수 있다.

제 5 조 (법정대리인의 권리)
회사는 만14세 미만 아동으로부터 개인위치정보를 수집ㆍ이용 또는 제공하고자 하는 경우에는 만14세 미만 아동과 그 법정대리인의 동의를 받아야 한다.
법정대리인은 만14세 미만 아동의 개인위치정보를 수집ㆍ이용ㆍ제공에 동의하는 경우 동의유보권, 동의철회권 및 일시중지권, 열람ㆍ고지요구권을
행사할 수 있다.

제 6 조 (개인위치정보 이용 또는 제공)
회사는 서비스 제공을 위하여 회원의 위치정보를 이용할 수 있으며, 회원은 본 약관에 동의함으로써 이에 동의한 것으로 본다.
타사업자 또는 이용 고객과의 요금정산 및 민원처리를 위해 위치정보 이용·제공사실 확인자료를 자동 기록·보존하며, 해당 자료는 1년간 보관한다.
개인위치정보를 회원이 지정하는 제3자에게 제공하는 경우에는 개인위치정보를 수집한 당해 통신 단말장치로 매회 회원에게 제공받는 자, 제공일시 및 제공목적을 즉시 통보한다. 단, 아래 각 호의 1에 해당하는 경우에는 회원이 미리 특정하여 지정한 통신 단말장치 또는 전자우편주소로 통보한다.
1. 개인위치정보를 수집한 당해 통신단말장치가 문자, 음성 또는 영상의 수신기능을 갖추지 아니한 경우
2. 회원이 온라인 게시 등의 방법으로 통보할 것을 미리 요청한 경우
회원의 개인위치정보 또는 위치정보 이용·제공사실 확인자료를 이용약관에 명시 또는 고지한 범위를 넘어 이용하거나 제3자에게 제공하고자 하는 경우에는 회원의 동의를 받아야 한다. 단, 아래의 경우는 제외한다.
1. 위치정보 및 위치기반서비스 제공에 따른 요금정산을 위하여 위치정보 이용·제공사실 확인자료가 필요한 경우
2. 통계작성, 학술연구 또는 시장조사를 위하여 특정 개인을 알아볼 수 없는 형태로 가공하여 제공하는 경우

제 7 조 (8세 이하의 아동 등의 보호의무자의 권리)
회사는 아래의 경우에 해당하는 자(이하 "8세 이하의 아동"등이라 한다)의 보호의무자가 8세 이하의 아동 등의 생명 또는 신체보호를 위하여 개인위치정보의 이용 또는 제공에 동의하는 경우에는 본인의 동의가 있는 것으로 본다.
1. 8세 이하의 아동
2. 금치산자
3. 장애인복지법제2조제2항제2호의 규정에 의한 정신적 장애를 가진 자로서 장애인고용촉진 및 직업재활법 제2조제2호의 규정에 의한 중증장애인에 해당하는 자(장애인복지법 제29조의 규정에 의하여 장애인등록을 한 자에 한한다)
8세 이하의 아동 등의 생명 또는 신체의 보호를 위하여 개인위치정보의 이용 또는 제공에 동의를 하고자 하는 보호의무자는 서면동의서에 보호의무자임을 증명하는 서면을 첨부하여  제출하여야 한다.
약관 제 6 조 (개인위치정보 이용 또는 제공) 의 규정은 제2항의 규정에 의하여 보호의무자가 동의를 하는 경우에 이를 준용한다. 이 경우 "회원"은 "보호의무자"로 본다.


제 8 조 (개인위치정보의 보유목적 및 이용기간)
회사는 본 약관 제4조에 따른 위치기반서비스를 제공하기 위해 필요한 최소한의 기간 동안 개인위치정보를 보유 및 이용합니다.
회사는 모든 위치기반서비스에서 개인위치정보를 일회성 또는 임시로 이용 후 지체없이 파기합니다.

제 9 조 (손해배상)
개인위치정보주체는 회사의 위치정보의 보호 및 이용 등에 관한 법률 제15조 내지 26조의 규정을 위반한 행위로 손해를 입은 경우에 회사에 대하여 손해배상을 청구할 수 있습니다. 이 경우 회사는 고의 또는 과실이 없음을 입증하지 아니하면 책임을 면할 수 없습니다.

제 10 조 (분쟁의 조정 및 기타)
회사는 위치정보와 관련된 분쟁에 대해 당사자간 협의가 이루어지지 아니하거나 협의를 할 수 없는 경우에는 방송통신위원회에 재정을 신청할 수 있다.
회사 는 고객은 위치정보와 관련된 분쟁에 대해 당사자간 협의가 이루어지지 아니하거나 협의를 할 수 없는 경우에는 개인정보분쟁조정위원회에 조정을 신청할 수 있다.

제 11 조 (사업자 정보 및 위치정보 관리책임자)
회사 상호, 주소 및 연락처는 다음과 같습니다.

주식회사 더에스씨브이
주소 : 서울시 강남구 봉은사로 24길 17, 1층
연락처 : 010-8728-7361
메일 : thescv_agency@naver.com`}
        </div>
      </div>
    </>
  );
};
