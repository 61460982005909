import * as yup from 'yup';
import { AlbaType } from '../types/job';

export const JobSchema = yup.object().shape({
  albaType: yup.mixed<AlbaType>().required('알바 종류를 선택해주세요.'),
  companyId: yup
    .number()
    .typeError('회사를 선택해주세요.')
    .required('회사를 선택해주세요.'),
  title: yup.string().required('공고 제목을 입력해주세요.'),
  summery: yup.string().required('추가 내용을 입력해주세요.'),
  industry: yup
    .array()
    .required('업종을 선택해주세요.')
    .min(1, '업종을 선택해주세요.'),
  jobType: yup.number().when('albaType', {
    is: AlbaType.NORMAL,
    then: yup.number().required('근무 형태를 선택해주세요.'),
    otherwise: yup.number().nullable(),
  }),
  deadLine: yup.string().required('지원 마감일을 선택해주세요.'),
  startWorkingDay: yup.string().required('근무 시작일을 선택해주세요.'),
  endWorkingDay: yup.string().required('근무 종료일을 선택해주세요.'),
  startWorkTime: yup
    .number()
    .typeError('근무 시작 시간을 선택해주세요.')
    .required('근무 시작 시간을 선택해주세요.'),
  startWorkMinute: yup
    .number()
    .typeError('근무 시작 분을 선택해주세요.')
    .required('근무 시작 분을 선택해주세요.'),
  endWorkTime: yup
    .number()
    .typeError('근무 종료 시간을 선택해주세요.')
    .required('근무 종료 시간을 선택해주세요.'),
  endWorkMinute: yup
    .number()
    .typeError('근무 종료 분을 선택해주세요.')
    .required('근무 종료 분을 선택해주세요.'),
  workDay: yup.array().when('albaType', {
    is: AlbaType.NORMAL,
    then: yup
      .array()
      .required('근무 요일을 선택해주세요.')
      .min(1, '근무 요일을 선택해주세요.'),
    otherwise: yup.array().nullable(),
  }),
  taxType: yup.number().required('세금을 선택해주세요.'),
  payType: yup.number().required('급여 형태를 선택해주세요.'),
  wage: yup
    .string()
    .required('급여를 입력해주세요.')
    .matches(/^[\d,]+$/, '올바른 숫자 형식이 아닙니다.'),
  provision: yup.string().when('albaType', {
    is: AlbaType.NORMAL,
    then: yup.string().required('지급 예정일을 입력해주세요.'),
    otherwise: yup.string().nullable(),
  }),

  opening: yup.string().required('인원을 입력해주세요.'),
  education: yup.string(),
  gender: yup.string().required('성별을 입력해주세요.'),
  qualification: yup.string(),
  age: yup.string().required('연령을 입력해주세요.'),
  advantage: yup.string(),
  videoUrl: yup.string(),
});
