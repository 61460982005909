import { List, LoadingScreen, useQuery } from '@entropyparadox/reusable-react';
import React from 'react';
import { likesByUserId, LikesData } from '../api';
import { JobListItem } from '../components';
import NewMainTopNavbar from '../components/NewMainTopNavbar';

const LikeList = () => {
  const { loading, data } = useQuery<LikesData>(likesByUserId);

  if (loading) return <LoadingScreen />;

  const likes = data?.likesByUserId.slice().sort((a, b) => {
    if (a.job.deadLine < b.job.deadLine) {
      return 1;
    }
    if (a.job.deadLine > b.job.deadLine) {
      return -1;
    }
    return 0;
  });

  return (
    <div className="h-full">
      <NewMainTopNavbar />
      <div className="hidden md:block">
        <div className="text-2xl mt-8 text-center text-gray-800 font-bold">
          관심 공고
        </div>
      </div>
      <div className="md:max-w-md md:mx-auto md:mt-8 w-full">
        <List>
          {likes?.map((job) => (
            <JobListItem key={job.id} job={job.job} />
          ))}
          {data?.likesByUserId.length === 0 ? (
            <div className="flex justify-center items-center h-full text-gray-500 mt-32">
              찜한 알바가 없습니다.
            </div>
          ) : (
            ''
          )}
        </List>
      </div>
    </div>
  );
};

export default LikeList;
