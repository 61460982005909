import React, { FC, ReactElement } from 'react';

interface Section2ItemProps {
  icon: ReactElement;
  title: string;
  content: string;
}

export const Section2Item: FC<Section2ItemProps> = ({
  icon,
  title,
  content,
}) => {
  return (
    <div className="flex items-center space-x-4">
      <div className="flex-shrink-0">{icon}</div>

      <div className="space-y-1">
        <p className="text-2xl font-semibold">{title}</p>
        <p className="text-sm text-gray-500 whitespace-pre-line">{content}</p>
      </div>
    </div>
  );
};
