import {
  AspectRatioImage,
  Button,
  Checkbox,
  Label,
  PhoneNumberField,
  Section,
  Select,
  TextField,
  useMutation,
} from '@entropyparadox/reusable-react';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { CreatePhone, UpDatePhoneVerify, updateUser } from '../../api';
import { User } from '../../types/user';
import { ReactComponent as FileUploadImg } from '../../assets/svg/file-upload.svg';

interface CompanyProfilePageProps {
  me: User;
}

const CompanyProfile: React.FC<CompanyProfilePageProps> = ({ me }) => {
  const thisMonth =
    me.birthday.substr(4, 1) === '0'
      ? me.birthday.substr(4, 2).split('0')[1]
      : me.birthday.substr(4, 2);

  const thisDay =
    me.birthday.substr(6, 1) === '0'
      ? me.birthday.substr(6, 2).split('0')[1]
      : me.birthday.substr(6, 2);
  const history = useHistory();
  const [phone, setPhone] = useState(me?.phone);
  const [name, setName] = useState(me?.name);
  const [birthday] = useState(me?.birthday);
  const [code, setCode] = useState('');
  const [authenticationNumber, setAuthenticationNumber] = useState(false);
  const [authenticationComplet, setAuthenticationComplet] = useState(false);
  const [year, setYear] = useState(birthday.substr(0, 4));
  const [month, setMonth] = useState(thisMonth);
  const [day, setDay] = useState(thisDay);
  const [entranceUrl, setEntranceUrl] = useState<File | null | undefined>(null);
  const [agree, setAgree] = useState(me?.agree);

  const [createPhone] = useMutation(CreatePhone, {
    variables: { phone: Number(phone) },
    onCompleted: ({ createPhone }) => {
      window.localStorage.setItem('phoneId', createPhone.id);
      setAuthenticationNumber(true);
      alert('인증번호가 발송되었습니다.');
    },
    onError: (err) => {
      console.log(err);
    },
  });

  const phnId = window.localStorage.getItem('phoneId');
  const m = month?.length === 1 ? `0${month}` : month;
  const d = day?.length === 1 ? `0${day}` : day;

  const [upDatePhoneVerify] = useMutation(UpDatePhoneVerify, {
    variables: { id: Number(phnId), code: code },
    onCompleted: () => {
      setAuthenticationComplet(true);
      window.localStorage.removeItem('phoneId');
    },
    onError: (error) => {
      alert(error);
    },
  });

  const [UPDATEUSER] = useMutation(updateUser, {
    variables: {
      phone: phone,
      name: name,
      birthday: year + m + d,
      entranceUrl: entranceUrl,
      agree: agree,
    },
    onCompleted: () => {
      window.location.reload();
    },
  });

  const handleSubmit = () => {
    UPDATEUSER();
    alert('수정이 완료 되었습니다.');
  };

  const maxOffset = 70;
  const thisYear = new Date().getFullYear();
  const allYears = [];
  for (let x = 0; x <= maxOffset; x++) {
    allYears.push(thisYear - x);
  }

  const yearList = allYears.map((y) => {
    return (
      <>
        <option key={y} value={y}>
          {y}년
        </option>
      </>
    );
  });

  const monthList = Array.from({ length: 12 }, (_, i) => i + 1).map((m) => {
    return (
      <>
        <option key={m} value={m}>
          {m}월
        </option>
      </>
    );
  });

  const days = new Date(Number(year), Number(month), 0).getDate();
  const dayList = Array.from({ length: days }, (_, i) => i + 1).map((d) => {
    return (
      <>
        <option key={d} value={d}>
          {d}일
        </option>
      </>
    );
  });

  return (
    <>
      <div className="w-full p-4 bg-white">
        <div className="text-xl truncate text-gray-800 font-bold my-1">
          내 프로필
        </div>
      </div>
      <Section>
        <TextField
          label="이름"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />

        <div>
          <Label text="*생년월일" />
          <div className="flex space-x-3.5">
            <div className="w-full">
              <Select
                placeholder="년"
                value={year}
                onChange={(e) => setYear(e.target.value)}
              >
                <option value="" selected disabled hidden>
                  년
                </option>
                {yearList}
              </Select>
            </div>
            <div className="w-full">
              <Select
                placeholder="월"
                value={month}
                onChange={(e) => setMonth(e.target.value)}
              >
                <option value="" selected disabled hidden>
                  월
                </option>
                {monthList}
              </Select>
            </div>
            <div className="w-full">
              <Select
                placeholder="일"
                value={day}
                onChange={(e) => setDay(e.target.value)}
              >
                <option value="" selected disabled hidden>
                  일
                </option>
                {dayList}
              </Select>
            </div>
          </div>
        </div>

        <PhoneNumberField
          label="*핸드폰 번호"
          value={phone || me?.phone}
          onChange={(e) => setPhone(e.target.value)}
          disabled={authenticationComplet}
        />
        {authenticationNumber ? (
          <>
            {!authenticationComplet ? (
              <>
                <Button
                  variant="outlined"
                  onClick={() =>
                    phone ? createPhone() : alert('핸드폰 번호를 입력해주세요.')
                  }
                >
                  인증번호 재전송
                </Button>
                <div className="mt-4">
                  <div className="grid grid-cols-4 gap-4 mt-1">
                    <input
                      type="text"
                      name=""
                      className="col-span-3 block w-full h-12 border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:to-brand-1 sm:text-sm"
                      placeholder="인증번호를 입력해주세요."
                      value={code}
                      onChange={(e) => setCode(e.target.value)}
                    />
                    <button
                      className="col-span-1 bg-brand-1 rounded-md text-white"
                      onClick={() =>
                        code
                          ? upDatePhoneVerify()
                          : alert('인증번호를 입력해주세요.')
                      }
                    >
                      확인
                    </button>
                  </div>
                </div>
              </>
            ) : (
              <>
                {authenticationComplet && <div>* 인증이 완료되었습니다.</div>}
              </>
            )}
          </>
        ) : (
          <Button
            variant="outlined"
            onClick={() =>
              phone ? createPhone() : alert('핸드폰 번호를 입력해주세요.')
            }
          >
            인증번호 보내기
          </Button>
        )}
        <label htmlFor="entranceUrl">
          <div className="text-gray-800 text-sm mb-2">매장 입구 사진</div>
          <div
            className="w-full border-2 border-dashed border-grey-5"
            style={{
              borderColor: !entranceUrl && !me.entranceUrl ? 'red' : '',
            }}
          >
            {entranceUrl ? (
              <>
                <AspectRatioImage
                  src={URL.createObjectURL(entranceUrl)}
                  alt=""
                  pb="pb-2/3"
                />
              </>
            ) : me.entranceUrl ? (
              <>
                <AspectRatioImage src={me.entranceUrl} alt="" pb="pb-2/3" />
              </>
            ) : !entranceUrl ? (
              <div className="w-full border-2 py-10 border-dashed border-grey-5 flex flex-col justify-center items-center space-y-1">
                <FileUploadImg />
                <div className="text-brand-1 text-xs w-3/5 text-center">
                  매장 입구 사진 첨부하기
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
        </label>
        <input
          type="file"
          id="entranceUrl"
          className="hidden"
          accept="image/*"
          onChange={(e) => {
            e.target.validity.valid && setEntranceUrl(e.target.files?.item(0));
          }}
        />

        <TextField label="*이메일" value={me.email} disabled />
        <div className="flex items-center space-x-2">
          <Checkbox
            id="agree"
            onChange={() => setAgree(!agree)}
            checked={agree}
          />
          <Label htmlFor="agree" margin="m-0">
            <span className="text-sm">마케팅 수신동의 (선택)</span>
          </Label>
        </div>

        <Button
          disabled={
            (phone !== me.phone && !authenticationComplet) ||
            !phone ||
            !name ||
            !year ||
            !month ||
            !day
          }
          onClick={() => handleSubmit()}
        >
          수정 완료
        </Button>
      </Section>
      <div className="h-4 bg-gray-100 my-3 mx-5" />
      <div className="mx-5">
        <div
          className="w-full py-5 border-b cursor-pointer"
          onClick={() => history.push('/change-password')}
        >
          비밀번호 변경
        </div>
        <div
          className="border-b w-full py-5 cursor-pointer"
          onClick={() => history.push('/delete-account')}
        >
          회원 탈퇴
        </div>
      </div>
    </>
  );
};

export default CompanyProfile;
