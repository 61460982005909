import React, { useEffect, useMemo, useRef, useState } from 'react';
import { ReactComponent as SearchIcon } from '../assets/svg/search.svg';
import { Job } from '../types/job';
import { ReactComponent as NavigationIcon } from '../assets/svg/crosshairs.svg';
import useSupercluster from 'use-supercluster';
import Marker from './Marker';
import { Tag, TagFilterType } from '../types/tag';
import ReactDOMServer from 'react-dom/server';
import { useHistory } from 'react-router-dom';
import CurrentLocationMarker from './CurrentLocationMarker';
import ClusterMarker from './ClusterMarker';
import { MapBounds } from '../types/Mapdata';
import { debounce } from 'lodash';

type MapProps = {
  tags?: Tag[];
  places?: Job[];
  searchTitle: string;
  setSearchTitle: React.Dispatch<React.SetStateAction<string>>;
  selectedTags?: TagFilterType;
  setSelectedTags?: React.Dispatch<React.SetStateAction<TagFilterType>>;
  bounds?: MapBounds;
  setBounds?: React.Dispatch<React.SetStateAction<MapBounds | undefined>>;
  map: any;
  setMap: React.Dispatch<React.SetStateAction<any>>;
  isPlaceLoading: boolean;
};

export const NaverMap: React.FC<MapProps> = ({
  tags,
  places,
  searchTitle,
  setSearchTitle,
  selectedTags = {},
  setSelectedTags = () => {},
  bounds,
  setBounds = () => {},
  map,
  setMap,
  isPlaceLoading,
}) => {
  const history = useHistory();
  const input = useRef<HTMLInputElement>(null);
  const [industries, setIndustries] = useState<Tag[]>([]);
  const [zoom, setZoom] = useState(10);
  const markerListRef = useRef<Array<naver.maps.Marker>>([]);
  const currentLocationMarkerRef = useRef<naver.maps.Marker>();
  const industryTags = selectedTags.industry || [];
  const points = places?.map((place) => ({
    type: 'Feature',
    properties: { ...place },
    geometry: {
      type: 'Point',
      coordinates: [parseFloat(place.lng), parseFloat(place.lat)],
    },
  }));

  const { clusters, supercluster } = useSupercluster({
    points: points || [],
    bounds,
    zoom,
    options: {
      radius: 75,
      maxZoom: 20,
      initial: function () {
        return { isUrgent: true };
      },
      map: (props: any) => ({ isUrgent: props.albaType === 'URGENTLY' }),
      reduce: (accumulated: any, props: any) => {
        accumulated.isUrgent = accumulated.isUrgent || props.isUrgent;
      },
    },
  });

  // 지도에서 직종 필터 선택시 일괄 업데이트
  const onClickIndustryFilterTags = (
    selectedTagId: number,
    industryTags: Array<number>,
  ) => {
    let newSelectedTags;
    if (industryTags?.includes(selectedTagId)) {
      newSelectedTags = {
        ...selectedTags,
        industry: industryTags.filter((tagId) => tagId !== selectedTagId),
      };
    } else {
      newSelectedTags = {
        ...selectedTags,
        industry: [...industryTags, selectedTagId],
      };
    }
    setSelectedTags(newSelectedTags);
    window.localStorage.setItem(
      'selectedTags',
      JSON.stringify(newSelectedTags),
    );
  };

  // naver map functions
  const searchAddressToCoordinate = (address: string) => {
    naver.maps.Service.geocode(
      {
        query: address,
      },
      function (status: any, response: any) {
        if (status === naver.maps.Service.Status.ERROR) {
          console.error('Something Wrong while searchAddressToCoordinate');
          return;
        }

        if (response.v2.meta.totalCount === 0) {
          alert('해당 주소에 대한 검색 결과가 존재하지 않습니다.');
          return;
        }
        const item = response.v2.addresses[0],
          point = new naver.maps.Point(item.x, item.y);

        map.setCenter(point);
      },
    );
  };

  const moveToCurrentLocation = () => {
    if (!navigator.geolocation) {
      console.log('Geolocation is not supported by your browser');
      return Error();
    }

    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        const coords = new naver.maps.LatLng(latitude, longitude);
        map.setCenter(coords);

        // 현재 위치 마커가 없을때 마커 추가
        if (!currentLocationMarkerRef.current) {
          const currentLocationMarker = new naver.maps.Marker({
            title: 'currentLocation',
            position: new naver.maps.LatLng(latitude, longitude),
            map,
            icon: {
              content: ReactDOMServer.renderToString(<CurrentLocationMarker />),
            },
          });
          currentLocationMarkerRef.current = currentLocationMarker;
        }
      },
      (e) => console.log(e),
    );
    return true;
  };

  const removeAllMarkers = () => {
    markerListRef.current.map((marker) => marker.setMap(null));
    markerListRef.current = [];
  };

  // naver map init
  useEffect(() => {
    if (!naver) return;
    if (!naver.maps) return;

    const mapZoom = Number(localStorage.getItem('mapZoom')) || 15;
    const mapBounds = JSON.parse(localStorage.getItem('mapBounds') || '[]');
    const [swlng = 0, swlat = 0, nelng = 0, nelat = 0] = mapBounds;

    const mapCenterLat = (swlat + nelat) / 2 || 37.3595704;
    const mapCenterLng = (swlng + nelng) / 2 || 127.105399;

    const map = new naver.maps.Map('map', {
      center: new naver.maps.LatLng(mapCenterLat, mapCenterLng),
      zoom: mapZoom,
    });

    setMap(map);
    setBounds([swlng, swlat, nelng, nelat]);

    // 경계가 바뀌면 mapBounds 저장 내용 변경
    naver.maps.Event.addListener(map, 'bounds_changed', function (bounds: any) {
      window.setTimeout(function () {
        const swlng = bounds._sw.lng(),
          swlat = bounds._sw.lat(),
          nelng = bounds._ne.lng(),
          nelat = bounds._ne.lat();
        setBounds([swlng, swlat, nelng, nelat]);
        localStorage.setItem(
          'mapBounds',
          JSON.stringify([swlng, swlat, nelng, nelat]),
        );
      }, 500);
    });

    // 확대가 바뀌면 mapZoom 저장 내용 변경
    naver.maps.Event.addListener(map, 'zoom_changed', function (zoom: any) {
      window.setTimeout(function () {
        setZoom(zoom);
        localStorage.setItem('mapZoom', String(zoom));
      }, 500);
    });
  }, []);

  useEffect(() => {
    if (!map) return;
    moveToCurrentLocation();
  }, [map]);

  // cluster to marker
  const generateMarkerFromCluster = (clusters: any[]) => {
    if (!clusters.length) {
      removeAllMarkers();
      return;
    }
    const changedClusters = clusters.filter((cluster) => {
      const [lng, lat] = cluster.geometry.coordinates;
      return !hasSamePositionMarker(lat, lng, markerListRef.current);
    });

    if (!changedClusters.length) return;

    // 기존 마커 삭제
    removeAllMarkers();

    const updateMarkers = clusters.map((cluster) => {
      const place = cluster.properties;
      const [lng, lat] = cluster.geometry.coordinates;
      const count = cluster.properties.point_count ?? 1;

      // 클러스터 마커 추가
      if (count > 1) {
        const marker = new naver.maps.Marker({
          position: new naver.maps.LatLng(lat, lng),
          title: 'cluster',
          map,
          icon: {
            content: ReactDOMServer.renderToString(
              <ClusterMarker
                count={count}
                total={supercluster.points.length}
                place={place}
              />,
            ),
          },
        });
        naver.maps.Event.addListener(marker, 'click', function () {
          map.morph(new naver.maps.LatLng(lat, lng), Math.min(zoom + 3, 20));
        });
        return marker;
      }

      // 일반 마커 추가
      const marker = new naver.maps.Marker({
        position: new naver.maps.LatLng(lat, lng),
        title: 'pin',
        map,
        icon: {
          content: ReactDOMServer.renderToString(
            <Marker place={place} onClickPoint={() => {}} />,
          ),
        },
      });
      naver.maps.Event.addListener(marker, 'click', function () {
        map.panTo(new naver.maps.LatLng(lat, lng));
        setTimeout(() => {
          history.push(`/map/jobs/${place.id}`);
        }, 500);
      });
      return marker;
    });

    const mapBounds = map.getBounds();
    let marker, position;

    // 실제 지도에 보이는 마커만 표시
    for (let i = 0; i < updateMarkers.length; i++) {
      marker = updateMarkers[i];
      position = marker.getPosition();
      if (mapBounds.hasLatLng(position)) {
        markerListRef.current.push(marker);
        marker.setMap(map);
      } else {
        if (!marker.getMap()) return;
        // markerListRef.current.splice(markerListRef.current.indexOf(marker));
        marker.setMap(null);
      }
    }
  };

  const debounceOnChangeCluster = debounce((clusters: any[]) => {
    generateMarkerFromCluster(clusters);
  }, 500);

  useEffect(() => {
    if (isPlaceLoading) return;
    debounceOnChangeCluster(clusters);
  }, [clusters, isPlaceLoading]);

  useEffect(() => {
    if (!industries.length) {
      setIndustries(tags?.filter((tag) => tag.status === 'INDUSTRY') || []);
    }
  }, [tags]);

  return (
    <div className="w-full relative h-full">
      <div className="flex w-full justify-between items-center space-x-2 p-2 md:px-4 border-b">
        <div className="flex-1 rounded-md h-10 border border-gray-200 focus-within:border-brand-1">
          <form
            className="flex items-center pl-2 md:pl-4 h-full w-full bg-white rounded-md"
            style={{ width: 'calc(100% - 3rem)' }}
            onSubmit={(e) => {
              e.preventDefault();
              searchAddressToCoordinate(searchTitle);
            }}
          >
            <button type="submit" className="focus:outline-none">
              <SearchIcon />
            </button>
            <input
              style={{ border: 'none' }}
              ref={input}
              onChange={(e) => setSearchTitle(e.target.value)}
              type="text"
              className="text-sm w-full outline-none focus:outline-none focus:ring-0 bg-transparent placeholder-gray-400"
            />
          </form>
        </div>
        <div
          className="cursor-pointer bg-white shadow-md border rounded-lg p-2 border-gray-100"
          onClick={moveToCurrentLocation}
        >
          <NavigationIcon />
        </div>
      </div>
      <div className="absolute hidden h-full md:flex md:flex-col border-r w-18 bg-white z-10 text-sm">
        {industries?.map((industry) => (
          <div
            key={industry.id + 'desktop'}
            className={`whitespace-pre h-16 flex justify-center items-center cursor-pointer ${
              industryTags?.includes(industry.id)
                ? 'bg-brand-1 text-white'
                : 'bg-white'
            }`}
            onClick={() => onClickIndustryFilterTags(industry.id, industryTags)}
          >
            <div>{industry.title}</div>
          </div>
        ))}
      </div>
      <div className="z-10 absolute top-15 w-full h-12 md:hidden flex overflow-x-auto space-x-2 px-2 scroll-box p-1">
        {industries?.map((industry) => (
          <div
            key={industry.id + 'mobile'}
            className={`rounded-full text-sm border px-4 shadow-md whitespace-pre h-8 flex items-center justify-center cursor-pointer ${
              industryTags?.includes(industry.id)
                ? 'bg-brand-1 text-white'
                : 'bg-white'
            }`}
            onClick={() => onClickIndustryFilterTags(industry.id, industryTags)}
          >
            <div>{industry.title}</div>
          </div>
        ))}
      </div>
      <div id="map" className="w-full h-full"></div>
    </div>
  );
};

const hasSamePositionMarker = (
  lat: number,
  lng: number,
  markers: Array<naver.maps.Marker>,
) => {
  for (const marker of markers) {
    const markerPosition = marker.getPosition();
    if (
      markerPosition &&
      markerPosition.y === lat &&
      markerPosition.x === lng
    ) {
      return true;
    }
  }
  return false;
};
