import React, { useState } from 'react';
import Img1 from '../../assets/png/section-3-img1.png';
import Img2 from '../../assets/png/section-3-img2.png';
import Img3 from '../../assets/png/section-3-img3.png';
import { Link } from 'react-router-dom';
import { useAuth, useRecoilValue } from '@entropyparadox/reusable-react';
import { meState } from '../../store';
import UnathorizedRouteModal from './UnathorizedRouteModal';
import { Role } from '../../types/user';

const Section3 = () => {
  const [open, setOpen] = useState(false);
  const { authenticated } = useAuth();
  const me = useRecoilValue(meState);
  const isCompany = authenticated && me?.role === Role.BUSINESS;
  const isUser = authenticated && me?.role === Role.USER;

  return (
    <div className="max-w-screen-lg mx-auto w-full px-5">
      {open && (
        <UnathorizedRouteModal
          isCompany={isCompany}
          onClose={() => setOpen(false)}
        />
      )}

      <div className="grid grid-cols-2 lg:gap-5 gap-3">
        <Link
          to="/recommend"
          className="bg-white rounded-xl lg:p-7 p-4 w-full h-full row-span-2 relative"
          onClick={(e) => {
            if (isCompany) {
              e.preventDefault();
              setOpen(true);
            }
          }}
        >
          <div className="lg:space-y-3 space-y-1">
            <p className="lg:text-2xl text-lg whitespace-pre-line font-bold">
              매칭 서비스로 <br />
              나만의 알바 찾기
            </p>
            <p className="lg:text-md text-2sm text-brand-1 bg-purple-50 py-1 px-2 rounded-full lg:w-36 w-32 text-center">
              맞춤 알바 설정하기
            </p>
          </div>

          <div className="absolute lg:bottom-7 lg:right-7 bottom-0 right-2">
            <div className="relative">
              <img
                className="object-cover xl:w-40 lg:w-40 md:w-32 sm:w-32 w-32"
                src={Img1}
                alt=""
              />
            </div>
          </div>
        </Link>

        <Link
          to={'/company'}
          onClick={(e) => {
            if (isUser) {
              e.preventDefault();
              setOpen(true);
            }
          }}
        >
          <div className="bg-white rounded-3xl lg:p-7 p-4 w-full h-full">
            <div className="lg:space-y-3 space-y-1">
              <p className="lg:text-2xl text-lg font-bold whitespace-pre-line">
                사장님 메뉴
                <br />
                찾았다 내 직원!
              </p>
              <p className="lg:text-md text-2sm text-brand-1 bg-purple-50 py-1 px-2 rounded-full lg:w-36 w-28 text-center">
                알바 공고 등록
              </p>
            </div>

            <div className="flex items-end justify-end">
              <div className="relative">
                <img
                  className="object-cover xl:w-24 lg:w-24 md:w-20 sm:w-10 w-10"
                  src={Img2}
                  alt=""
                />
              </div>
            </div>
          </div>
        </Link>

        <Link
          to={'/mypage/resume'}
          onClick={(e) => {
            if (isCompany) {
              e.preventDefault();
              setOpen(true);
            }
          }}
        >
          <div className="bg-white rounded-3xl lg:p-7 p-4 w-full h-full">
            <div className="lg:space-y-3 space-y-1">
              <p className="lg:text-2xl text-lg font-bold whitespace-pre-line">
                알바님 메뉴 <br />
                저를 소개합니다!
              </p>
              <p className="lg:text-md text-2sm text-brand-1 bg-purple-50 py-1 px-2 rounded-full lg:w-36 w-28 text-center">
                이력서 등록
              </p>
            </div>

            <div className="flex items-end justify-end">
              <div className="relative">
                <img
                  className="object-cover xl:w-24 lg:w-24 md:w-20 sm:w-10 w-10"
                  src={Img3}
                  alt=""
                />
              </div>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default Section3;
