import { useQuery, useRecoilValue } from '@entropyparadox/reusable-react';
import React from 'react';
import { meState } from '../../store';
import { resumeMe, ResumeMeData } from '../../api';
import ResumeCreateContent from '../../components/ResumeCreateContent';
import ResumeUpdateContent from '../../components/ResumeUpdateContent';

const ResumePage = () => {
  const me = useRecoilValue(meState);
  const { data, refetch } = useQuery<ResumeMeData>(resumeMe);

  if (!me) {
    return <></>;
  }

  if (!data) {
    return <ResumeCreateContent me={me} refetch={() => refetch()} />;
  }

  return (
    <ResumeUpdateContent
      resume={data.resumeMe}
      me={me}
      refetch={() => refetch()}
    />
  );
};

export default ResumePage;
