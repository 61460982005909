import React from 'react';
import { ReactComponent as NewLogoSVG } from '../../assets/svg/new-logo.svg';
import LogoPng from '../../assets/png/new-logo.png';

const Footer = () => {
  return (
    <div className="bg-white hidden lg:block">
      <div className="max-w-screen-lg mx-auto w-full py-7 px-5">
        <div className="flex items-center justify-between border-b border-gray-200 pb-3">
          <img className="object-cover w-7" src={LogoPng} alt="" />
          <p className="text-sm">Copyright(c) Corp. All right reserved.</p>
        </div>

        <div className="text-gray-500 text-sm pt-5">
          사업자등록번호 : 555-41-00895
          <br />
          통신판매업신고번호 : 제 2021-서울강남-02868호
          <br />
          주소 : 서울시 강남구 영동대로85길 20-7, 465호
          <br />
          직업정보제공 사업번호 : j1200020210009
        </div>
      </div>
    </div>
  );
};

export default Footer;
