import { useHistory, useLocation } from 'react-router-dom';
import { SolidTab } from '../components/SolidTab';
import QnaPage from './UserMypage/QnaPage';
import UsagePage from './UserMypage/UsagePage';
import AnnouncePage from './UserMypage/AnnouncePage';
import NewMainTopNavbar from '../components/NewMainTopNavbar';

enum TABS {
  QNA = 'QNA',
  USAGE = 'USAGE',
  ANNOUNCE = 'ANNOUNCE',
}

const MainMorePage = () => {
  const history = useHistory();

  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const selectedTab = (params.get('tab') || TABS.QNA) as TABS;

  return (
    <div>
      <NewMainTopNavbar hasMainButton />
      <div className="px-4 md:p-5 md:max-w-1/2 w-full mx-auto">
        <div>
          <div className="flex">
            <SolidTab
              text="자주하는 질문"
              selected={selectedTab === TABS.QNA}
              onClick={() => history.replace(`/more?tab=${TABS.QNA}`)}
            />
            <SolidTab
              text="이용방법"
              selected={selectedTab === TABS.USAGE}
              onClick={() => history.replace(`/more?tab=${TABS.USAGE}`)}
            />
            <SolidTab
              text="공지사항"
              selected={selectedTab === TABS.ANNOUNCE}
              onClick={() => history.replace(`/more?tab=${TABS.ANNOUNCE}`)}
            />
          </div>
        </div>

        {selectedTab === TABS.QNA && <QnaPage />}
        {selectedTab === TABS.USAGE && <UsagePage />}
        {selectedTab === TABS.ANNOUNCE && <AnnouncePage />}
      </div>
    </div>
  );
};

export default MainMorePage;
