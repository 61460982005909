import React, { FC, HTMLAttributes } from 'react';
import { twMerge } from 'tailwind-merge';

interface ChipProps extends HTMLAttributes<HTMLDivElement> {
  text: string;
  selected?: boolean;
}

export const Chip: FC<ChipProps> = ({ text, selected = false, ...props }) => {
  const backgroundColor = selected ? 'bg-brand-1' : 'bg-gray-100';
  const color = selected ? 'text-white' : 'text-gray-800';

  return (
    <div
      {...props}
      className={twMerge(
        'px-4 py-2 rounded-full whitespace-nowrap text-2sm',
        backgroundColor,
        color,
      )}
    >
      {text}
    </div>
  );
};
