import {
  AspectRatioImage,
  Avatar,
  Blank,
  Button,
  Label,
  PhoneNumberField,
  Section,
  Select,
  TextArea,
  TextField,
  useMutation,
} from '@entropyparadox/reusable-react';
import React, { useEffect, useState } from 'react';
import { Textarea } from './Textarea';
import { ReactComponent as FileUploadImg } from '../assets/svg/file-upload.svg';
import { ReactComponent as ContentFormImg } from '../assets/svg/contentForm.svg';
import { ME, createResume } from '../api';
import { User } from '../types/user';
import { useHistory } from 'react-router-dom';

interface ResumeContentProps {
  me: User;
  refetch: () => void;
}

// function getFormatDate(date: any) {
//   var year = date.getFullYear(); //yyyy
//   var month = 1 + date.getMonth(); //M
//   month = month >= 10 ? month : '0' + month; //month 두자리로 저장
//   var day = date.getDate(); //d
//   day = day >= 10 ? day : '0' + day; //day 두자리로 저장
//   return year + '-' + month + '-' + day;
// }

const ResumeContent: React.FC<ResumeContentProps> = ({ me, refetch }) => {
  const [isLoad, setIsLoad] = useState(false);
  const [avatar, setAvatar] = useState<File | null | undefined>();
  const [profile, setProfile] = useState('');
  const [healthCertificate, setHealthCertificate] = useState<
    File | null | undefined
  >();
  const [consentForm, setConsentForm] = useState<File | null | undefined>();
  const [bank, setBank] = useState('');
  const [bankAccount, setBankAccount] = useState('');
  const [career, setCareer] = useState('');
  const [introduction, setIntroduction] = useState('');
  const [address] = useState(me.address);
  const [detailAddress, setDetailAddress] = useState(me.detailAddress);
  const { push } = useHistory();

  const [CreateResume] = useMutation(createResume, {
    variables: {
      avatar,
      healthCertificate: healthCertificate,
      consentForm,
      bank,
      bankAccount,
      career,
      introduction,
    },
    onCompleted: async () => {
      await setIsLoad(false);
      await refetch();
      alert('등록이 완료 되었습니다.');
      push('/main');
    },
    refetchQueries: [{ query: ME }],
  });

  useEffect(() => {
    if (avatar) {
      setProfile(URL.createObjectURL(avatar));
    }
  }, [avatar]);

  return (
    <>
      {isLoad && <Blank />}
      <div className="w-full p-4 bg-white">
        <div className="text-xl truncate text-gray-800 font-bold my-1">
          내 이력서
        </div>
        <div className="text-base text-gray-500">
          이력 정보를 입력하고 알바를 지원해보세요.
        </div>
      </div>
      <Section>
        <TextField label="*이름" value={me.name} disabled />
        <div>
          <Label text="*생년월일" />
          <div className="flex space-x-3.5 mt-0">
            <div className="w-full text-gray-500">
              <Select
                placeholder="년"
                disabled
                style={{ background: '#EaEbEc' }}
              >
                <option value="" selected disabled hidden>
                  {me.birthday.substr(0, 4)}년
                </option>
              </Select>
            </div>
            <div className="w-full text-gray-500">
              <Select
                placeholder="월"
                disabled
                style={{ background: '#EaEbEc' }}
              >
                <option value="" selected disabled hidden>
                  {me.birthday.substr(4, 2)}월
                </option>
              </Select>
            </div>
            <div className="w-full text-gray-500">
              <Select
                placeholder="일"
                disabled
                style={{ background: '#EaEbEc' }}
              >
                <option value="" selected disabled hidden>
                  {me.birthday.substr(6, 2)}일
                </option>
              </Select>
            </div>
          </div>
        </div>
        <PhoneNumberField
          label="*핸드폰 번호"
          value={me.phone || ''}
          disabled
          onChange={() => {}}
        />
        <div className="mt-4">
          <label
            htmlFor="expiration_date"
            className="block text-sm font-medium text-gray-700"
          >
            *집 주소
          </label>
          <div className="mt-1">
            <div className="col-span-3">
              <TextField
                type="text"
                name="address"
                id="address"
                placeholder="주소를 입력해주세요."
                value={address}
                disabled
              />
            </div>
          </div>
        </div>
        <TextField
          label="*상세 주소"
          placeholder="상세 주소를 입력해주세요"
          value={detailAddress}
          onChange={(e) => setDetailAddress(e.target.value)}
          disabled
        />
        <div className="text-sm text-gray-500">
          *정보 수정은 프로필에서 가능합니다.
        </div>

        <div className="pt-10">
          <div className="text-gray-800 text-lg mb-2 font-semibold">
            *내 사진
          </div>

          <div className="flex items-center space-x-6">
            {avatar ? (
              <Avatar src={profile} size={16} />
            ) : (
              <div className="w-12 h-12 rounded-3xl bg-gray-100"></div>
            )}
            <label htmlFor="avatar">
              <div className="h-8 border border-gray-500 rounded-md flex items-center px-4 cursor-pointer">
                추가하기
              </div>
            </label>
          </div>
          <input
            type="file"
            id="avatar"
            className="hidden"
            accept="image/*"
            onChange={(e) => {
              e.target.validity.valid && setAvatar(e.target.files?.item(0));
            }}
          />
        </div>
        <TextArea
          label="*이력 정보"
          placeholder="이력 정보를 입력해주세요."
          value={career}
          onChange={(e) => setCareer(e.target.value)}
          style={{ height: '120px' }}
        />
        <TextArea
          label="*자기소개"
          placeholder="자기소개를 입력해주세요."
          value={introduction}
          onChange={(e) => setIntroduction(e.target.value)}
          style={{ height: '120px' }}
        />

        <TextField
          label="*계좌번호"
          placeholder="은행명을 입력해주세요."
          value={bank}
          onChange={(e) => setBank(e.target.value)}
        />
        <TextField
          placeholder="계좌번호를 입력해주세요. (- 포함)"
          value={bankAccount}
          onChange={(e) => setBankAccount(e.target.value)}
        />

        <label htmlFor="healthCertificate">
          <div className="text-gray-800 text-sm mb-2">
            <b>(선택)</b> 보건증 서류 (1년 이내에 발급된 보건증)
          </div>
          {!healthCertificate && (
            <div className="w-full border-2 py-10 border-dashed border-grey-5 flex flex-col justify-center items-center space-y-1">
              <FileUploadImg />
              <div className="text-gray-500 text-xs w-3/5 text-center">
                보건증을 업로드해주세요.
                <br />
                PNG, JPG 10MB 미만
              </div>
            </div>
          )}

          {healthCertificate && (
            <>
              <AspectRatioImage
                src={URL.createObjectURL(healthCertificate) || ''}
                alt=""
                pb="pb-2/3"
              />
              <div className="w-full mt-4">
                <Button style={{ width: '100%' }}>
                  <label
                    htmlFor="healthCertificate"
                    style={{ cursor: 'pointer' }}
                  >
                    수정하기
                  </label>
                </Button>
              </div>
            </>
          )}
        </label>
        <input
          type="file"
          id="healthCertificate"
          className="hidden"
          accept="image/*"
          onChange={(e) => {
            e.target.validity.valid &&
              setHealthCertificate(e.target.files?.item(0));
          }}
        />

        <label htmlFor="consentForm">
          <div className="text-gray-800 text-sm mb-2 ">
            <b>(선택)</b> 부모님 동의서 (미성년자 필수항목입니다)
          </div>

          {!consentForm && (
            <div className="w-full border-2 py-10 border-dashed border-grey-5 flex flex-col justify-center items-center space-y-1">
              <FileUploadImg />
              <div className="text-gray-500 text-xs w-3/5 text-center">
                부모님 동의서를 업로드 해주세요.
                <br />
                PNG, JPG 10MB 미만
              </div>
            </div>
          )}

          {consentForm && (
            <>
              <AspectRatioImage
                src={URL.createObjectURL(consentForm) || ''}
                alt=""
                pb="pb-2/3"
              />
              <div className="w-full mt-4">
                <Button style={{ width: '100%' }}>
                  <label htmlFor="consentForm" style={{ cursor: 'pointer' }}>
                    수정하기
                  </label>
                </Button>
              </div>
            </>
          )}
        </label>
        <input
          type="file"
          id="consentForm"
          className="hidden"
          accept="image/*"
          onChange={(e) => {
            e.target.validity.valid && setConsentForm(e.target.files?.item(0));
          }}
        />

        <div className="text-gray-800 text-sm">부모님 동의서 양식 다운로드</div>
        <a
          href="https://s3.ap-northeast-2.amazonaws.com/scv.2020/%E1%84%87%E1%85%AE%E1%84%86%E1%85%A9%E1%84%82%E1%85%B5%E1%86%B7%E1%84%83%E1%85%A9%E1%86%BC%E1%84%8B%E1%85%B4%E1%84%89%E1%85%A5.hwp"
          download
        >
          <div className=" border border-gray-200 rounded-md px-4 h-12 flex justify-between items-center">
            <div className="flex items-center">
              <ContentFormImg className="mr-2" />
              부모님 동의서.hwp
            </div>
            <div className=" text-brand-1">Download</div>
          </div>
        </a>

        <Button
          disabled={
            !avatar || !bank || !bankAccount || !career || !introduction
          }
          onClick={() => {
            CreateResume();
          }}
        >
          저장하기
        </Button>
      </Section>
    </>
  );
};

export default ResumeContent;
