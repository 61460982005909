import { TextField } from '@entropyparadox/reusable-react';
import React from 'react';
import { ReactComponent as SearchIcon } from '../../assets/svg/search.svg';
import { useHistory } from 'react-router-dom';

const SerchField = () => {
  const history = useHistory();
  return (
    <div onClick={() => history.push('/map')} className="relative w-full">
      <TextField placeholder="일하고 싶은 곳이 있나요?" />

      <SearchIcon className="absolute top-3.5 right-5" />
    </div>
  );
};

export default SerchField;
