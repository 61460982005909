import {
  Button,
  List,
  LoadingScreen,
  useQuery,
} from '@entropyparadox/reusable-react';
import { MyJobs, MyJobsData } from '../../api';
import { CompanyJobItem } from '../../components';
import { useHistory } from 'react-router-dom';

const CompanyNotice = () => {
  const { loading, error, data } = useQuery<MyJobsData>(MyJobs);
  const history = useHistory();

  if (loading) return <LoadingScreen />;
  if (error || !data) return <></>;

  const jobs = data.myJobs.slice().sort((a, b) => b.id - a.id) || [];
  return (
    <div className="max-w-md mx-auto">
      <div className="flex justify-between items-center py-2 px-4 bg-gray-50">
        <p className="">총 {jobs?.length}개</p>
        <button
          className="bg-brand-1 text-white rounded-full w-fit px-5 py-2"
          onClick={() => {
            if (!data?.me?.isActive) {
              alert('관리자의 승인을 기다리는 중입니다.');
              return;
            }
            if ((data?.me?.ticketNumber || 0) <= 0) {
              alert('공고 등록을 위해 먼저 이용권을 구매해주세요!');
              return;
            }
            history.push('/jobs/new');
          }}
        >
          + 새 공고 등록
        </button>
      </div>
      <List>
        {jobs?.map((job) => (
          <CompanyJobItem key={job.id} job={job} />
        ))}
        {data?.myJobs.length === 0 ? (
          <div className="flex justify-center items-center text-gray-500 mt-20">
            등록된 공고가 없습니다.
          </div>
        ) : (
          ''
        )}
      </List>
    </div>
  );
};

export default CompanyNotice;
