import React from 'react';

interface SwitchButtonProps {
  onChange: (value: any) => void;
  value: boolean;
  id: any;
}

export const SwitchButton: React.FC<SwitchButtonProps> = ({
  onChange,
  value,
  id,
}) => {
  return (
    <div className="">
      <label htmlFor={id} className="flex items-center cursor-pointer">
        <div className="relative">
          <input
            type="checkbox"
            id={id}
            className="sr-only"
            checked={value}
            onChange={(e) => onChange(!value)}
          ></input>
          <div
            className={`block
            ${value ? 'bg-brand-1' : ' bg-gray-200'} w-15 h-9 rounded-full`}
          ></div>
          <div
            className={`absolute ${
              value ? 'right-1' : 'left-1'
            } top-0.5 bg-white w-8 h-8 rounded-full transition shadow border`}
          ></div>
        </div>
      </label>
    </div>
  );
};
