export const ANNOUNCEMENT_LIST = [
  {
    id: 1,
    title: '리뉴얼 기념 무료 이벤트',
    date: '2024.04.04',
    description: `안녕하세요 우구구입니다. 홈페이지 리뉴얼 기념 무료 이벤트를 진행합니다.

    사장님들은 회원 가입하시고 승인을 완료하면 공고를 등록할 수 있는 이용권을 지급해 드릴 예정이오니 많은 관심 부탁드립니다.
    
    * 이벤트 종료는 별도 공지 예정입니다.`,
  },
];
