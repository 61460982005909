import { useHistory, useLocation } from 'react-router-dom';
import { SolidTab } from '../../components/SolidTab';
import AnnouncePage from './AnnouncePage';
import UsagePage from './UsagePage';
import QnaPage from './QnaPage';

enum TABS {
  QNA = 'QNA',
  USAGE = 'USAGE',
  ANNOUNCE = 'ANNOUNCE',
}

const MorePage = () => {
  const history = useHistory();

  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const selectedTab = (params.get('tab') || TABS.QNA) as TABS;

  return (
    <div className="p-5">
      <div>
        <div className="flex">
          <SolidTab
            text="자주하는 질문"
            selected={selectedTab === TABS.QNA}
            onClick={() => history.replace(`/mypage/more?tab=${TABS.QNA}`)}
          />
          <SolidTab
            text="이용방법"
            selected={selectedTab === TABS.USAGE}
            onClick={() => history.replace(`/mypage/more?tab=${TABS.USAGE}`)}
          />
          <SolidTab
            text="공지사항"
            selected={selectedTab === TABS.ANNOUNCE}
            onClick={() => history.replace(`/mypage/more?tab=${TABS.ANNOUNCE}`)}
          />
        </div>
      </div>

      {selectedTab === TABS.QNA && <QnaPage />}
      {selectedTab === TABS.USAGE && <UsagePage />}
      {selectedTab === TABS.ANNOUNCE && <AnnouncePage />}
    </div>
  );
};

export default MorePage;
