import React, { ButtonHTMLAttributes, FC } from 'react';

export interface SolidTabProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  selected?: boolean;
  text: string;
}

export const SolidTab: FC<SolidTabProps> = ({ selected, text, ...props }) => {
  return (
    <button
      {...props}
      className={`w-full border border-gray-100 py-3 bg-white ${
        selected ? 'text-white bg-gray-900' : 'text-gray-800'
      } `}
    >
      {text}
    </button>
  );
};
