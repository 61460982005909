export interface Tag {
  id: number;
  title: string;
  status: Status;
}

export enum Status {
  INDUSTRY = 'INDUSTRY',
  JOBTYPE = 'JOBTYPE',
  WORKPERIOD = 'WORKPERIOD',
  WORKTIME = 'WORKTIME',
  WORKDAY = 'WORKDAY',
  PAYTYPE = 'PAYTYPE',
}

export type TagFilterType = {
  albaType?: Array<number>;
  industry?: Array<number>;
  jobType?: Array<number>;
  workDay?: Array<number>;
  workPeriod?: Array<number>;
  workTime?: Array<number>;
};
