import {
  BackButton,
  LoadingScreen,
  TopNavbar,
  useIdParam,
  useQuery,
} from '@entropyparadox/reusable-react';
import React from 'react';
import { JOB, JobData } from '../api';
import JobEditContent from '../components/JobEditContent';
import NewMainTopNavbar from '../components/NewMainTopNavbar';

const EditJobPage = () => {
  const id = useIdParam();

  const { loading, error, data } = useQuery<JobData>(JOB, {
    variables: {
      id: id,
    },
  });

  if (loading) return <LoadingScreen />;
  if (error || !data) return <></>;

  return (
    <>
      <NewMainTopNavbar />
      <div className="max-w-md mx-auto">
        <div className="md:hidden">
          <TopNavbar title="공고 수정하기" left={<BackButton />} />
        </div>
        <div className="hidden md:block inset-0">
          <div className=" text-2xl mt-8 text-center">공고 수정하기</div>
        </div>
        <JobEditContent job={data.job} id={id} />
      </div>
    </>
  );
};

export default EditJobPage;
