import {
  Divider,
  List,
  LoadingScreen,
  useIdParam,
  useQuery,
} from '@entropyparadox/reusable-react';
import React from 'react';
import { CompanyJobItem } from '../components';
import { companiesJobs, CompaniesJobsData } from '../api';
import CompanyContentField from '../components/CompanyContentField';
import NewMainTopNavbar from '../components/NewMainTopNavbar';

export const NoticeDetailPage: React.FC = () => {
  const id = useIdParam();

  const { loading, error, data } = useQuery<CompaniesJobsData>(companiesJobs, {
    variables: {
      id: Number(id),
    },
    onError: (err) => {
      console.log(err);
    },
  });

  if (loading) return <LoadingScreen />;
  if (error || !data) return <></>;

  const jobs = data.companiesJobs.slice().sort((a, b) => {
    if (a.albaType < b.albaType && a.deadLine < b.deadLine) {
      return 1;
    }
    if (a.albaType > b.albaType || a.deadLine < b.deadLine) {
      return -1;
    }
    return 0;
  });

  return (
    <>
      <NewMainTopNavbar maintitle="업장 상세정보" />
      <div className="hidden md:block inset-0">
        <div className=" text-2xl mt-24 text-center">업장 상세정보</div>
      </div>

      <div className="sm:max-w-md sm:mx-auto sm:mt-8">
        <CompanyContentField companyId={id} />
        <Divider height="h-3" />
        <div className="text-xl font-bold text-gray-800 px-5 pt-6 pb-2">
          등록된 공고
        </div>
        <List>
          {jobs.map((job) => (
            <CompanyJobItem key={job.id} job={job} />
          ))}
        </List>
      </div>
    </>
  );
};
