import {
  BottomFixedButton,
  TopNavbar,
  CloseButton,
  Section,
  Chip,
  Divider,
} from '@entropyparadox/reusable-react';
import React, { useEffect, useState } from 'react';
import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { Status, Tag } from '../types/tag';

interface FilterPageContentProps {
  tags: Tag[];
}

export const AlbaTypeData = [
  { id: 1, type: 'NORMAL', title: '일반공고', status: 'NORMAL' },
  { id: 2, type: 'URGENTLY', title: '번쩍알바', status: 'URGENTLY' },
];

export const FilterPageContent: FC<FilterPageContentProps> = ({ tags }) => {
  const history = useHistory();
  let tagsByStatusAll = {
    workDay: new Array<number>(),
    workTime: new Array<number>(),
    workPeriod: new Array<number>(),
    industry: new Array<number>(),
    jobType: new Array<number>(),
    albaType: new Array<string>(),
  };
  const selectedTags = window.localStorage.getItem('selectedTags');
  if (!selectedTags) {
    window.localStorage.setItem(
      'selectedTags',
      JSON.stringify(tagsByStatusAll),
    );
  } else {
    tagsByStatusAll = JSON.parse(selectedTags);
  }

  const [workDay, setWorkDay] = useState(tagsByStatusAll.workDay);
  const [workTime, setWorkTime] = useState(tagsByStatusAll.workTime);
  const [workPeriod, setWorkPeriod] = useState(tagsByStatusAll.workPeriod);
  const [industry, setIndustry] = useState(tagsByStatusAll.industry);
  const [jobType, setJobType] = useState(tagsByStatusAll.jobType);
  const [albaType, setAlbaType] = useState(tagsByStatusAll.albaType || []);

  const handleSearch = () => {
    window.localStorage.setItem(
      'selectedTags',
      JSON.stringify({
        workDay,
        workTime,
        workPeriod,
        industry,
        jobType,
        albaType,
      }),
    );
    history.replace(`/map`);
  };

  const handleReset = () => {
    window.localStorage.removeItem('selectedTags');
    setWorkDay(new Array<number>());
    setWorkTime(new Array<number>());
    setWorkPeriod(new Array<number>());
    setIndustry(new Array<number>());
    setJobType(new Array<number>());
    setAlbaType(new Array<string>());
    history.replace(`/map/filter`);
  };

  useEffect(() => {
    if (!albaType) return;
    if (albaType?.includes('URGENTLY')) {
      setWorkDay(new Array<number>());
      setWorkTime(new Array<number>());
      setWorkPeriod(new Array<number>());
    }
  }, [albaType]);

  const tagChip = (tag: Tag, type: string, getData: any, setData: any) => {
    return (
      <>
        {tag.status === type && (
          <Chip
            key={tag.id}
            text={tag.title}
            style={{ margin: '0.25rem' }}
            selected={getData?.includes(tag.id)}
            onClick={() => {
              const newValue = new Set<Number>(getData);
              if (getData?.includes(tag.id)) {
                newValue.delete(tag.id);
              } else {
                newValue.add(tag.id);
              }
              setData(Array.from(newValue));
            }}
          />
        )}
      </>
    );
  };

  const AlbaTypeChip = (at: any, type: string, getData: any, setData: any) => {
    return (
      <>
        {at.status === type && (
          <Chip
            key={at.id}
            text={at.title}
            style={{ margin: '0.25rem' }}
            selected={getData?.includes(at.type)}
            onClick={() => {
              const newValue = new Set<String>(getData);
              if (getData?.includes(at.type)) {
                newValue.delete(at.type);
              } else {
                newValue.add(at.type);
              }
              setData(Array.from(newValue));
            }}
          />
        )}
      </>
    );
  };

  return (
    <>
      <TopNavbar
        title="필터"
        left={
          <div
            className="text-gray-500 cursor-pointer"
            onClick={handleReset}
            style={{ marginLeft: '0.75rem' }}
          >
            초기화
          </div>
        }
        right={<CloseButton />}
      />
      <div className="bg-white flex justify-center relative">
        <div className="ps:mt-4 md:mt-6 sm:mx-auto sm:w-md sm:w-160">
          <Section>
            <>
              <div className="text-lg font-bold text-grey-800">공고 유형</div>
              <div className="flex flex-wrap space-x-2">
                {AlbaTypeData.map((at) => (
                  <>{AlbaTypeChip(at, at.type, albaType, setAlbaType)}</>
                ))}
              </div>
              <div className="text-lg font-bold text-grey-800">근무 형태</div>
              <div className="flex flex-wrap">
                {tags.map((tag) => (
                  <>{tagChip(tag, Status.JOBTYPE, jobType, setJobType)}</>
                ))}
              </div>
              <div className="text-lg font-bold text-grey-800">업,직종</div>
              <div className="flex flex-wrap">
                {tags.map((tag) => (
                  <>{tagChip(tag, Status.INDUSTRY, industry, setIndustry)}</>
                ))}
              </div>
              {!albaType?.includes('URGENTLY') && (
                <>
                  <div className="text-lg font-bold text-grey-800">
                    근무 기간
                  </div>
                  <div className="flex flex-wrap">
                    {tags.map((tag) => (
                      <>
                        {tagChip(
                          tag,
                          Status.WORKPERIOD,
                          workPeriod,
                          setWorkPeriod,
                        )}
                      </>
                    ))}
                  </div>
                  <div className="text-lg font-bold text-grey-800">
                    근무 시간대
                  </div>
                  <div className="flex flex-wrap">
                    {tags.map((tag) => (
                      <>
                        {tagChip(tag, Status.WORKTIME, workTime, setWorkTime)}
                      </>
                    ))}
                  </div>
                  <div className="text-lg font-bold text-grey-800">
                    근무 요일
                  </div>
                  <div className="flex flex-wrap">
                    {tags.map((tag) => (
                      <>{tagChip(tag, Status.WORKDAY, workDay, setWorkDay)}</>
                    ))}
                  </div>
                </>
              )}

              <Divider />
            </>
          </Section>
          <BottomFixedButton
            text="적용하기"
            onClick={() => handleSearch()}
            style={{ maxWidth: '28rem' }}
          />
        </div>
      </div>
    </>
  );
};
