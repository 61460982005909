import { Button, Divider, List, Section } from '@entropyparadox/reusable-react';
import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { JobListItem } from '../components/JobListItem';
import { ReactComponent as ChevronLeft } from '../assets/svg/chevron-left.svg';
import { ReactComponent as MapIcon } from '../assets/svg/map.svg';
import { MenuFilterIcon } from '../components';
import { Job } from '../types/job';

interface JobsPageProps {
  isMapView: boolean;
  setMapView: (mapView: boolean) => void;
  jobs: Job[] | undefined;
}

export const JobsPage: React.FC<JobsPageProps> = ({
  isMapView,
  setMapView,
  jobs,
}) => {
  const sequenceJobs = jobs?.slice().sort((a, b) => {
    if (a.albaType < b.albaType && a.deadLine < b.deadLine) {
      return 1;
    }
    if (a.albaType > b.albaType || a.deadLine < b.deadLine) {
      return -1;
    }
    return 0;
  });

  return (
    <>
      <div className="relative h-full">
        <div className="md:hidden sticky top-0 bg-white border-b">
          <div className="flex items-center justify-between mx-5">
            <div
              className="font-bold flex items-center space-x-3 cursor-pointer py-4 w-full"
              onClick={() => setMapView(true)}
            >
              <ChevronLeft /> <div>이 지역 리스트</div>
            </div>
            <Link to="/map/filter">
              <MenuFilterIcon />
            </Link>

            <Divider height="h-3" />
          </div>
        </div>
        <div className="sticky top-0 bg-white border-b hidden md:block">
          <Section>
            <div className="flex justify-between items-center">
              <h3 className="font-bold text-xl">알바 리스트</h3>
              <div className="hidden md:block">
                <Link to="/map/filter">
                  <MenuFilterIcon />
                </Link>
              </div>
            </div>
          </Section>
        </div>
        <Divider margin="mx-5" />
        <div className="flex-1 overflow-scroll scroll-box ">
          <List>
            {jobs?.length === 0 && (
              <p className="flex justify-center items-center h-full text-gray-500 mt-20">
                검색된 알바 리스트가 없습니다.
              </p>
            )}
            {sequenceJobs?.map((job) => (
              <JobListItem key={job.id} job={job} />
            ))}
          </List>
          <div className="w-full h-20"></div>
        </div>
        {!isMapView && (
          <div className="sm:hidden fixed bottom-24 z-10 w-full flex justify-center">
            <button
              className="bg-brand-1 rounded-full py-2 px-4 text-white flex space-x-2"
              onClick={() => setMapView(true)}
            >
              <MapIcon />
              <p>지도로 보기</p>
            </button>
          </div>
        )}
      </div>
    </>
  );
};
