import React from 'react';
import DaumPostcode from 'react-daum-postcode';

interface DaumPostProps {
  setAddress: (fullAddress: any) => void;
  setIsOpen: () => void;
}

export const DaumPost: React.FC<DaumPostProps> = ({
  setAddress,
  setIsOpen,
}) => {
  const handleComplete = (data: any) => {
    let fullAddress = data.address;
    let extraAddress = '';

    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname;
      }
      if (data.buildingName !== '') {
        extraAddress +=
          extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
    }
    setIsOpen();
    setAddress(fullAddress);
  };
  return (
    <div className="border-gray-400 border mt-3">
      <DaumPostcode onComplete={handleComplete} autoClose={true} />
    </div>
  );
};
