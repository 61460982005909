import { atom } from '@entropyparadox/reusable-react';
import { User } from './types/user';
// import { Mapdata } from './types/Mapdata';

export const meState = atom<User | undefined>({
  key: 'meState',
  default: undefined,
});

// export const mapState = atom<Mapdata | undefined>({
//   key: 'mapState',
//   default: undefined,
// });
