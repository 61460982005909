import React from 'react';
import { List, LoadingScreen, useQuery } from '@entropyparadox/reusable-react';
import { AppliesJobData, applyJobs } from '../../api';
import MyApplyCard from '../../components/MyApplyCard';

const ApplyList = () => {
  const { loading, error, data } = useQuery<AppliesJobData>(applyJobs);

  if (loading) return <LoadingScreen />;
  if (error || !data) return <></>;

  const jobs = data.applyJobs.slice().sort((a, b) => b.id - a.id) || [];

  return (
    <>
      <List>
        {jobs.map((job) => (
          <MyApplyCard key={job.id} apply={job} />
        ))}
        {data.applyJobs.length === 0 ? (
          <div className="text-gray-500 flex justify-center items-center h-full mt-32">
            지원한 알바가 없습니다.
          </div>
        ) : (
          ''
        )}
      </List>
    </>
  );
};

export default ApplyList;
