import React, { FC } from 'react';
import { ReactComponent as Pin } from '../assets/svg/pin.svg';
import { ReactComponent as RedPin } from '../assets/svg/red-pin.svg';
import { AlbaType, Job } from '../types/job';

interface MarkerProps {
  lng?: number;
  key?: number;
  lat?: number;
  count?: number;
  total?: number;
  id?: number;
  place: Job & { isUrgent: boolean };
  onClickCluster?: () => void;
  onClickPoint: () => void;
}

const Marker: FC<MarkerProps> = ({
  lng,
  lat,
  count,
  total,
  place,
  key,
  onClickCluster,
  onClickPoint,
  id,
}) => {
  return (
    <>
      {place.albaType === AlbaType.NORMAL ? (
        <Pin
          onClick={onClickPoint}
          style={{ position: 'absolute', transform: 'translate(-50%, -100%)' }}
        />
      ) : (
        <RedPin
          onClick={onClickPoint}
          style={{ position: 'absolute', transform: 'translate(-50%, -100%)' }}
        />
      )}
    </>
  );
};

export default Marker;
