import React from 'react';
import { FC } from 'react';

interface JobRequirementsFieldProps {
  label: string;
  opening?: string;
  education?: string;
  gender?: string;
  qualification?: string;
  age?: string;
  advantage?: string;
}

const JobRequirementsField: FC<JobRequirementsFieldProps> = ({
  label,
  opening,
  education,
  gender,
  qualification,
  advantage,
  age,
}) => {
  return (
    <>
      {opening && (
        <div className="flex-shrink-0 flex">
          <div className="text-gray-500 flex-shrink-0 mr-3">{label}</div>
          <div className="text-gray-800 break-all mr-8">{opening}</div>
        </div>
      )}
      {education && (
        <div className="flex-shrink-0 flex">
          <div className="text-gray-500 flex-shrink-0 mr-3">{label}</div>
          <div className="text-gray-800 break-all mr-8">{education}</div>
        </div>
      )}
      {gender && (
        <div className="flex-shrink-0 flex">
          <div className="text-gray-500 flex-shrink-0 mr-3">{label}</div>
          <div className="text-gray-800 break-all mr-8">{gender}</div>
        </div>
      )}
      {qualification && (
        <div className="flex-shrink-0 flex">
          <div className="text-gray-500 flex-shrink-0 mr-3">{label}</div>
          <div className="text-gray-800 break-all mr-8">{qualification}</div>
        </div>
      )}
      {age && (
        <div className="flex-shrink-0 flex">
          <div className="text-gray-500 flex-shrink-0 mr-3">{label}</div>
          <div className="text-gray-800 break-all mr-8">{age}</div>
        </div>
      )}
      {advantage && (
        <div className="flex-shrink-0 flex">
          <div className="text-gray-500 flex-shrink-0 mr-3">{label}</div>
          <div className="text-gray-800 break-all">{advantage}</div>
        </div>
      )}
    </>
  );
};

export default JobRequirementsField;
