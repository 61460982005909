import { Label, LoadingScreen, useQuery } from '@entropyparadox/reusable-react';
import React, { forwardRef } from 'react';
import { CompaniesData, MyCompanies } from '../api';
import ReqPrefix from './ReqPrefix';
import { Select } from './Select';

interface CompaniesSelectFieldProps {
  helper?: string;
  [key: string]: any;
}

const CompaniesSelectField = forwardRef<
  HTMLSelectElement,
  CompaniesSelectFieldProps
>(({ helper, ...props }, ref) => {
  const { loading, error, data } = useQuery<CompaniesData>(MyCompanies);

  if (loading) return <LoadingScreen />;
  if (error || !data) return <></>;

  return (
    <div>
      <div className="text-lg font-semibold pt-4 mb-5">업장 정보</div>
      <Label>
        <ReqPrefix />
        업장 선택
      </Label>
      <Select
        ref={ref}
        placeholder="공고를 올릴 업장을 선택해주세요."
        {...props}
      >
        <option value={''} selected disabled hidden>
          선택
        </option>
        {data.businessUserGetMyCompany.map((company) => (
          <option value={company.id} key={company.id}>
            {company.name}
          </option>
        ))}
      </Select>
      {helper && <div className="mt-1 text-red-500">{helper}</div>}
    </div>
  );
});

export default CompaniesSelectField;
