import React from 'react';
import { ReactComponent as IconChevronRight } from '../../assets/svg/chevron-right.svg';
import { Link } from 'react-router-dom';
import { Announcement } from '../../types/announcement';

const NoticeCard = ({ announcement }: { announcement: Announcement }) => {
  return (
    <Link to={`/announcement/${announcement.id}`}>
      <div className="bg-white rounded-xl p-5 lg:space-y-5 space-y-4 card-shadow">
        <div className="space-y-1">
          <div className="flex items-center justify-between">
            <p className="lg:text-xl text-lg font-semibold">
              {announcement.title}
            </p>
            <IconChevronRight className="text-gray-400" />
          </div>
          <p className="lg:text-sm text-xs text-gray-500">
            {announcement.date}
          </p>
        </div>

        <p className="lg:text-md text-md text-gray-700 truncate-2-lines">
          {announcement.description}
        </p>
      </div>
    </Link>
  );
};

export default NoticeCard;
