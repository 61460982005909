import {
  BackButton,
  Divider,
  Section,
  TopNavbar,
  Button,
  useIdParam,
  useQuery,
  useRecoilValue,
  useMutation,
} from '@entropyparadox/reusable-react';
import { ReactComponent as HeartIcon } from '../assets/svg/heart-icon.svg';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { DeletedJob, JOB, JobData } from '../api';
import JobTagIdField from '../components/JobTagIdField';
import JobRequirementsField from '../components/JobRequirementsField';
import ApplyButton from '../components/ApplyButton';
import { meState } from '../store';
import { Role } from '../types/user';
import JobLikeIcon from '../components/JobLikeIcon';
import { AlbaType } from '../types/job';
import ReactPlayer from 'react-player';

interface JobPageProps {
  map: any;
}

export const JobPage: React.FC<JobPageProps> = ({ map }) => {
  const id = useIdParam();
  const me = useRecoilValue(meState);
  const history = useHistory();

  const { error, data } = useQuery<JobData>(JOB, {
    variables: {
      id: id,
    },
    nextFetchPolicy: 'cache-only',
  });

  const handleSignUp = () => {
    alert('로그인이 필요합니다.');
    history.push('/login');
  };

  const handleDeleted = () => {
    const answer = window.confirm('정말 삭제하시겠습니까?');
    if (answer) {
      deletedJob();
    } else {
      console.log('취소');
    }
  };

  const [deletedJob] = useMutation(DeletedJob, {
    variables: {
      id,
    },
    onCompleted: () => {
      alert('삭제가 완료 되었습니다.');
      history.push('/company');
    },
  });

  const clientWidth = () => {
    return (
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth
    );
  };

  const isDesktop = () => {
    return clientWidth() >= 768;
  };

  // if (loading) return <LoadingScreen />;
  if (error || !data) return <></>;

  if (data.job.processType === 'UNAUTHORIZED') {
    return (
      <>
        <TopNavbar
          left={<BackButton />}
          title="상세정보"
          right={
            <div
              onClick={() => history.push('/map')}
              className="cursor-pointer text-sm"
            >
              지도보기
            </div>
          }
        />
        <div className="p-4">아직 미승인된 공고의 정보는 볼 수 없습니다.</div>
      </>
    );
  }

  return (
    <>
      <TopNavbar
        left={<BackButton />}
        title="상세정보"
        right={
          <div
            onClick={() => history.push('/map')}
            className="cursor-pointer text-sm"
          >
            지도보기
          </div>
        }
      />
      <Section>
        <div className="text-xl font-bold text-gray-800">{data.job.title}</div>
        <div className="text-gray-500 whitespace-pre-line">
          {data.job.summery}
        </div>
      </Section>
      <Divider />
      <Section>
        <div className="text-lg font-bold text-gray-700">근무 정보</div>

        <JobTagIdField label="지원 마감" deadLine={data.job.deadLine} />
        <JobTagIdField label="업· 직종" industry={data.job.industry} />
        {data.job.workingDay && (
          <div className="flex justify-between">
            <div className="text-gray-500 flex-shrink-0">근무 일자</div>
            <div className="text-gray-800 font-bold text-right">
              <div>{data.job.workingDay}</div>
            </div>
          </div>
        )}

        {data.job.albaType === AlbaType.NORMAL && (
          <>
            <JobTagIdField label="근무 기간" workPeriod={data.job.workPeriod} />
            <JobTagIdField label="근무 요일" workDay={data.job.workDay} />
            <JobTagIdField label="근무 시간대" workTime={data.job.workTime} />
          </>
        )}

        <JobTagIdField
          label="근무 상세 시간"
          detailWorkTime={data.job.detailWorkTime}
        />
        <JobTagIdField payType={data.job.payType} wage={data.job.wage} />

        {data.job.albaType === AlbaType.NORMAL && (
          <>
            <div>
              <div className="text-gray-500 my-1">복리후생</div>
              <p>{data.job.benefits}</p>
            </div>
            <div>
              <div className="text-gray-500 my-1">지급 예정일</div>
              <p>{data.job.provision}</p>
            </div>
          </>
        )}
      </Section>
      <Divider height="h-3" />
      <Section>
        <div className="text-lg font-bold text-gray-700">근무 지역</div>
        <span className="text-gray-800">
          {data.job.company.address} {data.job.company.detailAddress}
        </span>
      </Section>
      <Section>
        <div className="flex space-x-2 items-center">
          {isDesktop() && (
            <Button
              variant="outlined"
              style={{ width: '100%' }}
              onClick={() => {
                const naver = (window as any).naver;
                history.push('/map');
                map.panTo(new naver.maps.LatLng(data.job.lat, data.job.lng));
              }}
            >
              위치 보기
            </Button>
          )}
          <Button
            style={{ width: '100%' }}
            onClick={() => {
              window.open(
                `http://maps.naver.com/?menu=location&mapMode=0&lat=${data.job.lat}&lng=${data.job.lng}&dlevel=14&pinTitle=${data.job.company.name}&pinType=SITE&enc=b64mapMode`,
              );
            }}
          >
            지도에서 보기
          </Button>
        </div>
      </Section>
      <Divider height="h-3" />
      <Section>
        <div className="text-lg font-bold text-gray-700">모집 요건</div>
        <div className="flex flex-wrap">
          <div className="flex items-center w-max my-1 flex-wrap">
            <JobRequirementsField
              label="인원"
              opening={`${data.job.opening}명`}
            />
            <JobRequirementsField label="성별" gender={data.job.gender} />
            <JobRequirementsField
              label="연령"
              age={data.job.age ? data.job.age : '무관'}
            />
            {data.job.albaType === AlbaType.NORMAL && (
              <>
                <JobRequirementsField
                  label="학력"
                  education={data.job.education ? data.job.education : '무관'}
                />
                <JobRequirementsField
                  label="경력"
                  qualification={
                    data.job.qualification ? data.job.qualification : '무관'
                  }
                />
                <JobRequirementsField
                  label="우대"
                  advantage={data.job.advantage ? data.job.advantage : '무관'}
                />
              </>
            )}
          </div>
        </div>
      </Section>
      <Divider height="h-3" />
      <Section>
        <div className="w-full flex items-center justify-between">
          <div>
            <div className="text-lg font-bold text-gray-700">
              {data.job.company.name}
            </div>
            <div className="text-sm text-gray-500 mr-2">
              {data.job.company.number}
            </div>
            <div className="text-sm text-gray-500 mr-2">
              {data.job.company.address} {data.job.company.detailAddress}
            </div>
          </div>
          <Button
            className="flex-shrink-0"
            onClick={() =>
              history.push(`/map/companies/${data.job.company.id}`)
            }
            style={{ flexShrink: 0 }}
          >
            상세보기
          </Button>
        </div>
      </Section>
      <Divider height="h-3" />
      {data.job.entranceUrl && (
        <div className="w-full px-4 pt-4 pb-10">
          <div className="text-lg font-bold text-gray-700">매장 입구</div>
          <div className="bg-black w-full h-[300px] flex justify-center items-center">
            <img
              src={data.job.entranceUrl}
              style={{ objectFit: 'contain', width: '100%', height: '300px' }}
              alt="매장 입구 사진"
            />
          </div>
        </div>
      )}
      <div className="w-full px-4 pt-4 pb-36">
        {data.job.thumbnail && (
          <>
            <div className="text-lg font-bold text-gray-700">상세 이미지</div>
            <div className="bg-black w-full h-[300px] flex justify-center items-center">
              <img
                src={data.job.thumbnail}
                style={{ objectFit: 'contain', width: '100%', height: '300px' }}
                alt="상세 이미지"
              />
            </div>
          </>
        )}

        {data.job.videoUrl && (
          <ReactPlayer
            url={data.job.videoUrl}
            className="react-player"
            playing
            width="100%"
            controls={false}
            style={{
              margin: '20px 0px',
              objectFit: 'cover',
            }}
            light
          />
        )}
      </div>
      <div
        className="w-full md:w-112 z-100 fixed bottom-0 bg-white"
        style={{ width: '-webkit-fill-available' }}
      >
        {data.job.company.userId === me?.id ? (
          <>
            <Button
              style={{ width: '50%' }}
              rounded="rounded-none"
              onClick={() => history.push(`/jobs/edit/${data.job.id}`)}
            >
              수정
            </Button>
            <Button
              style={{ width: '50%' }}
              variant="outlined"
              rounded="rounded-none"
              onClick={handleDeleted}
            >
              삭제
            </Button>
          </>
        ) : me?.role === Role.BUSINESS ? (
          ''
        ) : me?.role === Role.USER ? (
          <div className="w-full p-4 flex items-center space-x-4 min-w-max ">
            <div className="w-13 min-w-13 h-13 flex items-center justify-center bg-brand-1 bg-opacity-10 cursor-pointer rounded-lg">
              <JobLikeIcon job={data.job} />
            </div>
            <ApplyButton job={data.job} />
          </div>
        ) : (
          <div className="w-full p-4 flex items-center space-x-4 min-w-max">
            <div className="w-13 min-w-13 h-13 flex items-center justify-center bg-brand-1 bg-opacity-10 cursor-pointer rounded-lg">
              <HeartIcon stroke="#FFFFFF" onClick={() => handleSignUp()} />
            </div>
            <Button style={{ width: '100%' }} onClick={() => handleSignUp()}>
              온라인 지원하기
            </Button>
          </div>
        )}
      </div>
    </>
  );
};
