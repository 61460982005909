import { FC } from 'react';
import NewMainTopNavbar from '../components/NewMainTopNavbar';
import { ANNOUNCEMENT_LIST } from '../constants/announcements';
import NoticeCard from '../components/MainPage/NoticeCard';

interface AnnouncementPageProps {}

const AnnouncementPage: FC<AnnouncementPageProps> = ({}) => {
  return (
    <div>
      <NewMainTopNavbar />
      <div className="max-w-md md:mx-auto">
        <div className="w-full px-4 space-y-2">
          {ANNOUNCEMENT_LIST.map((item) => (
            <NoticeCard announcement={item} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default AnnouncementPage;
