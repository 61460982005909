import {
  Button,
  Section,
  TextField,
  TopNavbar,
  useMutation,
} from '@entropyparadox/reusable-react';
import React, { useState } from 'react';
import { resetNewPassword } from '../api';
import { useHistory } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import MobileHomeLogo from '../assets/png/woogugu_img.png';

interface matchParams {
  token: string;
}

const EmailChangePassword: React.FC<RouteComponentProps<matchParams>> = ({
  match,
}) => {
  const [newPassword, setNewPassword] = useState('');
  const [comfirmNewPassword, setComfirmNewPassword] = useState('');
  const history = useHistory();

  const [ResetNewPassword] = useMutation(resetNewPassword, {
    variables: {
      token: match.params.token,
      newPassword,
    },
    onCompleted: async () => {
      alert('비밀번호 변경이 완료되었습니다. 재로그인 해주세요.');
      history.push('/main');
    },
    onError: (err) => {
      alert(err.message);
    },
  });

  return (
    <>
      <div className="sm:hidden">
        <TopNavbar
          title="비밀번호 변경"
          left={
            <img
              src={MobileHomeLogo}
              alt=""
              className="w-16"
              onClick={() => history.push('/main')}
            />
          }
        />
      </div>
      <div className="hidden sm:block inset-0">
        <div className=" text-2xl mt-24 text-center">비밀번호 변경</div>
      </div>

      <div className="max-w-md mx-auto">
        <Section>
          <TextField
            type="password"
            label="*새로운 비밀번호"
            placeholder="비밀번호를 입력해주세요."
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
          <TextField
            type="password"
            label="*새로운 비밀번호 확인"
            placeholder="비밀번호를 재입력해주세요."
            value={comfirmNewPassword}
            onChange={(e) => setComfirmNewPassword(e.target.value)}
          />
          <div></div>
          <Button
            text="비밀번호 변경"
            style={{ marginTop: 20 }}
            onClick={() => ResetNewPassword()}
          ></Button>
        </Section>
      </div>
    </>
  );
};

export default EmailChangePassword;
