import React, { useState } from 'react';
import {
  Section,
  TextField,
  Button,
  useSetRecoilState,
  tokenState,
  useMutation,
} from '@entropyparadox/reusable-react';
import NewMainTopNavbar from '../components/NewMainTopNavbar';
import { useHistory } from 'react-router';
import { LOGIN } from '../api';

export const LoginPage: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const setToken = useSetRecoilState(tokenState);
  const history = useHistory();

  const [login] = useMutation(LOGIN, {
    variables: {
      email,
      password,
    },
    onCompleted: async ({ login }) => {
      if (login?.token) {
        await setToken(login?.token);
      }
      history.push('/main');
    },
    onError: async (error) => {
      if (error?.message === '로그인에 실패했습니다') {
        alert('이메일이나 비밀번호를 다시 확인해주세요.');
      }
      if (error?.message === '이미 탈퇴한 회원이거나 가입한 정보가 없습니다.') {
        alert('이미 탈퇴한 회원이거나 가입한 정보가 없습니다!');
      }
      if (error?.message === 'Unauthorized') {
        alert('이메일 혹은 비밀번호가 일치하지 않습니다!');
      }
    },
  });

  return (
    <>
      <NewMainTopNavbar hasMainButton />
      <div className="mt-8 max-w-md sm:mx-auto w-full">
        <Section>
          <div className="text-2xl font-bold text-gray-800">로그인</div>
          <TextField
            label="이메일"
            placeholder="이메일을 입력해주세요"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            label="비밀번호"
            placeholder="비밀번호를 입력해주세요"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <div className="w-full text-right pb-4">
            <div
              className="text-brand-1 cursor-pointer"
              onClick={() => history.push('/find-password')}
            >
              비밀번호 찾기
            </div>
          </div>
          <Button
            disabled={!email || !password}
            onClick={() => {
              if (
                !/^([\w\.\_\-])*[a-zA-Z0-9]+([\w\.\_\-])+@([a-zA-Z0-9]+\.)+[a-zA-Z0-9]{2,8}$/.test(
                  email,
                )
              ) {
                return alert('올바른 이메일 형식이 아닙니다.');
              }
              login();
            }}
          >
            로그인
          </Button>
          <Button variant="outlined" onClick={() => history.push('/signup')}>
            회원가입
          </Button>
        </Section>
      </div>
    </>
  );
};
