import { Button } from '@entropyparadox/reusable-react';
import { FC } from 'react';

interface RefundModalProps {
  onClose: () => void;
}

const RefundModal: FC<RefundModalProps> = ({ onClose }) => {
  return (
    <div
      className={`w-80 m-auto z-100 opacity-100 scale-100 duration-100 ease-in fixed top-1/4 inset-x-0 p-2 transition transform origin-top-right`}
    >
      <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
        <div className="pt-5 pb-6 px-5">
          <div className="flex items-center justify-between mb-4">
            <div className="font-bold text-base">취소/환불 규정 안내</div>

            <button
              type="button"
              className="bg-white rounded-md inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
              onClick={() => onClose()}
            >
              <span className="sr-only">Close menu</span>
              <svg
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinejoin="round"
                  strokeLinecap="round"
                  stroke-width="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <div className="w-full">
            <div className="text-sm text-left">
              <p>
                1. 구매일로부터 일주일 이내 환불을 요청하였으며, 이용권을
                이용하지 않은 경우 전액 환불 받을 수 있습니다.
                <br />
                <br />
                2. 이용권을 사용한 경우에는 사용한 횟수만큼 정상가로 차감되며
                차감된 나머지 금액을 환불해드리나 구매일로 일주일이 지난
                시점이라면 결제금액의 10% 위약금을 차감한 90%를 환불해드립니다.
                <br />
                <br />
                3. 계산된 환불 금액이 마이너스인 경우에는 환불 금액이 없는
                것으로 간주합니다.
                <br />
                <br />
                환불받고자 하는 회원은 고객센터로 환불을 요청해주셔야합니다.
              </p>
            </div>
            <div className="w-full mt-8">
              <Button
                text="확인"
                style={{ width: '100%' }}
                onClick={() => onClose()}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RefundModal;
