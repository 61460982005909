import { gql } from '@entropyparadox/reusable-react';
import { Apply } from '../types/applies';
import { Company } from '../types/company';
import { Job } from '../types/job';
import { Like } from '../types/likes';
import { Resume } from '../types/resumes';
import { Review } from '../types/reviews';
import { Tag } from '../types/tag';
import { User } from '../types/user';
import { TicketOrder } from '../types/ticketorders';
import { Ticket } from '../types/tickets';

export const ME = gql`
  query Me {
    me {
      id
      name
      email
      role
      birthday
      phone
      isActive
      isDeleted
      address
      detailAddress
      ticketNumber
      generalNotification
      generalPushNotification
      urgentlyPushNotification
      recommendPushNotification
      gender
      entranceUrl
      agree
      resume {
        id
      }
    }
  }
`;

export interface UserOneData {
  user: User;
}

export const userOne = gql`
  query ($id: Int!) {
    user(id: $id) {
      id
      name
      phone
      gender
      resume {
        id
        avatar
      }
    }
  }
`;

export const LOGIN = gql`
  mutation ($password: String!, $email: String!) {
    login(password: $password, email: $email) {
      token
    }
  }
`;

export const updateUser = gql`
  mutation updateUser(
    $phone: String
    $name: String
    $birthday: String
    $address: String
    $detailAddress: String
    $entranceUrl: Upload
    $agree: Boolean
  ) {
    updateUser(
      phone: $phone
      name: $name
      birthday: $birthday
      address: $address
      detailAddress: $detailAddress
      entranceUrl: $entranceUrl
      agree: $agree
    ) {
      id
    }
  }
`;

export const updateUserNotification = gql`
  mutation updateUserNotification(
    $generalNotification: Boolean
    $generalPushNotification: Boolean
    $urgentlyPushNotification: Boolean
    $recommendPushNotification: Boolean
  ) {
    updateUserNotification(
      generalNotification: $generalNotification
      generalPushNotification: $generalPushNotification
      urgentlyPushNotification: $urgentlyPushNotification
      recommendPushNotification: $recommendPushNotification
    ) {
      id
    }
  }
`;

export const CreatePhone = gql`
  mutation ($phone: Int!) {
    createPhone(phone: $phone) {
      id
    }
  }
`;

export const createApply = gql`
  mutation ($jobId: Int!) {
    createApply(jobId: $jobId) {
      id
    }
  }
`;

export interface ApplyMyData {
  applyMy: Apply;
}

export const ApplyMy = gql`
  query ($jobId: Int!) {
    applyMy(jobId: $jobId) {
      id
      userId
      status
    }
  }
`;

export const UpDatePhoneVerify = gql`
  mutation ($id: Int!, $code: String!) {
    upDatePhoneVerify(id: $id, code: $code) {
      id
    }
  }
`;

export const SignUpUser = gql`
  mutation (
    $email: String!
    $name: String!
    $rrn: String!
    $password: String!
    $phone: String!
    $agree: Boolean
    $address: String!
    $detailAddress: String!
  ) {
    signUpUser(
      email: $email
      name: $name
      rrn: $rrn
      password: $password
      phone: $phone
      agree: $agree
      address: $address
      detailAddress: $detailAddress
    ) {
      token
    }
  }
`;

export const SignUpBusiness = gql`
  mutation (
    $email: String!
    $name: String!
    $birthday: String!
    $password: String!
    $phone: String!
    $companyName: String!
    $address: String!
    $detailAddress: String!
    $url: String
    $number: String
    $introduction: String!
    $license: Upload!
    $agree: Boolean
    $entranceUrl: Upload
  ) {
    signUpBusiness(
      email: $email
      name: $name
      birthday: $birthday
      password: $password
      phone: $phone
      companyName: $companyName
      address: $address
      detailAddress: $detailAddress
      url: $url
      number: $number
      introduction: $introduction
      license: $license
      agree: $agree
      entranceUrl: $entranceUrl
    ) {
      token
    }
  }
`;

export const findJob = gql`
  mutation ($id: Int!) {
    findJob(id: $id) {
      id
    }
  }
`;

export interface JobsByTagAndBoundsData {
  jobsByTagAndBounds: Job[];
}

export const jobsByTagAndBounds = gql`
  query ($tagIds: String!, $bounds: String!) {
    jobsByTagAndBounds(tagIds: $tagIds, bounds: $bounds) {
      id
      title
      deadLine
      wage
      industry
      workDay
      payType
      taxType
      jobType
      lat
      lng
      detailWorkTime
      albaType
      company {
        id
        name
        address
        detailAddress
      }
    }
  }
`;

export interface MainData {
  urgentJobs: Job[];
}

export const urgentJobQuery = gql`
  query {
    urgentJobs {
      id
      entranceUrl
      title
      wage
      payType
      company {
        id
        name
        address
        detailAddress
      }
    }
  }
`;

export interface HomeData {
  jobsByTagAndBounds: Job[];
  urgentJobs: Job[];
  tags: Tag[];
}

export const homeQuery = gql`
  query ($tagIds: String!, $bounds: String!, $filter: String) {
    jobsByTagAndBounds(tagIds: $tagIds, bounds: $bounds, filter: $filter) {
      id
      title
      deadLine
      wage
      industry
      workDay
      payType
      taxType
      jobType
      lat
      lng
      detailWorkTime
      albaType
      entranceUrl
      company {
        id
        name
        address
        detailAddress
      }
    }
    urgentJobs {
      id
      entranceUrl
      title
      wage
      payType
      company {
        id
        name
      }
    }
    tags {
      id
      title
      status
    }
  }
`;

export interface CompaniesData {
  businessUserGetMyCompany: Company[];
}

export const MyCompanies = gql`
  query {
    businessUserGetMyCompany {
      id
      name
      address
      detailAddress
      jobs {
        id
        companyId
      }
    }
  }
`;

export interface CompanyData {
  companyOne: Company;
}

export const companyOne = gql`
  query ($companyId: Int!) {
    companyOne(companyId: $companyId) {
      id
      name
      address
      detailAddress
      url
      license
      introduction
      number
    }
  }
`;

export interface TagsData {
  tags: Tag[];
}

export const TAGS = gql`
  query {
    tags {
      id
      title
      status
    }
  }
`;

export interface MyJobsData {
  myJobs: Job[];
  me: User;
}

export const MyJobs = gql`
  query {
    myJobs {
      id
      title
      deadLine
      wage
      payType
      taxType
      jobType
      albaType
      processType
      company {
        id
        userId
      }
    }
    me {
      isActive
      ticketNumber
    }
  }
`;

export interface JobsData {
  jobs: Job[];
}

export const Jobs = gql`
  query {
    jobs {
      id
      title
      deadLine
      wage
      industry
      workDay
      payType
      taxType
      jobType
      lat
      lng
      albaType
      company {
        id
        name
        address
        detailAddress
      }
    }
  }
`;

export interface AppliesJobData {
  applyJobs: Apply[];
}

export const applyJobs = gql`
  query {
    applyJobs {
      id
      status
      job {
        id
        title
        deadLine
        wage
        payType
        taxType
        jobType
        albaType
        processType
        company {
          id
          userId
        }
      }
    }
  }
`;

export interface ApplyUsersData {
  applyUsers: Apply[];
}

export const applyUsers = gql`
  query ($jobId: Int!) {
    applyUsers(jobId: $jobId) {
      id
      status
      user {
        id
        name
        gender
        phone
      }
      job {
        id
        title
        summery
      }
      resume {
        id
        avatar
      }
    }
  }
`;

export interface ApplyUserData {
  applyUser: Apply;
}

export const applyUser = gql`
  query ($applyId: Int!) {
    applyUser(applyId: $applyId) {
      id
      status
      user {
        id
        name
        gender
        phone
        birthday
        address
        detailAddress
      }
      job {
        id
        title
        summery
      }
    }
  }
`;

export interface JobData {
  job: Job;
}

export const JOB = gql`
  query ($id: Int!) {
    job(id: $id) {
      id
      title
      deadLine
      wage
      industry
      workingDay
      workTime
      workDay
      payType
      taxType
      jobType
      workPeriod
      detailWorkTime
      benefits
      opening
      gender
      qualification
      education
      age
      advantage
      summery
      lat
      lng
      provision
      albaType
      thumbnail
      entranceUrl
      videoUrl
      processType
      company {
        id
        name
        address
        userId
        detailAddress
        url
        number
      }
      user {
        id
        phone
      }
    }
  }
`;

export interface CompaniesJobsData {
  companiesJobs: Job[];
}

export const companiesJobs = gql`
  query ($id: Int!) {
    companiesJobs(id: $id) {
      id
      title
      deadLine
      wage
      industry
      workDay
      payType
      taxType
      jobType
      albaType
      processType
      company {
        id
        userId
        name
        address
        detailAddress
        url
        number
      }
    }
  }
`;

export enum AlbaType {
  NORMAL = 'NORMAL',
  URGENTLY = 'URGENTLY',
}

export const CreatedJob = gql`
  mutation (
    $title: String!
    $albaType: AlbaType!
    $companyId: Int!
    $summery: String!
    $industry: [Int!]!
    $deadLine: String!
    $workingDay: String
    $workPeriod: Int
    $workTime: [Int!]
    $detailWorkTime: String!
    $workDay: [Int!]
    $payType: Int!
    $taxType: Int
    $wage: Int
    $benefits: String
    $opening: String!
    $education: String
    $gender: String!
    $qualification: String
    $age: String!
    $advantage: String
    $provision: String
    $jobType: Int
    $videoUrl: String
    $thumbnail: Upload
  ) {
    createJob(
      title: $title
      companyId: $companyId
      summery: $summery
      industry: $industry
      jobType: $jobType
      deadLine: $deadLine
      workingDay: $workingDay
      workPeriod: $workPeriod
      workTime: $workTime
      detailWorkTime: $detailWorkTime
      workDay: $workDay
      payType: $payType
      taxType: $taxType
      wage: $wage
      benefits: $benefits
      opening: $opening
      education: $education
      gender: $gender
      qualification: $qualification
      age: $age
      advantage: $advantage
      provision: $provision
      albaType: $albaType
      thumbnail: $thumbnail
      videoUrl: $videoUrl
    ) {
      title
    }
  }
`;

export const createResume = gql`
  mutation (
    $avatar: Upload!
    $healthCertificate: Upload
    $consentForm: Upload
    $bank: String!
    $bankAccount: String!
    $career: String!
    $introduction: String!
  ) {
    createResume(
      avatar: $avatar
      healthCertificate: $healthCertificate
      consentForm: $consentForm
      bank: $bank
      bankAccount: $bankAccount
      career: $career
      introduction: $introduction
    ) {
      id
    }
  }
`;

export const updateResume = gql`
  mutation (
    $avatar: Upload
    $healthCertificate: Upload
    $consentForm: Upload
    $bank: String
    $bankAccount: String
    $career: String
    $introduction: String
  ) {
    updateResume(
      avatar: $avatar
      healthCertificate: $healthCertificate
      consentForm: $consentForm
      bank: $bank
      bankAccount: $bankAccount
      career: $career
      introduction: $introduction
    ) {
      id
    }
  }
`;

export interface ResumeMeData {
  resumeMe: Resume;
}

export const resumeMe = gql`
  query {
    resumeMe {
      id
      avatar
      healthCertificate
      consentForm
      bank
      bankAccount
      career
      introduction
    }
  }
`;

export interface ResumeMeByUserIdData {
  resumeMeByUserId: Resume;
}

export const resumeMeByUserId = gql`
  query ($userId: Int!) {
    resumeMeByUserId(userId: $userId) {
      id
      avatar
      healthCertificate
      consentForm
      bank
      bankAccount
      career
      introduction
      user {
        id
        name
        birthday
        phone
        gender
        address
        detailAddress
      }
    }
  }
`;

export const updateApply = gql`
  mutation ($applyId: Int!) {
    updateApply(applyId: $applyId) {
      id
    }
  }
`;

export const updateApplyCancel = gql`
  mutation ($applyId: Int!) {
    updateApplyCancel(applyId: $applyId) {
      id
    }
  }
`;

export const DeletedJob = gql`
  mutation ($id: Int!) {
    deleteJob(id: $id) {
      id
    }
  }
`;

export const UpdateJob = gql`
  mutation (
    $id: Int!
    $title: String!
    $albaType: AlbaType!
    $companyId: Int!
    $summery: String!
    $industry: [Int!]!
    $jobType: Int
    $deadLine: String!
    $workingDay: String
    $workPeriod: Int
    $workTime: [Int!]
    $detailWorkTime: String!
    $workDay: [Int!]
    $payType: Int!
    $taxType: Int
    $wage: Int
    $benefits: String
    $opening: String!
    $education: String
    $gender: String!
    $qualification: String
    $age: String!
    $advantage: String
    $provision: String
    $thumbnail: Upload
    $videoUrl: String
  ) {
    updateJob(
      id: $id
      title: $title
      companyId: $companyId
      thumbnail: $thumbnail
      videoUrl: $videoUrl
      summery: $summery
      industry: $industry
      jobType: $jobType
      deadLine: $deadLine
      workingDay: $workingDay
      workPeriod: $workPeriod
      workTime: $workTime
      detailWorkTime: $detailWorkTime
      workDay: $workDay
      payType: $payType
      taxType: $taxType
      wage: $wage
      benefits: $benefits
      opening: $opening
      education: $education
      gender: $gender
      qualification: $qualification
      age: $age
      advantage: $advantage
      provision: $provision
      albaType: $albaType
    ) {
      id
    }
  }
`;

export const updateCompany = gql`
  mutation (
    $id: Int!
    $address: String
    $detailAddress: String
    $url: String
    $introduction: String
    $number: String
  ) {
    updateCompany(
      id: $id
      address: $address
      detailAddress: $detailAddress
      url: $url
      introduction: $introduction
      number: $number
    ) {
      id
    }
  }
`;

export const createLike = gql`
  mutation ($jobId: Int!) {
    createLike(jobId: $jobId) {
      id
    }
  }
`;

export const deleteLike = gql`
  mutation ($jobId: Int!) {
    deleteLike(jobId: $jobId) {
      id
    }
  }
`;

export interface LikeData {
  likeByAdIdUserId: Like;
}

export const likeByAdIdUserId = gql`
  query ($jobId: Int!) {
    likeByAdIdUserId(jobId: $jobId) {
      id
      jobId
    }
  }
`;

export interface LikesData {
  likesByUserId: Like[];
}

export const likesByUserId = gql`
  query {
    likesByUserId {
      id
      jobId
      job {
        id
        title
        summery
        industry
        jobType
        deadLine
        detailWorkTime
        workDay
        payType
        taxType
        wage
        company {
          id
          address
        }
      }
    }
  }
`;

export const changedPassword = gql`
  mutation (
    $oldPassword: String!
    $newPassword: String!
    $newPasswordConfirmation: String!
  ) {
    changedPassword(
      oldPassword: $oldPassword
      newPassword: $newPassword
      newPasswordConfirmation: $newPasswordConfirmation
    ) {
      id
    }
  }
`;

export const createReview = gql`
  mutation ($employeeId: Int!, $rate: Int!, $content: String!) {
    createReview(employeeId: $employeeId, rate: $rate, content: $content) {
      id
    }
  }
`;

export interface EmployeeReviewData {
  employeeReview: Review[];
}

export const employeeReview = gql`
  query ($employeeId: Int!) {
    employeeReview(employeeId: $employeeId) {
      id
      content
      rate
      createdAt
      employee {
        id
        name
      }
      employer {
        id
        name
      }
    }
  }
`;

export interface EmployerReviewData {
  employerReview: Review;
}

export const employerReview = gql`
  query ($employeeId: Int!) {
    employerReview(employeeId: $employeeId) {
      id
      employee {
        id
        name
      }
      employer {
        id
        name
      }
    }
  }
`;

export const findPassword = gql`
  mutation ($email: String!) {
    findPassword(email: $email) {
      id
    }
  }
`;

export const resetNewPassword = gql`
  mutation ($token: String!, $newPassword: String!) {
    resetNewPassword(token: $token, newPassword: $newPassword) {
      id
    }
  }
`;

export interface MyRecommendData {
  me: User;
  tags: Tag[];
}

export const MyRecommend = gql`
  query {
    me {
      recommends {
        id
        sido
        sigungu
        industry
        jobType
        detailWorkTime
        workDay
        lat
        lng
      }
    }
    tags {
      id
      title
      status
    }
  }
`;

export const CreateRecommend = gql`
  mutation (
    $sido: String!
    $sigungu: String!
    $industry: [Int!]!
    $jobType: [Int!]!
    $detailWorkTime: String!
    $workDay: [Int!]!
  ) {
    createRecommend(
      sido: $sido
      sigungu: $sigungu
      industry: $industry
      jobType: $jobType
      detailWorkTime: $detailWorkTime
      workDay: $workDay
    ) {
      id
    }
  }
`;

export const UpdateRecommend = gql`
  mutation (
    $sido: String!
    $sigungu: String!
    $industry: [Int!]!
    $jobType: [Int!]!
    $detailWorkTime: String!
    $workDay: [Int!]!
  ) {
    updateRecommend(
      sido: $sido
      sigungu: $sigungu
      industry: $industry
      jobType: $jobType
      detailWorkTime: $detailWorkTime
      workDay: $workDay
    ) {
      id
    }
  }
`;

export interface GetTicketInfoData {
  me: User;
  ticketOrders: TicketOrder[];
  myTicketLogs: Ticket[];
}

export const GetTicketInfo = gql`
  query {
    me {
      ticketNumber
    }
    ticketOrders {
      id
      ticketNumber
      price
      discountPrice
    }
    myTicketLogs {
      id
      ticketType
      price
      changedTicketNumber
      totalTicketNumber
      reason
      createdAt
    }
  }
`;

export const StartPayment = gql`
  mutation ($ticketOrderId: Int!) {
    startPayment(ticketOrderId: $ticketOrderId) {
      id
      merchantUid
    }
  }
`;

export const CompletePayment = gql`
  mutation ($ticketOrderId: Int!, $impUid: String!) {
    completePayment(ticketOrderId: $ticketOrderId, impUid: $impUid) {
      id
    }
  }
`;
