import {
  Avatar,
  LoadingScreen,
  useIdParam,
  useQuery,
} from '@entropyparadox/reusable-react';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { applyUsers, ApplyUsersData } from '../api';
import { StatusApply } from '../types/applies';
import { Gender } from '../types/user';

export const UserApplyCard = () => {
  const id = useIdParam();
  const history = useHistory();

  const { loading, error, data } = useQuery<ApplyUsersData>(applyUsers, {
    variables: { jobId: id },
  });

  if (loading) return <LoadingScreen />;
  if (error || !data) return null;
  const jobs = data.applyUsers.slice().sort((a, b) => b.id - a.id) || [];

  return (
    <>
      {jobs.length > 0 ? (
        jobs.map((applyUser) => {
          return (
            <div
              className={`py-4 flex items-center justify-between w-full max-w-md`}
              onClick={() =>
                history.push(`/notice/${id}/applicants/${applyUser.id}`)
              }
            >
              <div className="flex items-center space-x-3">
                {applyUser.resume.length > 0 && (
                  <Avatar
                    src={
                      applyUser?.resume[applyUser?.resume?.length - 1].avatar
                    }
                    size={14}
                  />
                )}

                <div>
                  <div className="flex space-x-3 items-center">
                    <p className="text-sm font-bold text-gray-900">
                      {applyUser?.user?.name}
                    </p>
                    <div className="rounded-3xl px-3 py-1 text-xs font-bold text-white bg-brand-1 opacity-50">
                      {applyUser?.user?.gender === Gender.MAN
                        ? '남자'
                        : applyUser?.user?.gender === Gender.WOMAN
                        ? '여자'
                        : '-'}
                    </div>
                  </div>
                  <p className="text-sm text-gray-500 mt-1">
                    {applyUser?.user?.phone?.replace(
                      /(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/,
                      '$1-$2-$3',
                    )}
                  </p>
                </div>
              </div>
              {applyUser.status === StatusApply.SUCCESS && (
                <div className="rounded-3xl px-3 py-1 text-sm font-bold text-white bg-gray-700">
                  채용완료
                </div>
              )}
              {applyUser.status === StatusApply.PENDING && (
                <div className="rounded-3xl px-3 py-1 text-sm font-bold text-gray-700 bg-gray-200">
                  채용대기
                </div>
              )}
              {applyUser.status === StatusApply.CANCEL && (
                <div className="rounded-3xl px-3 py-1 text-sm font-bold text-gray-700 bg-gray-200">
                  불합격
                </div>
              )}
            </div>
          );
        })
      ) : (
        <div className="flex justify-center items-center text-gray-500">
          아직 지원한 사람이 없습니다.
        </div>
      )}
    </>
  );
};
