import {
  Avatar,
  Button,
  Divider,
  Label,
  LoadingScreen,
  Section,
  TextArea,
  useIdParam,
  useMutation,
  useQuery,
} from '@entropyparadox/reusable-react';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { createReview, userOne, UserOneData } from '../api';
import NewMainTopNavbar from '../components/NewMainTopNavbar';
import Stars from '../components/Stars';
import { Textarea } from '../components/Textarea';
import { Gender } from '../types/user';

const EvaluatePage = () => {
  const id = useIdParam();
  const history = useHistory();
  const [rating, setRating] = useState(0);
  const [content, setContent] = useState('');
  const [hoverRating, setHoverRating] = useState(0);

  const { loading, error, data } = useQuery<UserOneData>(userOne, {
    variables: {
      id,
    },
  });

  const handleConfirm = () => {
    // eslint-disable-next-line no-restricted-globals
    const con_test = confirm(
      '등록 후에는 수정이 불가하오니 신중하게 작성바랍니다.',
    );
    if (con_test) {
      CreateReview();
    }
  };

  const [CreateReview] = useMutation(createReview, {
    variables: { employeeId: id, rate: rating, content },
    onCompleted: () => {
      alert('등록이 완료되었습니다.');
      history.go(-1);
    },
  });

  const onMouseEnter = (index: number) => setHoverRating(index);
  // 마우스가 별 위에 올라가면 스테이트를 변경.
  const onMouseLeave = () => setHoverRating(0);
  // 마우스가 별 밖으로 나가면 스테이트를 0으로 변경.
  const onSaveRating = (index: number) => setRating(index);
  // 클릭시, 별 인덱스를 스테이트에 저장.

  if (loading) return <LoadingScreen />;
  if (error || !data) return <></>;

  return (
    <>
      <NewMainTopNavbar maintitle="평가하기" />
      <div className="max-w-md mx-auto md:mt-8">
        <Section>
          <div className="hidden sm:block text-2xl text-center font-semibold">
            평가하기
          </div>
          <div className="flex items-center space-x-3 pb-4">
            <Avatar src={data.user.resume?.avatar} size={14} />
            <div>
              <div className="flex space-x-3 items-center">
                <p className="text-sm font-bold text-gray-900">
                  {data.user.name}
                </p>
                <div className="rounded-3xl px-3 py-1 text-xs font-bold text-white bg-brand-1 opacity-50">
                  {data.user.gender === Gender.MAN
                    ? '남자'
                    : data.user.gender === Gender.WOMAN
                    ? '여자'
                    : '-'}
                </div>
              </div>
              <p className="text-sm text-gray-500 mt-1">
                {data.user.phone.replace(
                  /(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/,
                  '$1-$2-$3',
                )}
              </p>
            </div>
          </div>
          <Divider />
          <div>
            <Label text="별점" />
            <div className="flex space-x-1">
              {[1, 2, 3, 4, 5].map((idx) => {
                return (
                  <Stars
                    index={idx}
                    rating={rating}
                    hoverRating={hoverRating}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                    onSaveRating={onSaveRating}
                  />
                );
              })}
            </div>
          </div>
          <div>
            <TextArea
              label="평가 내용"
              placeholder="지원자에 대한 솔직한 평가를 작성해주세요. 평가는 업장유저에게만 노출되며 지원당사자는 볼 수 없습니다."
              style={{ height: '120px' }}
              value={content}
              onChange={(e) => setContent(e.target.value)}
            />
          </div>
          <Button
            text="등록하기"
            disabled={!rating || !content}
            onClick={() => handleConfirm()}
          />
        </Section>
      </div>
    </>
  );
};

export default EvaluatePage;
