import { TextareaHTMLAttributes, forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';
import { Label } from './Label';

interface TextareaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  label?: string;
  required?: boolean;
  className?: string;
  helper?: string;
  [key: string]: any;
}

export const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ label, required, className, helper, ...props }, ref) => {
    return (
      <div>
        {label && <Label required={required}>{label}</Label>}
        <div
          className={`p-2 rounded-lg w-full border-2 my-1 focus-within:border-brand-1 ${className}`}
        >
          <textarea
            ref={ref}
            className={twMerge(
              'outline-none ring-0 focus:ring-0 border-none focus:border-none w-full bg-transparent flex items-center focus:bg-transparent placeholder-gray-4 resize-none overflow-auto',
              className,
            )}
            {...props}
          />
        </div>
        {helper && <div className="mt-1 text-red-500">{helper}</div>}
      </div>
    );
  },
);
