import {
  Button,
  useAuth,
  useLogout,
  useRecoilValue,
} from '@entropyparadox/reusable-react';
import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { meState } from '../store';
import { Role } from '../types/user';

import NewLogoSVG from '../assets/svg/new-logo.svg';
import ArrowLeft from '../assets/svg/chevron-left.svg';

interface NewMainTopNavbarProps {
  subtitle?: string;
  onlytitle?: string;
  maintitle?: string;
  back?: boolean;
  hasMainButton?: boolean;
}

const NewMainTopNavbar: React.FC<NewMainTopNavbarProps> = ({
  subtitle,
  onlytitle,
  maintitle,
  back = false,
  hasMainButton = false,
}) => {
  const history = useHistory();
  const { authenticated } = useAuth();
  const logout = useLogout();
  const me = useRecoilValue(meState);
  const handleLogOut = () => {
    logout();
    history.push('/main');
  };

  return (
    <div>
      <div
        className={`flex z-20 fixed top-0 w-full justify-between items-center text-center p-5 text-gray-1 text-md bg-white`}
      >
        <div className="flex-grow flex justify-start items-center flex-row">
          {back ? (
            <ArrowLeft />
          ) : (
            <Link to="/main">
              <div className="flex items-center space-x-2">
                <img
                  src={NewLogoSVG}
                  alt=""
                  className="w-8 h-8 z-10"
                  onClick={() => history.push('/main')}
                />
              </div>
            </Link>
          )}
        </div>
        <div className="hidden md:block">
          <div className="flex-grow flex flex-col justify-end items-end space-y-3">
            <div className="flex">
              {authenticated ? (
                <>
                  {me?.role === Role.BUSINESS && (
                    <div className="flex items-center space-x-8">
                      <div
                        className="w-max text-[14px] lg:text-base mr-3 cursor-pointer"
                        onClick={() => history.push('/map')}
                      >
                        지도
                      </div>
                      <div
                        className="w-max text-[14px] lg:text-base mr-3 cursor-pointer"
                        onClick={() => history.push('/company')}
                      >
                        공고관리
                      </div>
                      <div
                        className="w-max text-[14px] lg:text-base mr-3 cursor-pointer"
                        onClick={() => history.push('/company')}
                      >
                        마이페이지
                      </div>
                      <div
                        className="w-max text-[14px] lg:text-base cursor-pointer"
                        onClick={() => handleLogOut()}
                      >
                        로그아웃
                      </div>
                    </div>
                  )}
                  {me?.role === Role.USER && (
                    <div className="flex space-x-4">
                      <Link to="/map">
                        <div className="w-max text-[14px] lg:text-base mr-3 cursor-pointer">
                          지도
                        </div>
                      </Link>
                      <Link to="/like">
                        <div className="w-max text-[14px] lg:text-base mr-3 cursor-pointer">
                          관심공고
                        </div>
                      </Link>
                      <Link to="/recommend">
                        <div className="w-max text-[14px] lg:text-base mr-3 cursor-pointer">
                          맞춤알바설정
                        </div>
                      </Link>
                      <Link to="/mypage">
                        <div className="w-max text-[14px] lg:text-base mr-3 cursor-pointer">
                          마이페이지
                        </div>
                      </Link>
                    </div>
                  )}
                </>
              ) : (
                <div className="flex items-center space-x-8">
                  <Link to="/map">지도</Link>
                  <div
                    className="text-md mr-3 cursor-pointer"
                    onClick={() => history.push('/login')}
                  >
                    로그인
                  </div>
                  <Button
                    to="/signup"
                    height="h-11"
                    className="text-md font-normal"
                  >
                    회원가입
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
        {hasMainButton && (
          <Link to="/main" className="block md:hidden">
            메인으로
          </Link>
        )}
      </div>
      <div className="w-full h-18"></div>
    </div>
  );
};

export default NewMainTopNavbar;
