import { FC } from 'react';
import { Tag } from '../types/tag';

interface RecommendItemProps {
  tag: Tag;
  arrayState: number[];
  setArrayState: (arr: number[]) => void;
}

export const RecommendItem: FC<RecommendItemProps> = ({
  tag,
  arrayState,
  setArrayState,
}) => {
  return (
    <div
      className={`cursor-pointer text-center text-sm md:text-base inline-block px-4 py-2 mr-2 my-2 rounded-full whitespace-pre ${
        arrayState.includes(tag.id) ? 'bg-brand-1 text-white' : 'bg-gray-100'
      }`}
      onClick={() =>
        arrayState.includes(tag.id)
          ? setArrayState(arrayState.filter((el) => el !== tag.id))
          : setArrayState(arrayState.concat(tag.id))
      }
    >
      {tag.title}
    </div>
  );
};
