import {
  ListItem,
  LoadingScreen,
  useQuery,
  useRecoilValue,
  utcToLocalFormat,
} from '@entropyparadox/reusable-react';
import { ReactComponent as HeartIcon } from '../assets/svg/heart-icon.svg';
import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { AlbaType, Job } from '../types/job';
import { TAGS, TagsData } from '../api';
import { Tag } from '../types/tag';
import { meState } from '../store';
import { Role } from '../types/user';
import JobLikeIcon from './JobLikeIcon';

interface JobListItemProps {
  job: Job;
}

export const JobListItem: FC<JobListItemProps> = ({ job }) => {
  const history = useHistory();
  const me = useRecoilValue(meState);

  const { loading, error, data } = useQuery<TagsData>(TAGS);

  const handleSignUp = () => {
    alert('로그인이 필요합니다.');
    history.push('/login');
  };

  const now = new Date();
  now.setHours(0, 0, 0, 0);

  const deadLine = new Date(job.deadLine);
  deadLine.setHours(0, 0, 0, 0);

  if (loading) return <LoadingScreen />;
  if (error || !data) return <></>;

  const tagJobType = data.tags.filter((tag) => tag.id === job.jobType);
  const tagPayType = data.tags.filter((tag) => tag.id === job.payType);

  return (
    <ListItem>
      <div className="flex flex-col items-start text-left">
        <div className="flex justify-between w-full">
          <div
            className={`flex justify-between space-x-2 items-center mb-2 ${
              deadLine < now && ' opacity-30'
            }`}
          >
            <div
              className={` ${
                job.albaType === AlbaType.NORMAL
                  ? 'bg-darkgreen-5 text-darkgreen-1'
                  : 'bg-brand-5 text-brand-6'
              } rounded-3xl py-1 text-sm font-bold px-3`}
            >
              {job.albaType === AlbaType.NORMAL
                ? tagJobType[0].title
                : '번쩍 알바⚡'}
            </div>
            <div className="text-gray-500">{job.company.name}</div>
          </div>
          {me?.role === Role.USER ? (
            <JobLikeIcon job={job} />
          ) : (
            <>{!me ? <HeartIcon onClick={() => handleSignUp()} /> : ''}</>
          )}
        </div>
        <div
          onClick={() => history.push(`/map/jobs/${job.id}`)}
          className={`${deadLine < now && ' opacity-30'}`}
        >
          <div className="mb-3">{job.title}</div>
          {generateJobDetail(data.tags, job)}

          <div className="text-gray-600 ">{job.company.address}</div>
        </div>
        <div className="flex self-end space-x-2 items-center mt-2">
          {tagPayType.map((tagJob) => (
            <div
              key={tagJob.id}
              className={`${
                tagJob.title === '시급'
                  ? 'border-brand-7 text-brand-7'
                  : tagJob.title === '월급'
                  ? 'border-green-500 text-green-500'
                  : ''
              } px-2 py-1 border-2 font-bold text-xs`}
            >
              <p>{tagJob.title}</p>
            </div>
          ))}
          <div className="text-lg font-bold text-gray-800">
            {String(job.wage).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원
          </div>
        </div>
      </div>
    </ListItem>
  );
};

const generateJobDetail = (tags: Tag[], job: Job) => {
  const industry = job.industry
    .map(
      (industryId) => tags.filter((tag) => tag.id === industryId)?.[0]?.title,
    )
    .join(', ');

  const workDay = job.workDay
    .map((workDayId) => tags.filter((tag) => tag.id === workDayId)?.[0]?.title)
    .join(', ');

  return (
    <div className="text-gray-600 space-x-2">
      <span>{industry}</span>
      {job.albaType === AlbaType.NORMAL && (
        <span>
          {`• `}
          {workDay}
        </span>
      )}
      {job.albaType === AlbaType.URGENTLY && (
        <span>
          {`• `}
          {utcToLocalFormat(
            job.workingDay ? job.workingDay : job.deadLine,
            'MM/DD일 (dd)',
          )}
        </span>
      )}
      {` •`} {job.detailWorkTime}
    </div>
  );
};
