import { TicketType } from '../types/tickets';

export const TICKET_TYPE_PREFIX = {
  [TicketType.ADD]: '+',
  [TicketType.SUBSTRACT]: '-',
};

export const TICKET_TYPE_CATEGORY = {
  [TicketType.ADD]: '충전',
  [TicketType.SUBSTRACT]: '사용',
};
