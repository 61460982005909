import {
  BackButton,
  Button,
  Section,
  TopNavbar,
  LoadingScreen,
  useQuery,
  useMutation,
  useRecoilValue,
} from '@entropyparadox/reusable-react';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import NewMainTopNavbar from '../components/NewMainTopNavbar';
import CompaniesSelectField from '../components/CompaniesSelectField';
import { CreatedJob, TAGS, TagsData } from '../api';
import { SeletedTagField } from '../components/SeletedTagField';
import { useForm } from 'react-hook-form';
//@ts-ignore
import { meState } from '../store';
import './index.css';
import { AlbaType } from '../types/job';
import { ReactComponent as FileUploadImg } from '../assets/svg/file-upload.svg';
import { format } from 'date-fns';
import { yupResolver } from '@hookform/resolvers/yup';
import { Select } from '../components/Select';
import { TextField } from '../components/TextField';
import { Textarea } from '../components/Textarea';
import { JobSchema } from '../schema/job.schema';
import { Label } from '../components/Label';

const getMinute = (minute: number) => {
  if (String(minute).length >= 2) {
    return minute;
  }

  if (String(minute).length === 1) {
    return '0' + minute;
  }

  return '00';
};

const AddJobPage = () => {
  const history = useHistory();
  const me = useRecoilValue(meState);

  const [thumbnail, setThumbnail] = useState<File | null | undefined>();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    mode: 'onChange',
    //@ts-ignore
    resolver: yupResolver(JobSchema),
  });
  const watchAlbaType = watch('albaType');

  const { loading, error, data } = useQuery<TagsData>(TAGS);

  const [createdJob] = useMutation(CreatedJob, {
    onCompleted: () => {
      alert(
        `공고 승인요청이 완료되었습니다. 
공고가 승인되면 알림톡으로 안내드립니다.`,
      );
      history.push('/company');
    },
    onError: (err) => {
      alert('공고 승인요청 중 오류가 발생했습니다. 잠시 후 다시 시도해주세요');
      console.log(err);
    },
  });

  useEffect(() => {
    reset({ albaType: AlbaType.NORMAL });
  }, []);

  if (loading) return <LoadingScreen />;
  if (error || !data || !me) return <></>;
  if (me.isActive !== true) {
    history.push('/main');
  }

  return (
    <>
      <NewMainTopNavbar />
      <div className="max-w-md mx-auto">
        <div className="sm:hidden">
          <TopNavbar title="공고 등록하기" left={<BackButton />} />
        </div>
        <div className="hidden sm:block inset-0">
          <div className=" text-2xl mt-4 md:mt-8 text-center">
            공고 등록하기
          </div>
        </div>

        <Section>
          <div className="space-y-3">
            <div className="text-lg font-semibold pt-4 mb-3">공고 유형</div>
            <button
              className={`${
                watchAlbaType === AlbaType.NORMAL && 'border-2 border-brand-1'
              } w-full bg-brand-4 py-5 px-4 text-left space-y-2 rounded`}
              onClick={() => {
                setValue('albaType', AlbaType.NORMAL);
                setValue('title', '');
              }}
            >
              <p className="text-base font-bold text-gray-800">일반 공고</p>
              <p className="text-2sm text-gray-600">
                일반적으로 구인을 할때 사용하는 공고입니다.
              </p>
            </button>
            <button
              className={`${
                watchAlbaType === AlbaType.URGENTLY && 'border-2 border-brand-1'
              } w-full bg-brand-4 py-5 px-4 text-left space-y-2 rounded`}
              onClick={() => {
                setValue('albaType', AlbaType.URGENTLY);
                setValue('title', '번쩍알바');
              }}
            >
              <p className="text-base font-bold text-gray-800">번쩍 공고⚡️</p>
              <p className="text-2sm text-gray-600">
                번쩍알바는 당일에 급하게 알바가 필요할 경우 사용하는 공고입니다.
              </p>
            </button>
          </div>

          <CompaniesSelectField
            {...register('companyId', { valueAsNumber: true })}
            helper={errors.companyId?.message}
          />

          <TextField
            required
            label="공고 제목"
            placeholder="공고 제목을 입력해주세요."
            {...register('title')}
            helper={errors.title?.message}
          />

          <SeletedTagField
            required
            multiple
            label="업· 직종"
            tags={data.tags}
            type="INDUSTRY"
            state={watch('industry')}
            setState={(value) => setValue('industry', value)}
            helper={errors.industry?.message?.toString()}
          />

          {watchAlbaType === AlbaType.NORMAL && (
            <SeletedTagField
              required
              label="근무 형태"
              tags={data.tags}
              type="JOBTYPE"
              state={watch('jobType')}
              setState={(value) => setValue('jobType', Number(value))}
              helper={errors.jobType?.message?.toString()}
            />
          )}

          <div>
            <Label required>근무 날짜</Label>
            <div className="flex items-center space-x-2">
              <input
                type="date"
                className="block px-4 w-full h-12 border
                 border-gray-200 rounded-md sm:text-sm
                 focus:ring-0 focus:border-brand-1 placeholder-gray-400
                 disabled:bg-gray-100 disabled:text-gray-400"
                {...register('startWorkingDay')}
              />
              <input
                type="date"
                className="block px-4 w-full h-12 border
                 border-gray-200 rounded-md sm:text-sm
                 focus:ring-0 focus:border-brand-1 placeholder-gray-400
                 disabled:bg-gray-100 disabled:text-gray-400"
                {...register('endWorkingDay')}
              />
            </div>
            {errors.startWorkingDay?.message && (
              <div className="mt-1 text-red-500">
                {errors.startWorkingDay?.message}
              </div>
            )}
            {errors.endWorkingDay?.message && (
              <div className="mt-1 text-red-500">
                {errors.endWorkingDay?.message}
              </div>
            )}
          </div>

          {watchAlbaType === AlbaType.NORMAL && (
            <SeletedTagField
              required
              multiple
              label="근무 요일"
              tags={data.tags}
              type="WORKDAY"
              state={watch('workDay')}
              setState={(value) => setValue('workDay', value)}
              helper={errors.workDay?.message?.toString()}
            />
          )}

          <Label required>상세 근무</Label>
          <div className="flex w-full space-x-2">
            <div className="flex flex-col w-full">
              <Select
                {...register('startWorkTime', {
                  valueAsNumber: true,
                })}
              >
                <option value="" selected disabled hidden>
                  시간
                </option>
                {Array.from({ length: 24 }, (_, i: number) => i + 1).map(
                  (count) => (
                    <option key={count} value={count}>
                      {count < 10 ? '0' + count : count}시
                    </option>
                  ),
                )}
              </Select>
            </div>
            <div className="flex flex-col w-full">
              <Select
                {...register('startWorkMinute', {
                  valueAsNumber: true,
                })}
              >
                <option value="" selected disabled hidden>
                  분
                </option>
                {Array.from({ length: 6 }, (_, i: number) => 10 * i).map(
                  (count) => (
                    <>
                      <option key={count + 1} value={count}>
                        {count < 10 ? '0' + count : count}분
                      </option>
                    </>
                  ),
                )}
              </Select>
            </div>
            <div className="flex flex-col w-full">
              <Select {...register('endWorkTime')}>
                <option value="" selected disabled hidden>
                  시간
                </option>
                {Array.from({ length: 24 }, (_, i: number) => i + 1).map(
                  (count) => (
                    <option key={count} value={count}>
                      {count < 10 ? '0' + count : count}시
                    </option>
                  ),
                )}
              </Select>
            </div>
            <div className="flex flex-col w-full">
              <Select {...register('endWorkMinute')}>
                <option value="" selected disabled hidden>
                  분
                </option>
                {Array.from({ length: 6 }, (_, i: number) => 10 * i).map(
                  (count) => (
                    <option key={count + 1} value={count}>
                      {count < 10 ? '0' + count : count}분
                    </option>
                  ),
                )}
              </Select>
            </div>
          </div>
          {(errors.endWorkMinute ||
            errors.endWorkTime ||
            errors.startWorkMinute ||
            errors.startWorkTime) && (
            <div className="mt-1 text-red-500">
              상세 근무 시간을 입력해주세요.
            </div>
          )}
          <div>
            <Label required>지원 마감일</Label>
            <TextField
              className="w-full"
              type="date"
              {...register('deadLine')}
              helper={errors.deadLine?.message}
            />
          </div>

          <div className="text-lg font-semibold pt-4 mb-3">급여</div>

          <SeletedTagField
            required
            label="급여 형태"
            tags={data.tags}
            type="PAYTYPE"
            state={watch('payType')}
            setState={(value) => setValue('payType', value)}
            helper={errors.payType?.message?.toString()}
          />

          <div>
            <Label required>급여</Label>
            <div className="relative">
              <TextField
                className="w-full"
                placeholder="ex) 600000"
                {...register('wage')}
                onBlur={(e: any) => {
                  const newWage = Number(e.target.value.replace(/,/g, ''));
                  if (!newWage) return 0;
                  setValue(
                    'wage',
                    e.target.value.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                  );
                }}
                helper={errors.wage?.message}
              />
              <div className="absolute right-3 top-3">원</div>
            </div>
          </div>

          <SeletedTagField
            required
            label="세금 여부"
            tags={data.tags}
            type="TAXTYPE"
            state={watch('taxType')}
            setState={(value) => setValue('taxType', Number(value))}
            helper={errors.taxType?.message?.toString()}
          />

          {watchAlbaType === AlbaType.NORMAL && (
            <TextField
              required
              label="지급 예정일"
              placeholder="지급 예정일을 입력해주세요."
              {...register('provision')}
              helper={errors.provision?.message}
            />
          )}

          <div className="text-lg font-semibold pt-4">상세 정보 입력</div>
          <div className="grid grid-cols-3 gap-3">
            <div className="flex space-x-2 items-center">
              <Label className="min-w-8 whitespace-pre text-right" required>
                인원
              </Label>
              <TextField type="number" {...register('opening')} />
            </div>
            <div className="flex space-x-2 items-center">
              <Label className="min-w-8 whitespace-pre text-right" required>
                성별
              </Label>
              <Select placeholder="성별을 선택해주세요" {...register('gender')}>
                <option value="" selected disabled hidden></option>
                <option value="남자">남자</option>
                <option value="여자">여자</option>
                <option value="무관">무관</option>
              </Select>
            </div>
            <div className="flex space-x-2 items-center">
              <Label className="min-w-8 whitespace-pre text-right" required>
                연령
              </Label>
              <TextField {...register('age')} />
            </div>
            {watchAlbaType === AlbaType.NORMAL && (
              <>
                <div className="flex space-x-2 items-center">
                  <Label className="min-w-8 whitespace-pre text-right">
                    학력
                  </Label>
                  <Select
                    placeholder="학력을 선택해주세요"
                    {...register('education')}
                  >
                    <option value="" selected disabled hidden></option>
                    <option value="무관">무관</option>
                    <option value="고졸이상">고졸이상</option>
                    <option value="대졸이상">대졸이상</option>
                  </Select>
                </div>
                <div className="flex space-x-2 items-center">
                  <Label className="min-w-8 whitespace-pre text-right">
                    경력
                  </Label>
                  <TextField {...register('qualification')} />
                </div>
                <div className="flex space-x-2 items-center">
                  <Label className="min-w-8 whitespace-pre text-right">
                    우대
                  </Label>
                  <TextField {...register('advantage')} />
                </div>
              </>
            )}
          </div>
          {(errors.opening || errors.gender || errors.age) && (
            <div className="mt-1 text-red-500">상세 정보를 입력해주세요.</div>
          )}
          <Textarea
            required
            label="추가 내용"
            placeholder="공고에 대한 설명을 입력해주세요"
            {...register('summery')}
            helper={errors.summery?.message}
          />

          <label htmlFor="thumbnail">
            <div className="text-gray-800 text-sm mb-2">상세 이미지</div>
            <div className="w-full border-2 py-10 border-dashed border-grey-5 flex flex-col justify-center items-center space-y-1">
              <FileUploadImg />
              <div className="text-brand-1">상세 이미지 첨부하기</div>
            </div>
            {thumbnail && (
              <>
                <p>{thumbnail.name}</p>
                <img
                  className="w-full py-10  flex flex-col justify-center items-center space-y-1"
                  src={URL.createObjectURL(thumbnail)}
                  alt=""
                />
              </>
            )}
          </label>
          <input
            type="file"
            id="thumbnail"
            className="hidden"
            accept="image/*"
            onChange={(e) => {
              e.target.validity.valid && setThumbnail(e.target.files?.item(0));
            }}
          />

          <TextField
            label="영상 업로드"
            placeholder="영상 URL를 입력해주세요."
            {...register('videoUrl')}
          />

          <div className="w-full flex flex-col space-y-3 pt-4">
            <div className="text-brand-1 text-xs text-center ">
              *공고는 관리자의 승인 후 최종적으로 업로드됩니다.
            </div>
            <form
              onSubmit={handleSubmit((data) => {
                createdJob({
                  variables: {
                    ...data,
                    wage: Number(data.wage.replace(/,/g, '')),
                    detailWorkTime:
                      data.startWorkTime +
                      ':' +
                      getMinute(data.startWorkMinute) +
                      ' ~ ' +
                      data.endWorkTime +
                      ':' +
                      getMinute(data.endWorkMinute),
                    workingDay:
                      format(new Date(data.startWorkingDay), 'yyyy-MM-dd') +
                      ' ~ ' +
                      format(new Date(data.endWorkingDay), 'yyyy-MM-dd'),
                    thumbnail,
                  },
                });
              })}
            >
              <Button type="submit" className="w-full">
                등록 요청하기
              </Button>
            </form>
          </div>
        </Section>
      </div>
    </>
  );
};

export default AddJobPage;
