import React from 'react';
import SwiperImg from '../../assets/png/swiper-img.jpg';
import { ReactComponent as IconManpin } from '../../assets/svg/map-pin.svg';
import { Link } from 'react-router-dom';
import { Job } from '../../types/job';

const ParttimeCard = ({ job }: { job: Job }) => {
  const getSimpleAddress = (address: string) => {
    const array = address.split(' ');
    if (array.length < 3) return array.join(' ');
    return array.slice(0, 3).join(' ');
  };

  return (
    <Link to={`/map/jobs/${job.id}`}>
      <div className="lg:space-y-5 space-y-3">
        <div className="relative ">
          <img
            className="object-cover w-full rounded-xl h-32 md:h-48"
            src={job.entranceUrl || SwiperImg}
            alt="company entrance"
          />
        </div>

        <div className="space-y-2 lg:space-y-4">
          <div className="space-y-1">
            <p className="lg:text-lg text-gray-900 truncate-2-lines text-md">
              {job.title}
            </p>
            <p className="lg:text-sm text-gray-500 text-xs">
              {job.company.name}
            </p>
          </div>

          <div className="space-y-1">
            <p className="lg:text-lg font-bold text-md">
              {job.wage.toLocaleString()}원
            </p>
            <div className="flex items-center space-x-1 bg-white p-1 rounded-lg w-max">
              <IconManpin />
              <p className="lg:text-2sm text-xs">
                {getSimpleAddress(job.company.address)}
              </p>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default ParttimeCard;
