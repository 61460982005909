import React, { useMemo } from 'react';
import StarIcon from './StarIcon';

interface StarsProps {
  index: number;
  rating: number;
  hoverRating: number;
  onMouseEnter: (index: number) => void;
  onMouseLeave: () => void;
  onSaveRating: (index: number) => void;
}

const Stars: React.FC<StarsProps> = ({
  index,
  rating,
  hoverRating,
  onMouseEnter,
  onMouseLeave,
  onSaveRating,
}) => {
  const fillColor = useMemo(() => {
    if (hoverRating >= index) {
      return true; // #ffdb58 === 노란색
    } else if (!hoverRating && rating >= index) {
      return true; // #ffdb58 === 노란색
    }
    return false; // #dcdcdc === 회색
  }, [rating, hoverRating, index]);

  return (
    <div
      onMouseEnter={() => onMouseEnter(index)}
      onMouseLeave={() => onMouseLeave()}
      onClick={() => onSaveRating(index)}
    >
      <StarIcon fill={fillColor} />
    </div>
  );
};

export default Stars;
