import {
  useQuery,
  LoadingScreen,
  ListItem,
  utcToLocalFormat,
} from '@entropyparadox/reusable-react';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { TagsData, TAGS, AlbaType } from '../api';
import { Apply } from '../types/applies';

const APPLY_STATUS = {
  PENDING: '제출 확인중',
  SUCCESS: '합격',
  CANCEL: '불합격',
};

interface MyApplyCardProps {
  apply: Apply;
}

const MyApplyCard: FC<MyApplyCardProps> = ({ apply }) => {
  const { job } = apply;
  const { loading, error, data } = useQuery<TagsData>(TAGS);
  const now = new Date();
  now.setHours(0, 0, 0, 0);

  const deadLine = new Date(job.deadLine);
  deadLine.setHours(0, 0, 0, 0);

  if (loading) return <LoadingScreen />;
  if (error || !data) return <></>;

  const tagJobType = data.tags.filter((tag) => tag.id === job.jobType);
  const tagPayType = data.tags.filter((tag) => tag.id === job.payType);

  return (
    <ListItem>
      <Link to={`/map/jobs/${job.id}`}>
        <div
          className={`flex flex-col items-start space-y-2 ${
            deadLine < now && ' opacity-30'
          }`}
        >
          <div className="w-full flex items-center justify-between">
            <div className="flex items-center space-x-2">
              <div
                className={` ${
                  job.albaType === AlbaType.NORMAL
                    ? 'bg-darkgreen-5 text-darkgreen-1'
                    : 'bg-brand-5 text-brand-6'
                } min-w-max rounded-3xl px-3 py-1 text-sm`}
              >
                {job.albaType === AlbaType.NORMAL
                  ? tagJobType.map((tagJob) => <>{tagJob.title}</>)
                  : '번쩍 알바⚡'}
              </div>
            </div>
            <div>{APPLY_STATUS[apply.status]}</div>
          </div>
          <div className="w-full truncate text-left text-gray-800 font-bold">
            {job.title}
          </div>

          <div className="flex items-center w-full space-x-4">
            <div className="flex self-end space-x-2 items-center w-full">
              {tagPayType.map((tagPay) => (
                <div
                  className={`${
                    tagPay.title === '시급'
                      ? 'border-brand-7 text-brand-7'
                      : tagPay.title === '월급'
                      ? 'border-green-500 text-green-500'
                      : ''
                  } px-2 py-1 border-2 font-bold text-xs`}
                >
                  <>{tagPay.title}</>
                </div>
              ))}
              <div className="text-lg font-bold text-gray-600">
                {String(job.wage).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원
              </div>
            </div>
            <div className="text-gray-500 min-w-max">
              {utcToLocalFormat(job.deadLine || '', 'YYYY년 MM월 DD일')}까지
            </div>
          </div>
        </div>
      </Link>
    </ListItem>
  );
};

export default MyApplyCard;
