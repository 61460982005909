import React, { FC } from 'react';
import { Ticket, TicketType } from '../types/tickets';
import { format } from 'date-fns';
import { TICKET_TYPE_PREFIX } from '../constraints/tickets';

interface TicketLogItemProps {
  ticket: Ticket;
}

export const TicketLogItem: FC<TicketLogItemProps> = ({ ticket }) => {
  const isAdd = ticket.ticketType === TicketType.ADD;
  return (
    <div className="w-full h-full py-4 flex justify-between items-end">
      {isAdd ? (
        <div>
          <div className="text-md">이용권 구매</div>
          <div className="flex space-x-3 text-sm text-gray-500 mt-2">
            <div>결제금액</div>
            <div>{ticket.price?.toLocaleString()}원</div>
          </div>
          <div className="flex space-x-3 text-sm text-gray-500 mt-1">
            <div>구매일시</div>
            <div>
              {ticket.createdAt &&
                format(new Date(ticket.createdAt), 'yyyy-MM-dd HH:mm')}
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className="text-md">공고 등록</div>
          <div className="flex space-x-3 text-sm text-gray-500 mt-2">
            <div>등록일시</div>
            <div>
              {ticket.createdAt &&
                format(new Date(ticket.createdAt), 'yyyy-MM-dd HH:mm')}
            </div>
          </div>
        </div>
      )}
      <div className="text-right">
        <div
          className={`font-bold text-lg ${
            isAdd ? 'text-brand-1' : 'text-black'
          }`}
        >
          {TICKET_TYPE_PREFIX[ticket.ticketType]}
          {ticket.changedTicketNumber}개
        </div>
        <div className="text-gray-500 text-sm mt-1">
          남은 이용권 {ticket.totalTicketNumber}
        </div>
      </div>
    </div>
  );
};
