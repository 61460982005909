import { LoadingScreen, useQuery } from '@entropyparadox/reusable-react';
import React from 'react';
import { TAGS, TagsData } from '../api';
import { FilterPageContent } from '../components/FilterPageContent';

export const FilteringPage = () => {
  const { loading, error, data } = useQuery<TagsData>(TAGS);

  if (loading) return <LoadingScreen />;
  if (error || !data) return <></>;

  return (
    <div className="h-screen relative">
      <FilterPageContent tags={data.tags} />
    </div>
  );
};
