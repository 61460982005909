import { FC } from 'react';
import { Job } from '../types/job';

interface ClusterMarkerProps {
  count: number;
  total: number;
  place: Job & { isUrgent: boolean };
}

const ClusterMarker: FC<ClusterMarkerProps> = ({ count, place }) => {
  return (
    <div
      className={` ${
        place.isUrgent ? 'bg-brand-6' : 'bg-brand-1'
      } grid place-items-center rounded-full font-bold text-base text-white cursor-pointer`}
      style={{
        width: `${60}px`,
        height: `${60}px`,
      }}
    >
      {count}
    </div>
  );
};

export default ClusterMarker;
