import { Job } from './job';
import { Resume } from './resumes';
import { User } from './user';

export interface Apply {
  id: number;
  userId: number;
  status: StatusApply;
  job: Job;
  user: User;
  resume: Resume[];
}

export enum StatusApply {
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  CANCEL = 'CANCEL',
}
