import React from 'react';
import BgImg from '../../assets/jpg/section-1-bg.jpg';

import { ReactComponent as AppleIcon } from '../../assets/svg/icon-apple.svg';
import { ReactComponent as GoogleIcon } from '../../assets/svg/Icon-google.svg';
import { ReactComponent as IllustSVG } from '../../assets/svg/section-1-illust.svg';

import SerchField from './SerchField';
import { Link } from 'react-router-dom';

const Section1 = () => {
  return (
    <div>
      {/* pc */}
      <div className="relative w-full hidden lg:block z-0">
        <img
          className="object-cover -z-10 absolute max-h-192 w-full"
          src={BgImg}
          alt=""
        />

        <div className="max-w-screen-lg mx-auto w-full px-5 xl:py-60 space-y-5 lg:py-52 md:py-40 sm:py-32">
          <p className="sm:text-3xl md:text-3xl lg:text-3xl xl:text-5xl leading-normal text-white">
            우구구만의
            <br />
            맞춤 알바 매칭 서비스
          </p>

          <p className="sm:text-sm md:text-sm lg:text-md xl:text-lg font-light text-gray-300">
            하나씩 찾았던 번거로움은 NO.
            <br />
            더욱 빠르고, 쉽게 구할 수 있어요!
            <br />
            사장님도 알바님도 편하게
          </p>

          <div className="flex items-center space-x-3 pt-5">
            <button
              className="bg-white rounded-md flex items-center space-x-2 w-44 justify-center py-3.5"
              onClick={() =>
                window.open(
                  'https://apps.apple.com/kr/app/woogugu-%EC%9A%B0%EA%B5%AC%EA%B5%AC/id1574018732',
                )
              }
            >
              <AppleIcon />
              <p>App Store</p>
            </button>

            <button
              className="bg-black text-white rounded-md flex items-center space-x-2 w-44 justify-center py-4"
              onClick={() =>
                window.open(
                  'https://play.google.com/store/apps/details?id=com.woogugu',
                )
              }
            >
              <GoogleIcon />
              <p>Google Play</p>
            </button>
          </div>
        </div>
      </div>

      {/* mobile */}
      <Link to={'/map'}>
        <div className="px-5 pt-4 lg:pt-32 space-y-7 lg:hidden">
          <SerchField />

          <div className="bg-brand-1 rounded-xl p-7 flex items-center justify-between">
            <div className="space-y-2">
              <p className="text-white text-xl md:text-2xl">
                번거롭던 알바 찾기는
                <br />
                이제 그만!
              </p>

              <p className="text-md text-gray-200">
                우구구만의 매칭 서비스로
                <br />
                간편하게 알바를 찾아보세요.
              </p>

              <div className="pt-3">
                <div className="text-sm underline text-white">
                  더 알아보기 →
                </div>
              </div>
            </div>

            <IllustSVG className="md:w-32 md:h-32 w-20 h-20" />
          </div>
        </div>
      </Link>
    </div>
  );
};

export default Section1;
