import React, { useState } from 'react';
import {
  TextField,
  Button,
  PhoneNumberField,
  Label,
  Checkbox,
  useMutation,
  useSetRecoilState,
  tokenState,
  Select,
  Tabs,
  Tab,
  TextArea,
} from '@entropyparadox/reusable-react';
import { DaumPost } from '../components/DaumPost';
import NewMainTopNavbar from '../components/NewMainTopNavbar';
import {
  CreatePhone,
  SignUpBusiness,
  SignUpUser,
  UpDatePhoneVerify,
} from '../api';
import { Role } from '../types/user';
import { useHistory } from 'react-router';
import { ReactComponent as FileUploadImg } from '../assets/svg/file-upload.svg';
import { Link, useLocation } from 'react-router-dom';
import { validateKoreanRRN } from '../util/rrn';
import ReqPrefix from '../components/ReqPrefix';

export const SignupPage: React.FC = () => {
  const history = useHistory();
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const role = (params.get('type') || Role.USER) as Role;
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordAgain, setPasswordAgain] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [address, setAddress] = useState('');
  const [detailAddress, setDetailAddress] = useState('');
  const [number, setNumber] = useState('');
  const [year, setYear] = useState('');
  const [month, setMonth] = useState('');
  const [day, setDay] = useState('');
  const [url, setUrl] = useState('');
  const [introduction, setIntroduction] = useState('');
  const [license, setLicense] = useState<File | null | undefined>();
  const [confirm, setConfirm] = useState(false);
  const [place, setPlace] = useState(false);
  const [agree, setAgree] = useState(false);
  const [code, setCode] = useState('');
  const [rrn1, setRRN1] = useState('');
  const [rrn2, setRRN2] = useState('');
  const [authenticationNumber, setAuthenticationNumber] = useState(false);
  const [authenticationComplet, setAuthenticationComplet] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [entranceUrl, setEntranceUrl] = useState<File | null | undefined>(null);
  const [errorMessage, setErrorMessage] = useState('');
  const setToken = useSetRecoilState(tokenState);

  const [createPhone] = useMutation(CreatePhone, {
    variables: { phone: Number(phone) },
    onCompleted: ({ createPhone }) => {
      window.localStorage.setItem('phoneId', createPhone.id);
      setAuthenticationNumber(true);
      alert('인증번호가 발송되었습니다.');
    },
    onError: (err) => {
      console.log(err);
    },
  });

  const phnId = window.localStorage.getItem('phoneId');
  const m = month.length === 1 ? `0${month}` : month;
  const d = day.length === 1 ? `0${day}` : day;

  const [upDatePhoneVerify] = useMutation(UpDatePhoneVerify, {
    variables: { id: Number(phnId), code: code },
    onCompleted: () => {
      setAuthenticationComplet(true);
      window.localStorage.removeItem('phoneId');
    },
    onError: (error) => {
      alert(error);
    },
  });

  const [signUpUser] = useMutation(SignUpUser, {
    variables: {
      email,
      password,
      name,
      phone,
      agree,
      address,
      detailAddress,
      rrn: rrn1 + rrn2,
    },
    onCompleted: async ({ signUpUser: { token } }) => {
      await setToken(token);
      alert('회원가입이 완료 되었습니다.');
    },
  });

  const [signUpBusiness] = useMutation(SignUpBusiness, {
    variables: {
      email,
      password,
      name,
      phone,
      address,
      detailAddress,
      birthday: year && m && d ? year + m + d : '1970-01-01',
      companyName,
      introduction,
      url,
      number,
      license: license,
      agree,
      entranceUrl,
    },
    onCompleted: async ({ signUpBusiness: { token } }) => {
      alert('회원가입이 완료 되었습니다.');
      await setToken(token);
    },
    onError: async (err) => {
      console.log(err);
    },
  });

  const maxOffset = 70;
  const thisYear = new Date().getFullYear();
  const allYears = [];
  for (let x = 0; x <= maxOffset; x++) {
    allYears.push(thisYear - x);
  }

  const yearList = allYears?.map((y) => {
    return (
      <option key={y} value={y}>
        {y}년
      </option>
    );
  });
  // n.length >= width ? n : new Array(width - n.length + 1).join('0') + n
  const monthList = Array.from({ length: 12 }, (_, i) => i + 1)?.map((m) => {
    return (
      <option key={m} value={m}>
        {m}월
      </option>
    );
  });

  const days = new Date(Number(year), Number(month), 0).getDate();
  const dayList = Array.from({ length: days }, (_, i) => i + 1)?.map((d) => {
    return (
      <option key={d} value={d}>
        {d}일
      </option>
    );
  });

  const requiredButMissedValues = [
    !name && '이름',
    role === Role.USER && !rrn1 && !rrn2 && '주민번호',
    !email && '이메일',
    !password && '비밀번호',
    !passwordAgain && '비밀번호 확인',
    !phone && '전화번호',
    !authenticationComplet && '',
    !place && '',
    !address && '주소',
    !detailAddress && '상세 주소',
    role === Role.BUSINESS && !entranceUrl && '매장 입구 사진',
    role === Role.BUSINESS && !companyName && '업장명',
    role === Role.BUSINESS && !introduction && '사업 내용',
    role === Role.BUSINESS && !license && '사업자 등록증',
  ].filter((el) => !!el);

  return (
    <>
      <NewMainTopNavbar hasMainButton />
      <div className="min-h-screen flex flex-col justify-center sm:px-6 lg:px-8">
        <div className="mt-4 md:mt-8 sm:mx-auto sm:max-w-md sm:w-160">
          <section
            className={`flex flex-col items-stretch space-y-8 px-5 py-4 bg-white`}
          >
            <div className="text-2xl font-bold text-gray-800 pt-3">
              회원가입
            </div>
            <Tabs>
              <Tab
                text="일반 회원"
                active={role === Role.USER || !role}
                onClick={() => history.replace(`/signup?type=${Role.USER}`)}
              />
              <Tab
                text="사업자 회원"
                active={role === Role.BUSINESS}
                onClick={() => history.replace(`/signup?type=${Role.BUSINESS}`)}
              />
            </Tabs>
            <div>
              <Label>
                <ReqPrefix />
                이름
              </Label>
              <TextField
                placeholder="이름을 입력해주세요"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            {role !== Role.USER && (
              <div>
                <Label>
                  <ReqPrefix />
                  생년월일
                </Label>
                <div className="flex space-x-3.5 mt-0">
                  <div className="w-full">
                    <Select
                      placeholder="년"
                      value={year}
                      onChange={(e) => setYear(e.target.value)}
                    >
                      <option value="" selected disabled hidden>
                        년
                      </option>
                      {yearList}
                    </Select>
                  </div>
                  <div className="w-full">
                    <Select
                      placeholder="월"
                      value={month}
                      onChange={(e) => setMonth(e.target.value)}
                    >
                      <option value="" selected disabled hidden>
                        월
                      </option>
                      {monthList}
                    </Select>
                  </div>
                  <div className="w-full">
                    <Select
                      placeholder="일"
                      value={day}
                      onChange={(e) => setDay(e.target.value)}
                    >
                      <option value="" selected disabled hidden>
                        일
                      </option>
                      {dayList}
                    </Select>
                  </div>
                </div>
              </div>
            )}
            {role === Role.USER && (
              <div>
                <div className="mb-2 text-sm block text-gray-800">
                  <ReqPrefix />
                  주민번호
                </div>
                <div className="flex space-x-2 items-center">
                  <TextField
                    pattern="\d*"
                    placeholder="주민번호를 입력해주세요"
                    value={rrn1}
                    maxLength={6}
                    onChange={(e) => {
                      setRRN1(e.target.value);
                    }}
                  />
                  <div>-</div>
                  <TextField
                    pattern="\d*"
                    placeholder="주민번호를 입력해주세요"
                    value={rrn2}
                    maxLength={7}
                    onChange={(e) => {
                      setRRN2(e.target.value);
                    }}
                  />
                </div>
                <div className="text-red-500">
                  {rrn1 &&
                    rrn2 &&
                    !validateKoreanRRN(rrn1 + rrn2) &&
                    '주민번호가 주민번호 양식에 맞지 않습니다.'}
                </div>
              </div>
            )}
            <div>
              <Label>
                <ReqPrefix />
                전화번호
              </Label>
              <PhoneNumberField
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                disabled={authenticationComplet}
              />
            </div>
            {authenticationNumber ? (
              <>
                {!authenticationComplet ? (
                  <>
                    <Button
                      variant="outlined"
                      style={{
                        fontWeight: 400,
                        fontSize: '0.875rem',
                        lineHeight: '1.25rem',
                      }}
                      onClick={() =>
                        phone.length === 11
                          ? createPhone()
                          : alert('핸드폰 번호를 모두 입력해주세요.')
                      }
                    >
                      인증번호 재전송
                    </Button>

                    <div className="mt-4">
                      <div className="grid grid-cols-4 gap-4 mt-1">
                        <input
                          type="text"
                          name=""
                          className="col-span-3 block w-full h-12 border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:to-brand-1 sm:text-sm"
                          placeholder="인증번호를 입력해주세요."
                          value={code}
                          onChange={(e) => setCode(e.target.value)}
                        />
                        <button
                          className="col-span-1 bg-brand-1 rounded-md text-sm text-white"
                          onClick={() =>
                            code
                              ? upDatePhoneVerify()
                              : alert('인증번호를 입력해주세요.')
                          }
                        >
                          확인
                        </button>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    {authenticationComplet && (
                      <div className="text-sm">* 인증이 완료되었습니다.</div>
                    )}
                  </>
                )}
              </>
            ) : (
              <Button
                variant="outlined"
                style={{
                  fontWeight: 400,
                  fontSize: '0.875rem',
                  lineHeight: '1.25rem',
                }}
                onClick={() =>
                  phone.length === 11
                    ? createPhone()
                    : alert('핸드폰 번호를 모두 입력해주세요.')
                }
              >
                인증번호 보내기
              </Button>
            )}
            <div>
              <Label>
                <ReqPrefix />
                이메일 주소
              </Label>
              <TextField
                placeholder="이메일을 입력해주세요"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="space-y-1">
              <div>
                <Label>
                  <ReqPrefix />
                  비밀번호
                </Label>
                <TextField
                  type="password"
                  placeholder="비밀번호를 입력해주세요"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              {password !== '' && password.length < 8 && (
                <h2 className="text-xs text-red-500">
                  비밀번호는 8자 이상 입력해주세요.
                </h2>
              )}
            </div>
            <div className="space-y-1">
              <div>
                <Label>
                  <ReqPrefix />
                  비밀번호 확인
                </Label>
                <TextField
                  type="password"
                  placeholder="비밀번호를 재입력해주세요"
                  value={passwordAgain}
                  onChange={(e) => setPasswordAgain(e.target.value)}
                />
              </div>
              {passwordAgain !== '' && password !== passwordAgain && (
                <h2 className="text-xs text-red-500">
                  비밀번호와 비밀번호 확인이 다릅니다.
                </h2>
              )}
            </div>
            {role === Role.BUSINESS && (
              <TextField
                label="*업장명"
                placeholder="업장명을 입력해주세요"
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
              />
            )}
            <div className="mt-4">
              <label
                htmlFor="expiration_date"
                className="block text-sm font-medium text-gray-700"
              >
                {role === Role.BUSINESS ? (
                  <>
                    <ReqPrefix />
                    회사주소
                  </>
                ) : (
                  <>
                    <ReqPrefix />
                    주소
                  </>
                )}
              </label>
              <div className="grid grid-cols-4 gap-4 mt-2">
                <div className="col-span-3">
                  <TextField
                    type="text"
                    name="address"
                    id="phone_number_f"
                    placeholder="주소를 입력해주세요."
                    value={address}
                    disabled
                  />
                </div>
                <button
                  className="col-span-1 bg-brand-1 rounded-md text-white"
                  onClick={() => setIsOpen(true)}
                >
                  주소 검색
                </button>
              </div>
              {isOpen && (
                <DaumPost
                  setAddress={(fullAddress: any) => setAddress(fullAddress)}
                  setIsOpen={() => setIsOpen(false)}
                />
              )}
            </div>
            <div>
              <Label>
                <ReqPrefix />
                상세 주소
              </Label>
              <TextField
                placeholder="상세 주소를 입력해주세요"
                value={detailAddress}
                onChange={(e) => setDetailAddress(e.target.value)}
              />
            </div>
            {role === Role.BUSINESS && (
              <>
                <TextField
                  label="전화번호"
                  placeholder="전화번호를 입력해주세요"
                  value={number}
                  onChange={(e) => setNumber(e.target.value)}
                />
                <TextField
                  label="홈페이지"
                  placeholder="홈페이지를 입력해주세요"
                  value={url}
                  onChange={(e) => setUrl(e.target.value)}
                />
                <div>
                  <Label>
                    <ReqPrefix />
                    사업 내용
                  </Label>
                  <TextArea
                    placeholder="사업 내용에 대해 간단하게 작성해주세요."
                    value={introduction}
                    onChange={(e) => setIntroduction(e.target.value)}
                  />
                </div>
                <label htmlFor="license">
                  <div className="text-gray-800 text-sm mb-2">
                    <ReqPrefix />
                    사업자 등록증 첨부하기 (이미지 또는 PDF 파일 가능)
                  </div>
                  <div className="w-full border-2 py-10 border-dashed border-grey-5 flex flex-col justify-center items-center space-y-1">
                    <FileUploadImg />
                    <div className="text-brand-1">사업자 등록증 첨부하기</div>
                  </div>
                  {license && (
                    <>
                      <p>{license.name}</p>
                      <img
                        className="w-full py-10  flex flex-col justify-center items-center space-y-1"
                        src={URL.createObjectURL(license)}
                        alt=""
                      />
                    </>
                  )}
                </label>
                <input
                  type="file"
                  id="license"
                  className="hidden"
                  onChange={(e) => {
                    if (e.target.validity.valid) {
                      const file = e.target.files?.item(0);

                      if (file && file.size > 104857600) {
                        alert('100MB이하 파일만 업로드 가능합니다.');
                      }

                      if (file && file.size <= 104857600) {
                        setLicense(e.target.files?.item(0));
                      }
                    }
                  }}
                />

                <label htmlFor="entranceUrl">
                  <div className="text-gray-800 text-sm mb-2">
                    매장 입구 사진
                  </div>
                  <div
                    className="w-full border-2 py-10 border-dashed border-grey-5 flex flex-col justify-center items-center space-y-1"
                    style={{ borderColor: !entranceUrl ? 'red' : '' }}
                  >
                    <FileUploadImg />
                    <div className="text-brand-1">매장 입구 사진 첨부하기</div>
                  </div>
                  {entranceUrl && (
                    <>
                      <p>{entranceUrl.name}</p>
                      <img
                        className="w-full py-10  flex flex-col justify-center items-center space-y-1"
                        src={URL.createObjectURL(entranceUrl)}
                        alt=""
                      />
                    </>
                  )}
                </label>
                <input
                  type="file"
                  id="entranceUrl"
                  className="hidden"
                  accept="image/*"
                  onChange={(e) => {
                    e.target.validity.valid &&
                      setEntranceUrl(e.target.files?.item(0));
                  }}
                />
              </>
            )}
            {role === Role.BUSINESS && (
              <div className="text-sm">
                사업자 회원은 100명까지만 가입 승인을 하고 있습니다. 승인 처리가
                안될 경우 고객센터로 문의해주세요
              </div>
            )}
            <div className="space-y-2">
              <Checkbox
                label="전체 동의"
                onChange={() => {
                  const allChecked = confirm && place && agree;
                  setConfirm(!allChecked);
                  setPlace(!allChecked);
                  setAgree(!allChecked);
                }}
                checked={confirm && place && agree}
              />
              <div className="flex items-center space-x-2">
                <Checkbox
                  id="confirm"
                  onChange={() => setConfirm(!confirm)}
                  checked={confirm}
                />

                <Label htmlFor="confirm" margin="m-0">
                  <span className="text-sm">
                    회원가입 시{' '}
                    <Link to="/agreement/personal-info" target="blank">
                      개인정보 처리방침
                    </Link>
                    과{' '}
                    <Link
                      to={`${
                        role === Role.USER
                          ? '/agreement/user-confirm'
                          : '/agreement/business-confirm'
                      }`}
                      target="blank"
                    >
                      이용약관
                    </Link>
                    을 확인하였으며, 이에 동의합니다. (필수)
                  </span>
                </Label>
              </div>
              <div className="flex items-center space-x-2">
                <Checkbox
                  id="place"
                  onChange={() => setPlace(!place)}
                  checked={place}
                />
                <Link to="/agreement/location-service-agree" target="blank">
                  <Label htmlFor="place" margin="m-0">
                    <span className="text-sm">
                      <span className="cursor-pointer">
                        위치기반 서비스 이용약관
                      </span>
                      동의 (필수)
                    </span>
                  </Label>
                </Link>
              </div>
              <div className="flex items-center space-x-2">
                <Checkbox
                  id="agree"
                  onChange={() => setAgree(!agree)}
                  checked={agree}
                />
                <Link to="/agreement/marketing-agree" target="blank">
                  <Label htmlFor="agree" margin="m-0">
                    <span className="text-sm ">
                      <span className="cursor-pointer">마케팅 수신</span>
                      동의 (선택)
                    </span>
                  </Label>
                </Link>
              </div>
            </div>
            <div className="text-red-500">{errorMessage}</div>
            <Button
              disabled={
                !email ||
                !password ||
                !name ||
                !passwordAgain ||
                !phone ||
                !authenticationComplet ||
                !place ||
                !address ||
                !detailAddress ||
                (role === Role.USER && !rrn1 && !rrn2) ||
                (role === Role.BUSINESS && !entranceUrl) ||
                (role === Role.BUSINESS && !companyName) ||
                (role === Role.BUSINESS && !introduction) ||
                (role === Role.BUSINESS && !license)
              }
              id="signup-btn"
              onClick={async () => {
                if (password.length < 8) {
                  setErrorMessage('비밀번호는 8자 이상 입력해주세요.');
                  return;
                }
                if (password !== passwordAgain) {
                  setErrorMessage('비밀번호와 비밀번호 확인이 다릅니다!');
                  return;
                }
                if (role === Role.USER) {
                  if (!validateKoreanRRN(rrn1 + rrn2)) {
                    setErrorMessage(
                      '주민번호가 주민번호 양식에 맞지 않습니다.',
                    );
                    return;
                  }
                }
                try {
                  if (role === Role.USER) {
                    const res = await signUpUser();
                    if (res) {
                      const { data, errors } = res;
                      if (data?.signUpUser.token) {
                        history.push('/main');
                      } else {
                        setErrorMessage(
                          errors?.map((el: any) => el.message).join(', ') || '',
                        );
                      }
                    } else {
                      setErrorMessage(
                        '회원가입 정보가 정확하지 않거나 이미 가입된 이메일입니다. 정보를 다시 확인해주세요!',
                      );
                    }
                  } else if (role === Role.BUSINESS) {
                    const res = await signUpBusiness();
                    const { data, errors } = res;
                    if (data?.signUpBusiness?.token) {
                      history.push('/main');
                    } else {
                      setErrorMessage(
                        errors?.map((el: any) => el.message).join(', ') || '',
                      );
                    }
                  }
                } catch (err: any) {
                  if (
                    err?.message?.includes(
                      'duplicate key value violates unique constraint',
                    )
                  ) {
                    setErrorMessage(
                      '이미 가입된 이메일입니다. 로그인을 시도해주세요!',
                    );
                  } else if (
                    err?.message?.includes('Received status code 400')
                  ) {
                    setErrorMessage('필수 정보를 입력해주세요!');
                  } else {
                    setErrorMessage(err?.message || '');
                  }
                }
              }}
            >
              회원가입
            </Button>
            <div className="text-red-500 text-sm">
              {!!requiredButMissedValues.length &&
                requiredButMissedValues.filter((el) => !!el).join(', ') +
                  '을(를) 입력해주세요.'}
              {'\n'}
              {password !== passwordAgain &&
                '비밀번호와 비밀번호 확인이 다릅니다.'}
              {'\n'}
              {!authenticationComplet && '전화번호가 인증되지 않았습니다.'}
            </div>
          </section>
        </div>
      </div>
    </>
  );
};
