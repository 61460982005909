import React from 'react';
import { CompanyJobItem } from '../components';
import {
  BackButton,
  Divider,
  List,
  LoadingScreen,
  Section,
  TopNavbar,
  useIdParam,
  useQuery,
} from '@entropyparadox/reusable-react';
import { companiesJobs, CompaniesJobsData } from '../api';

export const CompanyDetailPage: React.FC = () => {
  const id = useIdParam();

  const { loading, error, data } = useQuery<CompaniesJobsData>(companiesJobs, {
    variables: {
      id: Number(id),
    },
  });

  if (loading) return <LoadingScreen />;
  if (error || !data) return <></>;

  return (
    <>
      <TopNavbar left={<BackButton />} title="회사 정보" />

      <div className="bg-white">
        <div className="">
          <div className="my-3">
            <Section spaceY="space-y-2">
              <div className="text-center text-lg font-bold text-gray-800">
                {data.companiesJobs[0].company.name}
              </div>
              <div>
                {data.companiesJobs[0].company.number && (
                  <div className="flex items-start justify-center space-x-2">
                    <div className="text-gray-500 flex-shrink-0">전화번호</div>
                    <div className="text-gray-800">
                      {data.companiesJobs[0].company.number}
                    </div>
                  </div>
                )}
                <div className="flex items-start justify-center space-x-2">
                  <div className="text-gray-500 flex-shrink-0">주소</div>
                  <div className="text-gray-800 w-3/4">
                    {data.companiesJobs[0].company.address}{' '}
                    {data.companiesJobs[0].company.detailAddress}
                  </div>
                </div>
                {data.companiesJobs[0].company.url && (
                  <div className="flex items-start justify-center space-x-2">
                    <div className="text-gray-500 flex-shrink-0">홈페이지</div>
                    <div className="text-gray-800">
                      <a
                        href={data.companiesJobs[0].company.url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {data.companiesJobs[0].company.url}
                      </a>
                    </div>
                  </div>
                )}
              </div>
            </Section>
          </div>
          <Divider height="h-3" />
          <Section>
            <div className="text-gray-700 font-bold text-lg py-2">
              모집중인 알바
            </div>
          </Section>
          <Divider />
          <List>
            {data.companiesJobs.map((job) => (
              <CompanyJobItem key={job.id} job={job} />
            ))}
          </List>
        </div>
      </div>
    </>
  );
};
