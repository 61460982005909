import { Button, useQuery } from '@entropyparadox/reusable-react';
import { GetTicketInfo, GetTicketInfoData } from '../../api';
import { ReactComponent as TicketIcon } from '../../assets/svg/ticket.svg';
import { TicketLogItem } from '../../components/TicketLogItem';
import { useHistory } from 'react-router-dom';

const CompanyTicket = () => {
  const history = useHistory();
  const { data } = useQuery<GetTicketInfoData>(GetTicketInfo);

  return (
    <>
      <div className="py-6 px-5">
        <div className="w-full rounded-xl border border-brand-1 bg-brand-1 bg-opacity-5 p-5 space-y-5">
          <div className="flex justify-between items-center">
            <span className="text-lg">내 이용권 수</span>
            <div className="flex items-center space-x-2">
              <TicketIcon className="w-6 h-6" />
              <div className="text-brand-1 text-lg font-bold">
                {data?.me?.ticketNumber}
              </div>
            </div>
          </div>
          <Button
            text="이용권 구매하기 →"
            onClick={() => history.push('/company/payment')}
            style={{
              width: '100%',
              fontSize: 14,
              paddingTop: 10,
              paddingBottom: 10,
            }}
          />
        </div>
        <div className="mt-9">
          <h1 className="mb-4 text-xl">이용권 충전 및 사용 내역</h1>
          <hr />
          {data?.myTicketLogs?.map((ticket) => (
            <TicketLogItem ticket={ticket} key={ticket.id} />
          ))}
        </div>
      </div>
    </>
  );
};

export default CompanyTicket;
