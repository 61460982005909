import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from './Accordion';
import NewLineText from './NewLineText';

type Props = {
  title: string;
  content: string;
};

const MyPageAccordion = (props: Props) => {
  const { title, content } = props;

  return (
    <Accordion type="single" collapsible className="w-full">
      <AccordionItem value="item1">
        <AccordionTrigger>
          <p className="text-left text-[15px] text-[#0C203F] lg:text-[17px]">
            {title}
          </p>
        </AccordionTrigger>
        <AccordionContent>
          <div>
            <NewLineText
              className="text-[14px] text-gray-500 lg:text-[15px]"
              text={content}
            />
          </div>
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};

export default MyPageAccordion;
