import {
  Divider,
  LoadingScreen,
  useQuery,
  utcToLocalFormat,
} from '@entropyparadox/reusable-react';
import React, { FC } from 'react';
import { employeeReview, EmployeeReviewData } from '../api';
//@ts-ignore
import ReactStars from 'react-rating-stars-component';
interface EvaluateListProps {
  employeeId: number;
}

const EvaluateList: FC<EvaluateListProps> = ({ employeeId }) => {
  const { loading, error, data } = useQuery<EmployeeReviewData>(
    employeeReview,
    {
      variables: { employeeId: employeeId },
    },
  );

  if (loading) return <LoadingScreen />;
  if (error || !data) return <></>;

  return (
    <div>
      {data.employeeReview.length === 0 && (
        <div style={{ color: '#B0B8C1' }}>
          아직 입력된 업장의 후기가 없습니다.
        </div>
      )}
      {data.employeeReview.map((review) => (
        <>
          <div className="text-sm space-y-2 mb-4">
            <div className="flex justify-between items-center">
              <div className="flex items-center space-x-2">
                <span>{review.employer.name}</span>
                <div className="flex space-x">
                  <ReactStars
                    count={5}
                    size={18}
                    edit={false}
                    value={review.rate}
                  />
                </div>
              </div>
              <span>
                {utcToLocalFormat(review.createdAt || '', 'YYYY.MM.DD')}
              </span>
            </div>
            <p className="text-gray-800">{review.content}</p>
          </div>
          <Divider />
        </>
      ))}
    </div>
  );
};

export default EvaluateList;
