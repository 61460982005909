import { FC, useEffect, useState } from 'react';
import MyPageAccordion from '../../components/MyPageAccordion';
import { useAuth, useRecoilValue } from '@entropyparadox/reusable-react';
import { meState } from '../../store';
import { Role } from '../../types/user';
import { twMerge } from 'tailwind-merge';

interface UsagePageProps {}

const USER_USAGE = [
  {
    title: '1. 회원가입 방법',
    content: `우측 상단 회원가입 버튼 클릭 후 알바님께서는 일반 회원으로 가입해주셔야합니다.\n각 정보(이름, 주민번호, 전화번호 등)를 입력하신 뒤 회원가입을 눌러주시면 회원가입이 진행됩니다.`,
  },
  {
    title: '2. 이력서 등록 방법',
    content: `마이페이지에서 이력서 카테고리를 선택하여 정보를 입력합니다.\n회원가입시 입력되었던 이름, 생년월일, 핸드폰 번호는 이력서에서는 변경 불가능하며 프로필에서 변경하셔야합니다.\n\n* 미성년자 알바님은 꼭 부모님 동의서를 작성하셔서 업로드해주세요.`,
  },
  {
    title: '3. 알바 지원 방법',
    content: `원하는 공고를 클릭 후 지원하기를 클릭해주세요.\n지원 후에는 지원완료로 변경되니 꼭 확인해주시기 바랍니다.`,
  },
];

const COMPANY_USAGE = [
  {
    title: '1. 회원가입 방법',
    content: `우측 상단 회원가입 버튼 클릭 후 사장님께서는 사업자 회원으로 가입해주셔야합니다.\n각 정보(이름, 생년월일, 전화번호, 업장 정보 등)를 입력하신 뒤 회원가입을 눌러주시면 회원가입이 진행됩니다.`,
  },
  {
    title: '2. 사업자 등록 승인 방법',
    content: `담당자가 사업자 등록증 및 정보를 확인 후에 이상이 없으면 승인처리가 완료됩니다.\n승인이 안되는 경우 채널톡으로 문의주시면 빠르게 처리해드리겠습니다.`,
  },
  {
    title: '3. 이용권 구매',
    content: `우측 상단 공고관리 버튼 클릭 후 이용권 구매를 진행해야 합니다.\n마이페이지에서 이용권 구매하기 클릭 후 이용권을 결제해주시면 됩니다.\n취소/환불 절차는 하단에 안내되어 있습니다.\n(현재 이벤트 진행 중으로 이용권은 별도 지급됩니다.)`,
  },
  {
    title: '4. 공고는 어떻게 등록하나요',
    content: `공고는 일반 공고와 번쩍 공고로 나뉘며 번쩍 공고의 경우 급하게 알바가 필요한 경우에 이용 가능합니다.\n공고 내용은 필수 항목을 기재 또는 선택 후 등록 요청하기 버튼을 눌러주시면 됩니다.`,
  },
  {
    title: '5. 공고 승인은 언제 되나요?',
    content: `허위 또는 불법적인 공고 게시를 막고자 별도 승인 절차가 필요합니다.\n승인이 안되는 경우 채널톡으로 문의주시면 빠르게 처리해드리겠습니다.`,
  },
];

const UsagePage: FC<UsagePageProps> = ({}) => {
  const { authenticated } = useAuth();
  const me = useRecoilValue(meState);

  const [isUser, setIsUser] = useState(true);

  useEffect(() => {
    if (!!authenticated && !!me && me?.role === Role.BUSINESS) {
      setIsUser(false);
    }
  }, [me, authenticated]);

  return (
    <div>
      {!authenticated && (
        <div className="mt-5 flex space-x-4">
          <button
            className={twMerge(
              'px-4 py-2 rounded-full',
              isUser ? 'bg-brand-1 text-white' : 'text-gray-300',
            )}
            onClick={() => setIsUser(true)}
          >
            알바님
          </button>
          <button
            className={twMerge(
              'px-4 py-2 rounded-full',
              !isUser ? 'bg-brand-1 text-white' : 'text-gray-300',
            )}
            onClick={() => setIsUser(false)}
          >
            사장님
          </button>
        </div>
      )}
      <div className="mt-4">
        {isUser ? (
          <div className="space-y-2">
            {USER_USAGE.map((data, i) => (
              <MyPageAccordion
                key={i}
                title={data.title}
                content={data.content}
              />
            ))}
          </div>
        ) : (
          <div className="space-y-2">
            {COMPANY_USAGE.map((data, i) => (
              <MyPageAccordion
                key={i}
                title={data.title}
                content={data.content}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default UsagePage;
