import {
  gql,
  useApolloClient,
  useLogout,
  useAuth,
  useRecoilState,
  AuthRoute,
} from '@entropyparadox/reusable-react';
import { useEffect } from 'react';
import { useLocation, Switch, Route, Redirect } from 'react-router-dom';
import ChannelService from '../ChannelService';
import { ME } from '../api';
import { BottomNavBar } from '../components/BottomNavBar';
import { Bridge } from '../plugins/bridge';
import { meState } from '../store';
import AddJobPage from './AddJobPage';
import { BusinessConfirmPage } from './Agreement/BusinessConfirmPage';
import { LocationServiceAgreePage } from './Agreement/LocationServiceAgreePage';
import { MarketingAgreePage } from './Agreement/MarketingAgreePage';
import { PersonalInfoPage } from './Agreement/PersonalInfoPage';
import { UserConfirmPage } from './Agreement/UserConfirmPage';
import ApplicantDetail from './ApplicantDetail';
import { ApplicantListPage } from './ApplicantListPage';
import ChangePassword from './ChangePassword';
import { CompanyPage } from './CompanyMypage/CompanyPage';
import DeleteAccount from './DeleteAccount';
import EditJobPage from './EditJobPage';
import EmailChangePassword from './EmailChangePassword';
import EvaluatePage from './EvaluatePage';
import FindPassword from './FindPassword';
import JobCopyAddJobPage from './JobCopyAddPage';
import { LoginPage } from './LoginPage';
import MainPage from './MainPage';
import MapPage from './MapPage';
import { NoticeDetailPage } from './NoticeDetailPage';
import NoticeEdit from './NoticeEdit';
import RecommendPage from './RecommendPage';
import { SignupPage } from './SignupPage';
import { MainMypage } from './UserMypage/MainMypage';
import { includes } from 'lodash';
import LikeList from './LikeList';
import AnnouncementDetailPage from './AnnouncementDetailPage';
import AnnouncementPage from './AnnouncementPage';
import MainMorePage from './MainMorePage';

const SHOW_BOTTOM_NAVBAR_ROUTES = [
  '/main',
  '/map',
  '/like',
  '/recommend',
  '/mypage',
  '/mypage/resume',
  '/mypage/profile',
  '/mypage/notifications',
  '/mypage/more',
  '/company',
  '/company/store',
  '/company/profile',
  '/company/ticket',
  '/company/more',
];

const RegisterFcmToken = gql`
  mutation RegisterFcmToken($fcmToken: String!) {
    registerFcmToken(fcmToken: $fcmToken)
  }
`;

function MainRouter() {
  const client = useApolloClient();
  const logout = useLogout();
  const { pathname } = useLocation();
  const { authenticated } = useAuth();
  const [me, setMe] = useRecoilState(meState);

  useEffect(() => {
    if (authenticated) {
      client
        .query({ query: ME })
        .then(({ data: { me } }) => {
          if (me.isDeleted) {
            window.location.reload();
            logout();
          }
          setMe(me);
          ChannelService.boot({
            pluginKey: process.env.REACT_APP_CANNELIO_KEY,
            hideChannelButtonOnBoot: false,
            mobileMessengerMode: 'iframe',
            profile: {
              name: me?.name,
              profileMobileNumber: me?.phone,
              profileEmail: me?.email,
            },
          });
        })
        .catch((error) => console.log(error));
    } else if (me) {
      setMe(undefined);
      client.clearStore();
    } else {
      ChannelService.boot({
        pluginKey: process.env.REACT_APP_CANNELIO_KEY,
        hideChannelButtonOnBoot: false,
        mobileMessengerMode: 'iframe',
      });
    }
  }, [client, authenticated, me, setMe, logout]);

  useEffect(() => {
    if (!client) return;
    if (!authenticated) return;
    Bridge.onGetFcmToken = ({ fcmToken }: any) => {
      if (!fcmToken) return;
      client.mutate({ mutation: RegisterFcmToken, variables: { fcmToken } });
    };
    Bridge.postMessage('getFcmToken');
  }, [client, authenticated]);

  useEffect(() => {
    //@ts-ignore
    window?.IMP?.init(process.env.REACT_APP_IAMPORT_KEY);
  }, []);
  return (
    <div className="flex min-h-screen flex-1 flex-col">
      <main className="relative flex flex-1 flex-col">
        <Switch>
          <Route path="/delete-account" component={DeleteAccount} />
          <Route
            path="/reset-password/:token"
            component={EmailChangePassword}
          />
          <Route path="/announcement/:id" component={AnnouncementDetailPage} />
          <Route path="/announcement" component={AnnouncementPage} />
          <AuthRoute path="/review/:id" component={EvaluatePage} />
          <AuthRoute path="/jobs/edit/:id" component={EditJobPage} />
          <AuthRoute path="/find-password" component={FindPassword} guestOnly />
          <AuthRoute path="/recommend" component={RecommendPage} />
          <AuthRoute path="/jobs/new" component={AddJobPage} />
          <AuthRoute path="/jobs/copy/new/:id" component={JobCopyAddJobPage} />
          <AuthRoute path="/change-password" component={ChangePassword} />
          <AuthRoute path="/login" component={LoginPage} guestOnly />
          <AuthRoute path="/signup" component={SignupPage} guestOnly />
          <AuthRoute path="/like" component={LikeList} />
          <AuthRoute path="/mypage" component={MainMypage} />
          <AuthRoute path="/company" component={CompanyPage} />
          <AuthRoute
            path="/notice/:id/applicants/:id"
            component={ApplicantDetail}
          />
          <AuthRoute
            path="/jobs/:id/applicants"
            component={ApplicantListPage}
          />
          <AuthRoute path="/notice/:id/edit" component={NoticeEdit} />
          <Route path="/notice/:id" component={NoticeDetailPage} />
          <Route path="/agreement/user-confirm" component={UserConfirmPage} />
          <Route
            path="/agreement/business-confirm"
            component={BusinessConfirmPage}
          />
          <Route
            path="/agreement/location-service-agree"
            component={LocationServiceAgreePage}
          />
          <Route
            path="/agreement/marketing-agree"
            component={MarketingAgreePage}
          />
          <Route path="/agreement/personal-info" component={PersonalInfoPage} />
          <Route path="/more" component={MainMorePage} />
          <Route path="/map" component={MapPage} />
          <Route path="/main" component={MainPage} />
          <Route path="/">
            <Redirect to="/main" />
          </Route>
        </Switch>
      </main>
      {includes(SHOW_BOTTOM_NAVBAR_ROUTES, pathname) && <BottomNavBar />}
    </div>
  );
}

export default MainRouter;
