import {
  Button,
  LoadingScreen,
  useQuery,
} from '@entropyparadox/reusable-react';
import React, { FC } from 'react';
import { useHistory } from 'react-router';
import { employerReview, EmployerReviewData } from '../api';

interface AlreadyReviewButtonProps {
  applyUserId: number;
}

const AlreadyReviewButton: FC<AlreadyReviewButtonProps> = ({ applyUserId }) => {
  const history = useHistory();

  const { loading, data } = useQuery<EmployerReviewData>(employerReview, {
    variables: {
      employeeId: applyUserId,
    },
  });

  if (loading) return <LoadingScreen />;

  return (
    <>
      {!data?.employerReview.employee.id ? (
        <Button
          style={{ width: '100%' }}
          onClick={() => history.push(`/review/${applyUserId}`)}
        >
          평가하기
        </Button>
      ) : (
        <Button style={{ width: '100%', opacity: 0.6 }}>평가완료</Button>
      )}
    </>
  );
};

export default AlreadyReviewButton;
