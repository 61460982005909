import {
  ListItem,
  LoadingScreen,
  useQuery,
  useRecoilState,
  utcToLocalFormat,
} from '@entropyparadox/reusable-react';
import React from 'react';
import { Link } from 'react-router-dom';
import { TAGS, TagsData } from '../api';
import { meState } from '../store';
import { AlbaType, Job, ProcessType } from '../types/job';

interface JobListItemProps {
  job: Job;
}

export const CompanyJobItem: React.FC<JobListItemProps> = ({ job }) => {
  const { loading, error, data } = useQuery<TagsData>(TAGS);
  const [me] = useRecoilState(meState);
  const now = new Date();
  now.setHours(0, 0, 0, 0);

  const deadLine = new Date(job.deadLine);
  deadLine.setHours(0, 0, 0, 0);

  if (loading) return <LoadingScreen />;
  if (error || !data) return <></>;

  const tagJobType = data.tags.filter((tag) => tag.id === job.jobType);
  const tagPayType = data.tags.filter((tag) => tag.id === job.payType);

  return (
    <ListItem>
      <Link
        to={
          me?.id === job.company.userId
            ? `/jobs/${job.id}/applicants`
            : `/map/jobs/${job.id}`
        }
      >
        <div
          className={`flex flex-col items-start space-y-2 ${
            deadLine < now && ' opacity-30'
          }`}
        >
          <div className="w-full flex items-center justify-between">
            <div className="flex items-center space-x-2">
              <div
                className={` ${
                  job.processType === ProcessType.UNAUTHORIZED
                    ? 'bg-gray-100 text-black'
                    : 'bg-black text-white'
                } min-w-max rounded-3xl px-3 py-1 text-sm`}
              >
                {job.processType === ProcessType.UNAUTHORIZED
                  ? '미승인'
                  : '승인'}
              </div>
              <div
                className={` ${
                  job.albaType === AlbaType.NORMAL
                    ? 'bg-darkgreen-5 text-darkgreen-1'
                    : 'bg-brand-5 text-brand-6'
                } min-w-max rounded-3xl px-3 py-1 text-sm`}
              >
                {job.albaType === AlbaType.NORMAL
                  ? tagJobType.map((tagJob) => <>{tagJob.title}</>)
                  : '번쩍 알바⚡'}
              </div>
            </div>
            <a
              download
              className="cursor-pointer px-4 py-2 bg-gray-100 rounded-xl"
              href="https://woogugu-test.s3.ap-northeast-2.amazonaws.com/labor-contract.hwp"
              onClick={(e) => e.stopPropagation()}
            >
              근로계약서 출력
            </a>
          </div>
          <div className="w-full truncate text-left text-gray-800 font-bold">
            {job.title}
          </div>

          <div className="flex items-center w-full space-x-4">
            <div className="flex self-end space-x-2 items-center w-full">
              {tagPayType.map((tagPay) => (
                <div
                  className={`${
                    tagPay.title === '시급'
                      ? 'border-brand-7 text-brand-7'
                      : tagPay.title === '월급'
                      ? 'border-green-500 text-green-500'
                      : ''
                  } px-2 py-1 border-2 font-bold text-xs`}
                >
                  <>{tagPay.title}</>
                </div>
              ))}
              <div className="text-lg font-bold text-gray-600">
                {String(job.wage).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원
              </div>
            </div>
            <div className="text-gray-500 min-w-max">
              {utcToLocalFormat(job.deadLine || '', 'YYYY년 MM월 DD일')}까지
            </div>
          </div>
        </div>
      </Link>
    </ListItem>
  );
};
