import { FC } from 'react';
import NoticeCard from '../../components/MainPage/NoticeCard';
import { ANNOUNCEMENT_LIST } from '../../constants/announcements';

interface AnnouncePageProps {}

const AnnouncePage: FC<AnnouncePageProps> = ({}) => {
  return (
    <div className="w-full mt-5 space-y-2">
      {ANNOUNCEMENT_LIST.map((item) => (
        <NoticeCard announcement={item} />
      ))}
    </div>
  );
};

export default AnnouncePage;
