import { Company } from './company';

export enum AlbaType {
  NORMAL = 'NORMAL',
  URGENTLY = 'URGENTLY',
}

export enum ProcessType {
  AUTHORIZED = 'AUTHORIZED',
  UNAUTHORIZED = 'UNAUTHORIZED',
}

export interface Job {
  id: number;
  title: string;
  summery: string;
  deadLine: string;
  workingDay: string;
  wage: number;
  provision: string;
  payType: number;
  jobType: number;
  taxType: number;
  workPeriod: number;
  detailWorkTime: string;
  benefits: string;
  opening: string;
  education: string;
  gender: string;
  qualification: string;
  age: string;
  advantage: string;
  thumbnail: string;
  entranceUrl: string;
  isHidden: boolean;
  videoUrl: string;
  lat: string;
  lng: string;
  companyId: number;
  industry: number[];
  workTime: number[];
  workDay: number[];
  albaType: AlbaType;
  company: Company;
  processType: ProcessType;
}
