import {
  BottomFixedButton,
  Section,
  TextArea,
  TextField,
  useMutation,
} from '@entropyparadox/reusable-react';
import React, { FC, useState } from 'react';
import { useHistory } from 'react-router';
import { updateCompany } from '../api';
import { DaumPost } from '../components/DaumPost';
import NewMainTopNavbar from '../components/NewMainTopNavbar';
import { Company } from '../types/company';

interface CompanyEditContentProps {
  company: Company;
  refetch: () => void;
}

const CompanyEditContent: FC<CompanyEditContentProps> = ({
  company,
  refetch,
}) => {
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const [number, setNumber] = useState(company.number);
  const [address, setAddress] = useState(company.address);
  const [detailAddress, setDetailAddress] = useState(company.detailAddress);
  const [url, setUrl] = useState(company.url);
  const [introduction, setIntroduction] = useState(company.introduction);

  const [UpdateCompany] = useMutation(updateCompany, {
    variables: {
      id: company.id,
      address,
      detailAddress,
      url,
      introduction,
      number,
    },
    onCompleted: async () => {
      await refetch();
      alert('수정이 완료되었습니다.');
      history.push(`/notice/${company.id}`);
    },
  });

  return (
    <div>
      <NewMainTopNavbar />
      <div className="hidden md:block inset-0">
        <div className="text-2xl mt-8 text-center">업장 정보수정</div>
      </div>
      <div className="max-w-md mx-auto">
        <Section>
          <TextField
            label="*업장명"
            placeholder="업장명을 입력해주세요"
            value={company.name}
            disabled
          />
          <TextField
            label="*업장 전화번호"
            placeholder="업장 전화번호를 입력해주세요"
            value={number}
            onChange={(e) => setNumber(e.target.value)}
          />
          <div className="mt-4">
            <label
              htmlFor="expiration_date"
              className="block text-sm font-medium text-gray-700"
            >
              *회사주소
            </label>
            <div className="grid grid-cols-4 gap-4 mt-1">
              <div className="col-span-3">
                <TextField
                  type="text"
                  name="address"
                  id="phone_number_f"
                  placeholder="주소를 입력해주세요."
                  value={address}
                  disabled
                />
              </div>
              <button
                className="col-span-1 bg-brand-1 rounded-md text-white"
                onClick={() => setIsOpen(true)}
              >
                주소 검색
              </button>
            </div>
            {isOpen && (
              <DaumPost
                setAddress={(fullAddress: any) => setAddress(fullAddress)}
                setIsOpen={() => setIsOpen(false)}
              />
            )}
          </div>
          <TextField
            label="*상세 주소"
            placeholder="상세 주소를 입력해주세요"
            value={detailAddress}
            onChange={(e) => setDetailAddress(e.target.value)}
          />
          <TextField
            label="홈페이지"
            placeholder="홈페이지를 입력해주세요"
            value={url}
            onChange={(e) => setUrl(e.target.value)}
          />
          <TextArea
            label="사업 내용"
            placeholder="사업 내용에 대해 간단하게 작성해주세요."
            value={introduction}
            onChange={(e) => setIntroduction(e.target.value)}
          />
          <div className="text-sm text-gray-600">
            *업장명 수정 또는 사업자등록증 수정은 고객센터로 문의주세요.
          </div>
        </Section>
        <BottomFixedButton
          disabled={!address || !detailAddress || !introduction}
          style={{ maxWidth: '448px' }}
          onClick={() => UpdateCompany()}
        >
          수정 완료
        </BottomFixedButton>
      </div>
    </div>
  );
};

export default CompanyEditContent;
