import MyPageAccordion from '../../components/MyPageAccordion';

const QNA_LIST = [
  {
    title: '회원탈퇴는 어떻게 하나요?',
    content: `회원 탈퇴는 상단 마이페이지 클릭 후 프로필 항목 하단에 있는 회원 탈퇴 버튼을 눌러 문의 주시면 처리해드리겠습니다.`,
  },
  {
    title: '허위 광고인 경우에는 어떻게 하나요?',
    content: `채널톡으로 문의주시면 해당 내용 확인 후에 별도로 처리해드리겠습니다.`,
  },
  {
    title: '공고 지원 했는데 연락은 언제 오나요?',
    content: `지원을 해주신 경우 담당자에게 알림톡이 전달되며 공고 담당자가 확인 후 별도 연락드립니다.`,
  },
  {
    title: '번쩍알바는 무엇인가요?',
    content: `번쩍알바의 경우 일반공고와 달리 급하게 알바를 구하고 싶은 경우 별도 표시가 됩니다.\n상단에 표시가 되어 쉽게 지원 할 수 있습니다.`,
  },
  {
    title: '공고 등록 금액은 어떻게 되나요?',
    content: `공고 등록 1건 당 이용권 1개가 차감되며 이용권 1개 당 1,000원의 이용료를 선결제 해주셔야 합니다.\n이용권 결제시 많을수록 할인이 진행됩니다.\n10개는 20% 할인되어 8,000원, 20개의 경우 30% 할인되어 14,000원입니다.`,
  },
  {
    title: '환불은 어떻게 하나요?',
    content: `이용권 하단에 있는 취소/환불 절차 확인 후 채널톡으로 문의 주시면 처리해드리겠습니다.`,
  },
  {
    title: '아이디, 비밀번호를 잊어버렸어요!',
    content: `아이디(이메일)를 잊어버리신 경우에는 채널톡으로 문의 부탁드립니다.\n비밀번호의 경우 아이디(이메일)을 입력하시면 해당 메일로 안내 메일이 전달됩니다.\n해당 링크를 통해 비밀번호를 변경해주시면 됩니다.\n채널톡 문의를 주실 경우 일부 개인정보를 요청드릴 수 있다는 점 참고부탁드립니다.`,
  },
  {
    title: '미성년자는 회원가입이 안되나요?',
    content: `미성년자의 경우 회원가입은 가능합니다. 다만, 근무를 진행하시려면 부모님 동의서, 등본 등 근무 담당자에게 전달해주셔야 근무가 가능합니다.`,
  },
];

const QnaPage = () => {
  return (
    <div>
      <div className="mt-5 space-y-2">
        {QNA_LIST.map((data, i) => (
          <MyPageAccordion key={i} title={data.title} content={data.content} />
        ))}
      </div>
    </div>
  );
};

export default QnaPage;
