import { ReactComponent as CurrentPin } from '../assets/svg/current-pin.svg';

const CurrentLocationMarker = () => {
  return (
    <CurrentPin
      id="current"
      key="current"
      style={{ position: 'absolute', transform: 'translate(-50%, -100%)' }}
    />
  );
};

export default CurrentLocationMarker;
