import { useMutation, useQuery } from '@entropyparadox/reusable-react';
import {
  GetTicketInfo,
  GetTicketInfoData,
  CompletePayment,
  StartPayment,
} from '../../api';
import { TicketOrder } from '../../types/ticketorders';
import { ReactComponent as TicketIcon } from '../../assets/svg/ticket.svg';
import { useState } from 'react';
import RefundModal from './RefundModal';

const getDiscountRate = (originalPrice: number, realPrice: number) => {
  const rate = ((originalPrice - realPrice) / originalPrice) * 100;
  return rate + '%';
};

const CompanyPayment = () => {
  const { data, refetch } = useQuery<GetTicketInfoData>(GetTicketInfo);
  const [showModal, setShowModal] = useState(false);

  const [startPayment] = useMutation(StartPayment);

  const [completePayment] = useMutation(CompletePayment, {
    onCompleted: () => {
      refetch();
    },
    onError: (error) => {
      alert(error);
    },
  });

  const handlePayment = (selectedTicketOrder: TicketOrder) => {
    startPayment({ variables: { ticketOrderId: selectedTicketOrder.id } })
      .then(
        ({
          data: {
            startPayment: { merchantUid },
          },
        }) => {
          //@ts-ignore
          window.IMP.request_pay(
            {
              pg: 'tosspayments',
              merchant_uid: merchantUid,
              name: `공고 등록 ${selectedTicketOrder.ticketNumber}회 이용권`,
              pay_method: 'card',
              amount: selectedTicketOrder.price,
              currency: 'KRW',
              locale: 'ko',
              m_redirect_url: window.location.href,
              // notice_url: `${config.apiUrl}/payment-orders/imp-webhook`,
            },
            (res: any) => {
              const { imp_uid, error_msg } = res;
              if (!!error_msg) {
                alert(error_msg);
                return;
              }
              completePayment({
                variables: {
                  ticketOrderId: selectedTicketOrder.id,
                  impUid: imp_uid,
                },
              });
            },
          );
        },
      )
      .catch((err) => {
        alert(err.message);
        return;
      });
  };

  return (
    <>
      {showModal && <RefundModal onClose={() => setShowModal(false)} />}
      <div className="py-6 px-5">
        <h1 className="text-xl mb-4 ml-2 font-semibold">이용권 구매</h1>
        <div className="flex justify-between items-center border border-brand-1 bg-brand-1 bg-opacity-5 rounded-xl px-5 py-2">
          <span className="">내 이용권 수</span>
          <div className="flex items-center space-x-2">
            <TicketIcon className="w-6 h-6" />
            <div className="text-brand-1 text-lg font-bold">
              {data?.me?.ticketNumber}
            </div>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-6 mt-8">
          {data?.ticketOrders?.map((ticketOrder) => {
            const price = ticketOrder.price || 0;
            const discountPrice = ticketOrder.discountPrice || 0;
            const totalPrice = price - discountPrice;
            return (
              <div
                className="rounded-xl overflow-hidden w-full flex flex-col bg-brand-1 bg-opacity-5 cursor-pointer"
                key={ticketOrder.id}
                onClick={() => handlePayment(ticketOrder)}
              >
                <div className="py-6 flex flex-col justify-center items-center">
                  <TicketIcon className="w-18 h-18 p-2" />
                  <div className="font-bold">
                    이용권 {ticketOrder.ticketNumber}개
                  </div>
                </div>
                <div className="py-3 text-center bg-brand-1 text-white">
                  {price !== totalPrice && (
                    <>
                      <span className="text-md">
                        {getDiscountRate(price, totalPrice)}{' '}
                      </span>
                      <span className="line-through text-xs text-brand-3">
                        {ticketOrder.price?.toLocaleString()}원
                      </span>
                    </>
                  )}{' '}
                  {totalPrice?.toLocaleString()}원
                </div>
              </div>
            );
          })}
        </div>

        <div
          className="underline ml-2 text-gray-600 underline-offset-2 mt-6 cursor-pointer"
          style={{ textUnderlineOffset: '4px' }}
          onClick={() => setShowModal(true)}
        >
          취소/환불 절차
        </div>
      </div>
    </>
  );
};

export default CompanyPayment;
