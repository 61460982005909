import React from 'react';
import MapImg from '../../assets/png/section-4-img.png';
import { Link } from 'react-router-dom';

const Section4 = () => {
  return (
    <div className="px-5 max-w-screen-lg mx-auto w-full">
      <Link
        to={'/map'}
        className="flex items-center justify-between bg-purple-100 p-5 md:p-10 rounded-xl"
      >
        <div className="lg:space-y-4 space-y-2">
          <p className="text-gray-400 md:text-lg text-2sm">
            한 눈에 보이는 지도 공고로 찾아보세요!
          </p>
          <p className="md:text-2xl font-bold text-xl leading-normal text-gray-800">
            지도 공고
            <br />
            보러가기 →
          </p>
        </div>

        <div className="relative">
          <img className="object-cover md:w-32 w-20" src={MapImg} alt="" />
        </div>
      </Link>
    </div>
  );
};

export default Section4;
