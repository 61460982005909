import {
  Button,
  Section,
  TextField,
  useMutation,
} from '@entropyparadox/reusable-react';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { findPassword } from '../api';
import NewMainTopNavbar from '../components/NewMainTopNavbar';

const FindPassword = () => {
  const [email, setEmail] = useState('');
  const [send, setSend] = useState(false);

  const history = useHistory();

  const [FindPassword] = useMutation(findPassword, {
    variables: {
      email,
    },
    onCompleted: async () => {
      setSend(true);
    },
    onError: (err) => {
      alert(err.message);
    },
  });

  return (
    <div>
      <NewMainTopNavbar maintitle="비밀번호 찾기" />
      {send === false && (
        <div className="min-h-screen sm:bg-gray-50 flex flex-col sm:justify-center sm:px-6 lg:px-8">
          <div className="text-2xl font-bold text-gray-800 py-3 mx-auto mt-5 sm:mt-0">
            비밀번호 찾기
          </div>
          <div className="text-base text-gray-400 py-2 mx-auto text-center">
            가입시 입력했던 이메일을 <br />
            입력해주세요.
          </div>
          <div className="sm:mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <div className="bg-white py-6 px-2 sm:shadow sm:rounded-lg sm:px-10">
              <Section>
                <TextField
                  type="email"
                  label="*이메일"
                  placeholder="이메일을 입력해주세요."
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <div className="w-full pb-4 font-light text-xs text-gray-500">
                  *이메일이 오지 않거나, 이메일이 기억나지 않을 경우 고객센터로
                  문의 주시기 바랍니다.
                </div>
                <Button disabled={!email} onClick={() => FindPassword()}>
                  다음
                </Button>
              </Section>
            </div>
          </div>
        </div>
      )}
      {send === true && (
        <Section>
          <div className="min-h-screen sm:bg-gray-50 flex flex-col sm:justify-center sm:px-6 lg:px-8">
            <div className="text-2xl font-bold text-gray-800 py-3 mx-auto mt-5 sm:mt-0">
              이메일이 전송되었습니다.
            </div>
            <div className="text-base text-gray-400 py-2 mx-auto text-center">
              이메일을 확인하여 <br />
              비밀번호를 변경해주세요.
            </div>
            <div className="sm:mt-8 sm:mx-auto sm:w-full sm:max-w-md flex flex-col">
              <Button disabled={!email} onClick={() => history.push('/main')}>
                확인
              </Button>
            </div>
          </div>
        </Section>
      )}
    </div>
  );
};

export default FindPassword;
