import React, { FC } from 'react';
import { ReactComponent as HomeIcon } from '../assets/svg/menu-home.svg';
import { ReactComponent as MapIcon } from '../assets/svg/menu-edit.svg';
import { ReactComponent as BookmarkIcon } from '../assets/svg/menu-bookmark.svg';
import { ReactComponent as FillTextIcon } from '../assets/svg/menu-file-text.svg';
import { ReactComponent as UserIcon } from '../assets/svg/menu-user.svg';
import { NavItem } from './NavItem';
import { useLocation } from 'react-router-dom';
import { useRecoilValue } from '@entropyparadox/reusable-react';
import { meState } from '../store';
import { Role } from '../types/user';

const MENU_DATA = [
  { id: 1, path: '/main', icon: <HomeIcon />, text: '메인' },
  { id: 2, path: '/map', icon: <MapIcon />, text: '지도' },
  { id: 3, path: '/like', icon: <BookmarkIcon />, text: '관심공고' },
  { id: 4, path: '/recommend', icon: <FillTextIcon />, text: '맞춤알바설정' },
  { id: 5, path: '/mypage', icon: <UserIcon />, text: '프로필' },
];

interface BottomNavBarProps {}

export const BottomNavBar: FC<BottomNavBarProps> = () => {
  const { pathname } = useLocation();
  const me = useRecoilValue(meState);

  return (
    <div>
      <div className="fixed bottom-0 z-20 grid h-20 px-5 w-full border-t rounded-t-2xl bg-white md:hidden md:max-w-md">
        <div className={'flex items-center justify-between'}>
          {MENU_DATA.map((menu) => {
            const realPath =
              menu.id === 5 && me?.role === Role.BUSINESS
                ? '/company'
                : menu.path;

            return (
              <NavItem
                key={menu.id}
                className={
                  pathname.startsWith(realPath)
                    ? 'font-bold text-brand-1'
                    : 'text-gray-400 font-medium'
                }
                path={realPath}
                icon={menu.icon}
                menu={menu.text}
              />
            );
          })}
        </div>
      </div>
      <div
        className={`w-full ${pathname.startsWith('/map') ? 'md:h-0' : 'h-20'}`}
      ></div>
    </div>
  );
};
