import { useIdParam } from '@entropyparadox/reusable-react';
import { FC } from 'react';
import NewMainTopNavbar from '../components/NewMainTopNavbar';
import { ANNOUNCEMENT_LIST } from '../constants/announcements';
import NewLineText from '../components/NewLineText';

interface AnnouncementDetailPageProps {}

const AnnouncementDetailPage: FC<AnnouncementDetailPageProps> = ({}) => {
  const id = useIdParam();
  const announcement = ANNOUNCEMENT_LIST.filter((item) => item.id === id)?.[0];

  if (!announcement) return <div>잘못된 접근입니다.</div>;

  return (
    <div>
      <NewMainTopNavbar />
      <div className="max-w-md md:mx-auto space-y-4 p-5">
        <div className="space-y-2">
          <p className="lg:text-xl text-lg font-semibold">
            {announcement.title}
          </p>
          <p className="lg:text-sm text-xs text-gray-500">
            {announcement.date}
          </p>
        </div>

        <NewLineText
          className="lg:text-md text-md text-gray-700"
          text={announcement.description}
        ></NewLineText>
      </div>
    </div>
  );
};

export default AnnouncementDetailPage;
