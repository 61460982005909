import ParttimeCard from './ParttimeCard';
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { MainData, urgentJobQuery } from '../../api';
import { useQuery } from '@entropyparadox/reusable-react';
import { map } from 'lodash';

const Section5 = () => {
  const { data } = useQuery<MainData>(urgentJobQuery, {});
  return (
    <div className="max-w-screen-lg mx-auto w-full px-5 lg:space-y-12 space-y-5">
      <p className="lg:text-4xl font-bold text-2xl">
        우리동네 번쩍 구인구직
        <br />한 번 알아볼까요?
      </p>

      <Swiper
        spaceBetween={12}
        slidesPerView={2.3}
        breakpoints={{
          768: { slidesPerView: 2.3 },
          1024: { slidesPerView: 3 },
        }}
      >
        {map(data?.urgentJobs, (job) => (
          <SwiperSlide key={job.id}>
            <ParttimeCard job={job} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Section5;
