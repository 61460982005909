import { Payment } from './payments';
import { User } from './user';

export enum TicketType {
  ADD = 'ADD',
  SUBSTRACT = 'SUBSTRACT',
}

export interface Ticket {
  id: number;
  usedJobId?: number;
  ticketType: TicketType;
  changedTicketNumber?: number;
  totalTicketNumber?: number;
  reason?: string;
  price?: number;
  paymentId?: number;
  payment?: Payment;
  userId?: number;
  user?: User;
  createdAt?: string;
  updatedAt?: string;
}
