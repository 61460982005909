import React from 'react';
import { Link } from 'react-router-dom';
import NoticeCard from './NoticeCard';
import { ANNOUNCEMENT_LIST } from '../../constants/announcements';

const Section6 = () => {
  return (
    <div className="max-w-screen-lg mx-auto w-full px-5 lg:space-y-12 space-y-5">
      <div className="flex items-center justify-between">
        <p className="lg:text-4xl font-bold text-2xl">공지사항</p>
        <Link
          to={'/announcement'}
          className="lg:text-md text-sm text-gray-500 underline"
        >
          더보기
        </Link>
      </div>

      <div className="grid lg:grid-cols-2 grid-cols-1 gap-5">
        {ANNOUNCEMENT_LIST.map((item) => (
          <NoticeCard announcement={item} key={item.id} />
        ))}
      </div>
    </div>
  );
};

export default Section6;
