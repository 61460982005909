import React from 'react';
import {
  Button,
  LoadingScreen,
  Section,
  useIdParam,
  useQuery,
} from '@entropyparadox/reusable-react';
import NewMainTopNavbar from '../components/NewMainTopNavbar';
import { useHistory } from 'react-router-dom';
import { UserApplyCard } from '../components/UserApplyCard';
import { JOB } from '../api';
import { ProcessType } from '../types/job';

export const ApplicantListPage: React.FC = () => {
  const id = useIdParam();
  const history = useHistory();
  const { loading, error, data } = useQuery(JOB, {
    variables: { id: id },
  });

  const job = data?.job;

  if (loading) return <LoadingScreen />;
  return (
    <>
      <NewMainTopNavbar maintitle="지원자 정보" />
      <div className="max-w-md mx-auto w-full">
        <div className="hidden md:block text-2xl text-center mt-8 mb-6 font-semibold">
          지원자 정보
        </div>
        <Section>
          {job?.processType === ProcessType.AUTHORIZED ? (
            <div className="divide-y divide-gray-200 md:w-112 w-full">
              <UserApplyCard />
            </div>
          ) : (
            <div className="flex justify-center items-center text-gray-500">
              승인된 공고만 지원자 정보를 확인 가능합니다.
            </div>
          )}
        </Section>
      </div>
      <div className="w-screen z-100 fixed bottom-0 bg-white">
        <div className="md:max-w-md w-full mx-auto">
          <Button
            style={{ width: '50%' }}
            variant="outlined"
            rounded="rounded-none"
            onClick={() => history.push(`/jobs/copy/new/${id}`)}
          >
            공고 복사하기
          </Button>
          <Button
            style={{ width: '50%' }}
            rounded="rounded-none"
            onClick={() => history.push(`/map/jobs/${id}`)}
          >
            공고 확인하기
          </Button>
        </div>
      </div>
    </>
  );
};
