import {
  Button,
  useMutation,
  useQuery,
  useRecoilValue,
} from '@entropyparadox/reusable-react';
import React, { FC, useState } from 'react';
import { ApplyMy, ApplyMyData, createApply } from '../api';
import { meState } from '../store';
import { StatusApply } from '../types/applies';
import { Job } from '../types/job';
import ModalPopUp from './ModalPopUp';

interface ApplyButtonProps {
  job: Job;
}

const ApplyButton: FC<ApplyButtonProps> = ({ job }) => {
  const me = useRecoilValue(meState);
  const [open, setOpen] = useState(false);

  const { data } = useQuery<ApplyMyData>(ApplyMy, {
    variables: { jobId: job.id },
  });

  const [CreateApply] = useMutation(createApply, {
    variables: { jobId: job.id },
    refetchQueries: [{ query: ApplyMy, variables: { jobId: job.id } }],
    onCompleted: () => {
      alert(
        '지원 해주셔 감사합니다. 업장에서 합격 여부를 안내드릴 예정입니다.',
      );
    },
  });

  const handleConfirm = () => {
    // eslint-disable-next-line no-restricted-globals
    const con_test = confirm(
      `온라인으로 지원하시겠습니까?
지원후에는 취소되지 않습니다.`,
    );
    if (con_test) {
      CreateApply();
    } else {
    }
  };

  const now = new Date();
  now.setHours(0, 0, 0, 0);

  const deadLine = new Date(job.deadLine);
  deadLine.setHours(0, 0, 0, 0);

  const handleNone = () => {
    setOpen(true);
  };

  if (deadLine < now) {
    return (
      <Button style={{ width: '100%' }} disabled>
        온라인 지원하기
      </Button>
    );
  }

  if (
    data?.applyMy.userId === me?.id &&
    data?.applyMy.status === StatusApply.SUCCESS
  ) {
    return (
      <Button style={{ width: '100%', opacity: 0.5 }}>온라인 지원합격</Button>
    );
  }

  if (data?.applyMy.userId === me?.id) {
    return (
      <Button style={{ width: '100%', opacity: 0.5 }}>온라인 지원완료</Button>
    );
  }

  if (me?.resume === null) {
    return (
      <>
        <Button style={{ width: '100%' }} onClick={() => handleNone()}>
          온라인 지원하기
        </Button>
        {open && (
          <ModalPopUp
            title="현재 이력서 미등록 상태입니다."
            title2="이력서 등록 후 채용률을 높여보세요:)"
            text1="이력서 등록하기"
            text2="건너뛰기"
            link1="/mypage/resume"
            inset="left-2/4 -translate-x-1/2"
            createApply={() => CreateApply()}
            setClose={() => {
              setOpen(false);
            }}
          />
        )}
      </>
    );
  }
  return (
    <Button style={{ width: '100%' }} onClick={() => handleConfirm()}>
      온라인 지원하기
    </Button>
  );
};

export default ApplyButton;
